import React, { useState, useEffect } from 'react'
import firebase from 'firebase'
import moment from 'moment'
import queryString from 'query-string'

import { useParams, useHistory, useLocation } from 'react-router-dom'
import { message, Row, Col } from 'antd'
import Loader from '../Loader'
import copyToClipboard from '../../utils/copyToClipboard'
import setStoryView from '../../api/setStoryView'

export default function ViewStory({ brand }) {
  const [story, setStory] = useState()

  const history = useHistory()
  const location = useLocation()
  const urlParams = queryString.parse(location.search)
  const superfanID = urlParams.id

  const params = useParams()
  const storyID = params.id
  const db = firebase.firestore()

  useEffect(() => {
    const getStory = async () => {
      const s = await db
        .collection('stories')
        .doc(storyID)
        .get()
        .then(doc => doc.data())
        .catch(error => console.error(error))

      if (s && (s.expires >= Date.now() || s.expires === 0)) {
        setStory(s)
        setStoryView(storyID, superfanID)
      } else if (s && s.expires < Date.now()) {
        history.push(`/${brand.handle}`)
        message.error('This story has expired ⌛️')
      } else {
        history.push(`/${brand.handle}`)
        message.error('Sorry, couldn\'t find story 🧐')
      }
    }
    getStory()
  }, [brand.handle, db, history, storyID, superfanID])

  if (story) {
    return (
      <div className="stories floater">
        <div className="container">
          <div className="box">
            <Row gutter={[24, 24]}>
              <Col xs={24} md={12}>
                <h1 className="m-0">
                  {story.title}
                </h1>
                {story.expires ? (
                  <div className={`mb-3 expires small ${(story.expires - Date.now()) <= 36000000 ? 'red' : 'blue'}`}>
                    Expires <span className="bold">{moment(story.expires).calendar()}</span>
                  </div>
                ) : (
                  <div className="expires small green">
                    Doesn&apos;t expire
                  </div>
                )}

                <h2 className="m-0 mt-3">
                  Visuals
                </h2>
                <p className="small light">
                  Long-press or right-click an image to save to your device
                </p>
                <div className="posts scrollbox horizontal w-100">
                  <div className="posts-container">
                    {story.images && story.images.map(image => {
                      const queryIndex = image.lastIndexOf('?')
                      const extension = image.slice(queryIndex - 3, queryIndex)
                      return (
                        <div
                          key={image}
                          className="post-container"
                        >
                          <div className="post">
                            {['mp4', 'mov'].includes(extension) ? (
                              <video
                                controls
                                preload
                              >
                                <source src={image} type="video/mp4" />
                              </video>
                            ) : (
                              <img
                                src={image}
                                alt="story"
                                className="w-100"
                              />

                            )}
                          </div>
                        </div>
                      )
                    })}
                  </div>
                </div>
              </Col>
              <Col xs={24} md={12}>
                <h2 className="mb-0">
                  Message
                </h2>
                <div className="message scrollbox vertical">
                  <p className="m-0 pre-wrap">
                    {story.description}
                  </p>
                  <div className="small bold secondary pointer mb-4" onClick={() => copyToClipboard(story.description)}>
                    <p>
                      Copy message
                    </p>
                  </div>
                  {story.hashtags && (
                    <div className="mt-3">
                      {story.hashtags.map(hashtag => (
                        <div className="">
                          <p className="m-0">
                            #{hashtag}&nbsp;
                          </p>
                          <div className="small bold secondary pointer" onClick={() => copyToClipboard(hashtag)}>
                            <p>
                              Copy hashtag
                            </p>
                          </div>
                        </div>
                      ))}
                    </div>
                  )}
                  {story.url && (
                    <>
                      <a href={story.url} target="_blank" rel="noopener noreferrer" className="break-all">{story.url}</a>
                      <div className="small bold secondary pointer mb-4" onClick={() => copyToClipboard(story.url)}>
                        <p>
                          Copy url
                        </p>
                      </div>
                    </>
                  )}
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </div>
    )
  }

  return <Loader />
}
