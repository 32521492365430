/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import update from 'immutability-helper'
import {
  Button, Input, Col, message, Row, Checkbox, notification, Progress,
} from 'antd'
import { SearchOutlined } from '@ant-design/icons'
import { useDropzone } from 'react-dropzone'
import TextArea from 'antd/lib/input/TextArea'
import FloatLabel from '../FloatLabel'
import { ReactComponent as Image } from '../../icons/image.svg'
import cleanUpSuperfanData from '../../utils/cleanUpSuperfanData'
import sendVideoMessage from '../../api/sendVideoMessage'

export default function VideoMessage({
  acceptedSuperfans, preselectedSuperfans, setPreselectedSuperfans, data,
}) {
  const [video, setVideo] = useState({})
  const [loading, setLoading] = useState()
  const [search, setSearch] = useState()
  const [selectedSuperfans, setSelectedSuperfans] = useState(preselectedSuperfans)
  const [uploadProgress, setUploadProgress] = useState(null)

  const history = useHistory()

  useEffect(() => {
    setPreselectedSuperfans([])
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    const superfans = []
    selectedSuperfans.forEach(id => {
      superfans.push(acceptedSuperfans.find(superfan => superfan.id === id))
    })
    setVideo({ ...video, superfans: cleanUpSuperfanData(superfans) })
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedSuperfans])

  const { getRootProps, getInputProps } = useDropzone({
    accept: 'video/*',
    onDrop: acceptedFiles => {
      const file = acceptedFiles[0]
      const preview = URL.createObjectURL(acceptedFiles[0])
      setVideo({
        ...video,
        file,
        preview,
      })
    },
  })

  return (
    <div className="video-message">
      <div className="content-box">
        <h1>
          Send video message
        </h1>
        <Row gutter={[24, 24]}>
          <Col xs={24} lg={12}>
            <TextArea
              autoSize={{ minRows: 3, maxRows: 5 }}
              className="mt-4"
              placeholder="Optional message"
              value={video.message}
              onChange={e => setVideo({ ...video, message: e.target.value })}
            />

            <h2 className="mt-4">
              Add video
            </h2>
            <div className="mb-6">
              {video.file ? (
                <div className="uploaded-video">
                  <p>
                    Looking fabulous!
                  </p>
                  <p>
                    Please check if your video is visible in the preview:
                  </p>
                  <div
                    className="underline pointer mb-3 primary"
                    onClick={() => setVideo({ ...video, file: undefined })}
                  >
                    Remove video
                  </div>
                  <video
                    controls
                    preload="none"
                  >
                    <source src={video.preview} type="video/mp4" />
                  </video>

                </div>
              ) : (
                <>
                  <div
                    className="dropzone-container post-container"
                  >
                    <div {...getRootProps({ className: 'dropzone post' })}>
                      <input {...getInputProps()} />
                      <Image className="secondary" />
                      <p className="w-100">
                        <span className="secondary bold">
                          Upload file here
                        </span>
                        {' '}
                        or just drag and drop
                      </p>
                    </div>
                  </div>
                </>
              )}
            </div>
          </Col>
          <Col xs={24} md={12}>
            <h2 className="mt--6">
              {selectedSuperfans.length === 0 && (
                'Select superfan(s)'
              )}
              {selectedSuperfans.length === 1 && (
                '1 superfan selected'
              )}
              {selectedSuperfans.length > 1 && (
                `${selectedSuperfans.length} superfans selected`
              )}

            </h2>
            <FloatLabel
              value={search}
              label="Search superfan name"
              labelStyle={{ paddingLeft: '26px' }}
            >
              <Input
                value={search}
                onChange={e => setSearch(e.target.value.toLowerCase())}
                prefix={<SearchOutlined />}
              />
            </FloatLabel>

            <div className="bg-gray-super-light superfan-list padding py-0 mt-4">
              {acceptedSuperfans.filter(superfan => (search ? superfan.firstName.toLowerCase().includes(search) || superfan.lastName.toLowerCase().includes(search) : true)).map(superfan => (
                <div key={superfan.id}>
                  <div
                    onClick={() => {
                      const index = selectedSuperfans.findIndex(id => superfan.id === id)
                      if (index >= 0) {
                        setSelectedSuperfans(update(selectedSuperfans, { $splice: [[index, 1]] }))
                      } else {
                        setSelectedSuperfans(update(selectedSuperfans, { $push: [superfan.id] }))
                      }
                    }}
                    className="d-flex align-center py-2 pointer"
                    key={superfan.id}
                  >
                    <Checkbox checked={selectedSuperfans.find(id => superfan.id === id) !== undefined} />
                    <img src={superfan.imageUrl} alt="profile" className="profile-img round mr-3 ml-3" />
                    <p className="m-0">
                      {superfan.firstName} {superfan.lastName}
                    </p>
                  </div>
                  <div className="separator m-0" />
                </div>
              ))}
            </div>
          </Col>
        </Row>
      </div>
      <div className="buttons mt-3 d-flex justify-end align-center">
        {uploadProgress !== null && (
          <>
            <p className="mb-0 mr-3">Uploading video:</p>
            <Progress
              className="progress"
              percent={uploadProgress.toFixed(0)}
              size="small"
              status="active"
            />
          </>
        )}
        <Button
          disabled={!video.file || selectedSuperfans.length < 1}
          type="primary"
          className="mr-3 ml-3"
          loading={loading}
          onClick={async () => {
            setLoading(true)
            const response = await sendVideoMessage(video, data.brand.id, setUploadProgress)
            console.log(response)
            if (response.status === 'success') {
              notification.success({
                title: 'Video sent 💌',
                message: `We've sent an email to your selected superfan${selectedSuperfans.length > 1 ? 's' : ''}`,
                duration: 0,
              })
              history.push(`/dashboard/${data.brand.handle}/superfans`)
              setLoading()
            } else if (response.status === 'error') {
              setLoading()
              message.error('Something went wrong sending your video')
              console.log(response)
            }
          }}
        >
          Send
        </Button>
      </div>
    </div>
  )
}
