import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import moment from 'moment'
import {
  Button, Row, Col, Progress,
} from 'antd'

import { ReactComponent as Antenna } from '../../icons/antenna.svg'

import SuperfansChart from './charts/SuperfansChart'
import getReach from '../../utils/getReach'
import getStoryValue from '../../utils/getStoryValue'
import SuperfanCarousel from './SuperfanCarousel'
import PendingSuperfanCarousel from './PendingSuperfanCarousel'
import InviteModal from './InviteModal'

export default function DashboardHome({
  data,
  dateRange,
  setDateRange,
  startDate,
  acceptedSuperfans,
  pendingSuperfans,
  superfansOverTime,
}) {
  const [inviteModal, setInviteModal] = useState()
  const activeStories = data.brand.stories.filter(story => story.expires > Date.now())

  return (
    <div className="dashboard-home">
      <div className="dashboard-top" style={{ gridArea: 'dashboard-top' }}>
        <div className="title">
          <div className="greeting">
            <p className="light m-0">
              Last login: {moment(data.user.lastSignInTime).format('MMMM Do, HH:mm')}
            </p>
            <h1 className="bold">
              Hello {data.user.firstName || ''}
            </h1>
          </div>
          <Button
            type="primary"
            onClick={() => setInviteModal(true)}
          >
            Invite superfans
          </Button>
        </div>
        <div className="separator" />
      </div>

      <SuperfansChart
        superfans={superfansOverTime}
        pendingCount={pendingSuperfans.length}
        startDate={startDate}
        dateRange={dateRange}
        setDateRange={setDateRange}
        showDateSelector
      />

      <div className="pending component" style={{ gridArea: 'pending' }}>
        {pendingSuperfans.length > 0 && (
        <>
          <div className="d-flex align-baseline">
            <h2 className="m-0">
              Pending
            </h2>
            <div className="notification-count">
              {pendingSuperfans.length}
            </div>
          </div>

          <div className="separator mt-1 mb-3" />

          <PendingSuperfanCarousel superfans={pendingSuperfans} data={data} />
        </>
        )}

        {pendingSuperfans.length === 0 && (
        <>
          <h2 className="m-0">
            Superfans
          </h2>

          <div className="separator mt-1 mb-3" />

          <SuperfanCarousel superfans={acceptedSuperfans} data={data} />
        </>
        )}
      </div>

      {activeStories.map(story => (
        <div key={story.id} className="current-story component" style={{ gridArea: 'story' }}>
          <Row gutter={[16, 16]} className="mb-0">
            <Col xs={24} lg={12}>
              <h2 className="m-0">
                {story.title}
              </h2>
              <p className="highlight secondary w-fit-content m-0">
                Expiring <span className="bold">{moment(story.expires).calendar()}</span>
              </p>
              <Progress percent={((Date.now() - story.created) / (story.expires - story.created)) * 100} status="active" showInfo={false} />
            </Col>
            <Col xs={24} lg={4}>
              <div className="card mt-3">
                <p className="light small m-0 center mx--3">
                  Views
                </p>
                <h1 className="secondary d-flex align-baseline m-0">
                  <Antenna className="h1-icon" />
                  {story.views.length}
                </h1>
              </div>
            </Col>
            <Col xs={24} lg={4}>
              <div className="card mt-3">
                <p className="light small m-0 center mx--3">
                  Potential reach
                </p>
                <h1 className="secondary d-flex align-baseline m-0">
                  <Antenna className="h1-icon" />
                  {getReach(story.views)}
                </h1>
              </div>
            </Col>
            <Col xs={24} lg={4}>
              <div className="card mt-3">
                <p className="light small m-0 center mx--3">
                  Potential value
                </p>
                <h1 className="tertiary m-0">
                  €{getStoryValue(story.views)}
                </h1>
              </div>
            </Col>
          </Row>
        </div>
      ))}

      {activeStories.length === 0 && (
        <div className="next-story component" style={{ gridArea: 'story' }}>
          <Row gutter={[16, 16]} className="mb-0">
            <Col xs={24} lg={12} className="d-flex align-center">
              <h2 className="m-0 pr-3">
                Potential next story
              </h2>
              <p className="m-0">
                Tap into your potential and create a new story for your superfans.
              </p>
            </Col>
            <Col xs={24} lg={4}>
              <div className="card">
                <p className="light small m-0 center mx--3">
                  Potential reach
                </p>
                <h1 className="secondary d-flex align-baseline m-0">
                  <Antenna className="h1-icon" />
                  {superfansOverTime[superfansOverTime.length - 1].reach.toLocaleString('nl')}
                </h1>
              </div>
            </Col>
            <Col xs={24} lg={4}>
              <div className="card">
                <p className="light small m-0 center mx--3">
                  Potential value
                </p>
                <h1 className="tertiary m-0">
                  €{superfansOverTime[superfansOverTime.length - 1].value.toLocaleString('nl')}
                </h1>
              </div>
            </Col>
            <Col xs={24} lg={4} className="d-flex align-center justify-end">
              <Link to={`/dashboard/${data.brand.handle}/stories/add`}>
                <Button type="primary">
                  Add story
                </Button>
              </Link>
            </Col>
          </Row>
        </div>
      )}
      <InviteModal
        data={data}
        visible={inviteModal}
        setVisible={setInviteModal}
      />
    </div>
  )
}
