/* eslint-disable react/jsx-props-no-spreading */
import React from 'react'
import Emoji from '../Emoji'

export default function SuperfanSignup7({ user }) {
  return (
    <div className="box">
      <h1 className="m-0 mt-3 mb-5">
        <Emoji symbol="🎉" />&nbsp;&nbsp;Done! What happens next?
      </h1>
      <h2 className="m-0">
        <span className="emoji-bullet"><Emoji symbol="🏁" /></span>
        You have to <span className="primary">verify</span> your email
      </h2>
      <p className="custom-ul">
        We sent an email to {user.email}
      </p>

      <div className="separator mb-3" />

      <h2 className="m-0">
        <span className="emoji-bullet"><Emoji symbol="🔎" /></span>
        <span className="light">
          We will verify your details
        </span>
      </h2>

      <div className="separator mb-3" />

      <h2 className="m-0">
        <span className="emoji-bullet"><Emoji symbol="📩" /></span>
        <span className="light">
          We get in contact with you by email
        </span>
      </h2>
    </div>
  )
}
