import {
  Button, Col, Input, message, Row,
} from 'antd'
import React, { useState } from 'react'
import update from 'react-addons-update'
import createContact from '../emails/createContact'
import sendBrandSuggestionEmail from '../emails/sendBrandSuggestionEmail'
import validateEmail from '../utils/validateEmail'
import FloatLabel from './FloatLabel'

export default function SuggestBrandFields() {
  const [user, setUser] = useState({})
  const [isEmailSent, setIsEmailSent] = useState()
  const [loading, setLoading] = useState()

  return (
    <>
      {isEmailSent === true ? (
        <h2>
          Thanks {user.firstName}! Your request is submitted, we will be in touch soon.
        </h2>
      ) : (
        <Row gutter={[24, 16]}>
          <Col xs={24} md={12}>
            <FloatLabel
              label="First Name"
              value={user.firstName}
            >
              <Input
                value={user.firstName}
                name="first-name"
                onChange={e => setUser(update(user, { firstName: { $set: e.target.value } }))}
              />
            </FloatLabel>
          </Col>
          <Col xs={24} md={12}>
            <FloatLabel
              label="Last Name"
              value={user.lastName}
            >
              <Input
                value={user.lastName}
                name="last-name"
                onChange={e => setUser(update(user, { lastName: { $set: e.target.value } }))}
              />
            </FloatLabel>
          </Col>
          <Col xs={24} className="p-0" />
          <Col xs={24} md={12}>
            <FloatLabel
              label="Brand"
              value={user.brand}
            >
              <Input
                value={user.brand}
                name="brand"
                onChange={e => setUser(update(user, { brand: { $set: e.target.value } }))}
              />
            </FloatLabel>
          </Col>
          <Col xs={24} md={12}>
            <FloatLabel
              label="Email address"
              value={user.email}
            >
              <Input
                value={user.email}
                name="email"
                onChange={e => setUser(update(user, { email: { $set: e.target.value } }))}
                onBlur={e => setUser(update(user, { isValidEmail: { $set: validateEmail(e.target.value) } }))}
              />
            </FloatLabel>
            {user.isValidEmail === false && (
            <p className="primary">
              Please use valid email address
            </p>
            )}
          </Col>
          <Col xs={24} className="d-flex justify-center">
            <Button
              style={{ width: '180px' }}
              type="primary mt-4"
              loading={loading}
              disabled={!user.firstName || !user.lastName || !user.brand || !user.email}
              onClick={async () => {
                setLoading(true)
                const emailData = {
                  email: user.email,
                  firstName: user.firstName,
                  lastName: user.lastName,
                  brand: user.brand,
                }
                const emailResponse = await sendBrandSuggestionEmail(emailData)
                await createContact(emailData)
                if (emailResponse === true) {
                  setIsEmailSent(true)
                  setLoading()
                } else {
                  message.error('Something went wrong sending your form')
                  setLoading()
                }
              }}
            >
              Send
            </Button>
            {isEmailSent === 'error' && (
            <p className="primary">
              Something went wrong, please check your information and try again
            </p>
            )}
          </Col>
        </Row>
      )}
    </>
  )
}
