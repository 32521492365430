import axios from 'axios'

export default function getEmailData(uuid) {
  const url = `${process.env.REACT_APP_FUNCTIONS_URL}/getEmailData`
  const headers = { accept: 'application/json' }

  return axios
    .get(url, { params: { uuid }, headers })
    .then(response => response.data)
    .catch(error => error)
}
