import firebase from 'firebase'

export default async function getStories(brandID) {
  const db = firebase.firestore()

  const stories = []
  await db
    .collection('stories')
    .where('brandID', '==', brandID)
    .get()
    .then(querySnapshot => {
      querySnapshot.forEach(doc => {
        stories.push({
          ...doc.data(),
          id: doc.id,
        })
      })
    })

  return stories
}
