import React, { useState } from 'react'
import {
  Row, Col, Button, Modal,
} from 'antd'

import { Link } from 'react-router-dom'
import { ReactComponent as LongArrow } from '../icons/long-arrow.svg'
import { ReactComponent as Quote } from '../icons/quote.svg'

import Ripple from './Ripple'
import CompanySignupFields from './CompanySignupFields'

export default function Home() {
  const [modalStatus, setModalStatus] = useState()

  return (
    <div className="home">
      <div className="container container-x">
        <Row className="bg-gray-light full-width-mobile relative">
          <Col xs={1} lg={2} />
          <Col xs={23} md={11} lg={9} className="mb-9">
            <h1 className="title">
              <span className="secondary">Superfans</span> are waiting for you
            </h1>

            <p className="mb-6 large">
              Your superfans love to spread your story through their social network. We help you to collect and activate your superfans easily.
            </p>

            <p className="bold italic mb-1">
              Access your first superfans
            </p>

            <Button type="primary" onClick={() => setModalStatus(true)}>
              Leave email address
            </Button>
          </Col>
          <Col xs={0} lg={1} />
          <Col xs={0} md={12} className="hero">
            <img src="/img/hero.png" alt="hero" />
          </Col>
          <div className="read-more">
            <p>
              read more
            </p>
            <LongArrow />
          </div>
        </Row>
        <div className="d-flex justify-end">
          <Row className="align-center brands mt-3" gutter={[0, 8]}>
            <Col
              xs={{ span: 21, offset: 3 }}
              sm={{ span: 11, offset: 4 }}
            >
              <p className="m-0">
                These brands love us
              </p>
            </Col>
            <Col
              xs={{ span: 4, offset: 12 }}
              sm={{ span: 3, offset: 0 }}
              className="d-flex justify-center"
            >
              <div
                className="logo-container d-flex align-center"
                style={{ backgroundImage: 'url(/img/brands/klabu.png)' }}
              />
            </Col>
            <Col
              xs={4}
              sm={3}
              className="d-flex justify-center"
            >
              <div
                className="logo-container d-flex align-center"
                style={{ backgroundImage: 'url(/img/brands/dutch-weed-burger.png)' }}
              />
            </Col>
            <Col
              xs={4}
              sm={3}
              className="d-flex justify-center"
            >
              <div
                className="logo-container d-flex align-center"
                style={{ backgroundImage: 'url(/img/brands/forest-forces.png)' }}
              />
            </Col>
          </Row>
        </div>
        <Row className="md-container">
          <Col className="d-flex direction-column align-center mt-4">
            <h1 className="center title">
              Superfan Theory
            </h1>
            <p className="center large relative">
              Fans love your mission and product while Superfans go above and beyond to spread it, because they identify with it. Superfans are your invisible brand ambassadors. Connect and celebrate your superfans to build a tribe that works for you. Most mission-driven brands have superfans, yet ignore them and waste this potential. Are you?
            </p>
            <Link to="/calculate-potential">
              <Button type="primary">
                Calculate potential
              </Button>
            </Link>
            <img src="/img/home-1.png" alt="superfan" className="top-overlay" />
          </Col>
        </Row>
        <h1 className="title">
          How it <span className="secondary">works</span>
        </h1>
        <div>
          <Row className="bg-gray-light mx--padding how-it-works how-it-works-left">
            <Col xs={1} lg={2} />
            <Col xs={22} lg={6}>
              <h1 className="secondary">
                01. Collect
              </h1>
              <p>
                Find and organise your superfans quickly and easily.
              </p>
            </Col>
            <Col xs={0} lg={2} />
            <Col xs={24} md={12} lg={7}>
              <img src="/img/home-2.png" alt="home" />
            </Col>
            <Col xs={24} md={12} lg={7}>
              <img src="/img/home-3.png" alt="home" />
            </Col>
          </Row>
          <Row className="bg-gray-light mx--padding how-it-works how-it-works-right">
            <Col xs={{ span: 24, order: 3 }} lg={{ span: 14, order: 1 }}>
              <img src="/img/home-4.png" alt="home" />
            </Col>
            <Col xs={{ span: 1, order: 1 }} lg={{ span: 2, order: 2 }} />
            <Col xs={{ span: 22, order: 2 }} lg={{ span: 6, order: 3 }}>
              <h1 className="secondary">
                02. Activate
              </h1>
              <p>
                Activate your superfans with stories and track their engagement.
              </p>
            </Col>
          </Row>
          <Row className="bg-gray-light mx--padding how-it-works how-it-works-left">
            <Col xs={1} lg={2} />
            <Col xs={22} lg={6}>
              <h1 className="secondary mt--5">
                03. Celebrate
              </h1>
              <p>
                Give shout-outs, show gratitude, and connect with your superfans personally.
              </p>
            </Col>
            <Col xs={0} lg={5} />
            <Col xs={12} lg={5}>
              <img src="/img/home-5.png" alt="home" />
            </Col>
            <Col xs={0} lg={1} />
            <Col xs={12} lg={5}>
              <img src="/img/home-6.png" alt="home" />
            </Col>
          </Row>
        </div>

        <div className="mt-6">
          <Ripple>
            <div className="d-flex direction-column">
              <img src="/img/home-7.png" alt="profile" className="ripple-profile" />
              Jan van Hövell
              <p className="bold center m-0">
                CEO Klabu
              </p>
            </div>
          </Ripple>
          <div className="quote">
            <Quote className="secondary" />
            <h1>
              If we had this when we started 3 years ago we would have used it right away.
            </h1>
          </div>
        </div>
      </div>
      <div className="bg-gray-light">
        <div className="container">
          <Row gutter={[24, 16]}>
            <Col xs={24} lg={14}>
              <CompanySignupFields />
            </Col>
          </Row>
        </div>
      </div>

      <Modal
        className="modal-hide-footer"
        visible={modalStatus !== undefined}
        onCancel={() => setModalStatus()}
        width={620}
      >
        <div className="padding">
          <Row gutter={[24, 16]}>
            <CompanySignupFields />
          </Row>
        </div>
      </Modal>
    </div>
  )
}
