/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState } from 'react'

const FloatLabel = props => {
  const [focus, setFocus] = useState(false)
  const {
    children, label, labelStyle, value, className,
  } = props

  const float = focus || (value && value.length !== 0)

  const labelClass = float ? 'label label-float' : 'label'

  return (
    <div
      className={`float-label ${className || ''}`}
      onBlur={() => setFocus(false)}
      onFocus={() => setFocus(true)}
    >
      {children}
      <label
        style={float ? null : labelStyle}
        className={labelClass}
      >
        {label}
      </label>
    </div>
  )
}

export default FloatLabel
