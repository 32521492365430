import {
  Button, Input, message, Modal, Table,
} from 'antd'
import React, { useEffect, useState } from 'react'
import FloatLabel from '../FloatLabel'
import getSuperAdmins from '../../api/getSuperAdmins'
import validateEmail from '../../utils/validateEmail'
import setSuperAdmin from '../../api/setSuperAdmin'
import removeSuperAdmin from '../../api/removeSuperAdmin'

const { Column } = Table

export default function SuperAdmins() {
  const [superAdmins, setSuperAdmins] = useState([])
  const [reload, setReload] = useState()
  const [loading, setLoading] = useState()
  const [modal, setModal] = useState()
  const [email, setEmail] = useState()

  useEffect(() => {
    const loadSuperAdmins = async () => {
      setSuperAdmins(await getSuperAdmins())
    }

    loadSuperAdmins()
  }, [reload])

  const tableData = superAdmins.map(admin => ({
    ...admin,
    key: admin.id,
    name: `${admin.firstName || '-'} ${admin.lastName || ''}`,
    email: admin.email,
    button: (
      <div className="d-flex justify-end">
        <Button
          type="secondary"
          loading={loading === admin.id}
          onClick={async () => {
            setLoading(admin.id)
            const response = await removeSuperAdmin(admin.id)
            if (response === true) {
              message.success(`${admin.firstName} succesfully removed as super admin`)
              setReload(admin.id)
              setLoading()
            } else {
              message.error('Something went wrong')
              setLoading()
            }
          }}
        >
          Remove
        </Button>
      </div>
    ),
  }))

  return (
    <div className="component">
      <div className="d-flex justify-between align-center mb-3">
        <h1>
          Manage Super Admins
        </h1>
        <Button
          type="primary"
          onClick={() => setModal(true)}
        >
          Add Super Admin
        </Button>
      </div>
      <Table dataSource={tableData}>
        <Column title="Name" dataIndex="name" key="name" />
        <Column title="Email" dataIndex="email" key="email" />
        <Column title="" dataIndex="button" key="button" />
      </Table>
      <Modal
        visible={modal}
        onCancel={() => setModal()}
        okButtonProps={{
          loading: loading === 'modal',
          disabled: !validateEmail(email),
        }}
        okText="Invite"
        onOk={async () => {
          setLoading('modal')
          const response = await setSuperAdmin(email)
          if (response === true) {
            setModal()
            setReload(email)
            message.success(`${email} has been invited as a super admin 😎`)
            setLoading()
          } else {
            message.error('Something went wrong')
            setLoading()
          }
        }}
      >
        <FloatLabel
          value={email}
          label="Email"
        >
          <Input
            value={email}
            onChange={e => setEmail(e.target.value)}
          />
        </FloatLabel>
      </Modal>
    </div>
  )
}
