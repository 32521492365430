import React from 'react'

export default function HeaderGraphic({
  children, style, boxStyle, foregroundColor, backgroundColor,
}) {
  return (
    <div className="header-graphic" style={style}>
      <div className="background" style={{ ...boxStyle, backgroundColor, color: backgroundColor }}>
        {children}
      </div>
      <div className="foreground" style={{ ...boxStyle, backgroundColor: foregroundColor }}>
        {children}
      </div>
    </div>
  )
}
