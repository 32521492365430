/* eslint-disable react/jsx-props-no-spreading */
import React from 'react'
import { Link } from 'react-router-dom'
import { Button, Input } from 'antd'
import { useDropzone } from 'react-dropzone'

import Emoji from '../Emoji'
import getSuperfanPrefix from '../../utils/getSuperfanPrefix'

export default function SuperfanSignup4({ brand, user, setUser }) {
  const { getRootProps, getInputProps } = useDropzone({
    accept: 'video/*',
    onDrop: acceptedFiles => {
      const newFile = acceptedFiles[0]
      const preview = URL.createObjectURL(acceptedFiles[0])
      const extension = newFile.name.slice(newFile.name.lastIndexOf('.'))
      setUser({
        ...user,
        video: {
          file: newFile,
          url: preview,
          extension,
        },
      })
    },
  })

  return (
    <div className="box">
      <h1 className="m-0 mt-3">
        <Emoji symbol="✊🏼" />&nbsp;&nbsp;Your motivation
      </h1>
      <p>
        Explain your motivation for becoming a{getSuperfanPrefix(brand.customSuperfan)} {brand.customSuperfan || 'superfan'} either by text message or video message. Don&apos;t worry, it&apos;s just about getting to know you a little.
      </p>

      <div className="small-container">
        <Input.TextArea
          placeholder={`Example: I love ${brand.name}’s mission because..`}
          className="mb-3"
          value={user.quote}
          onChange={e => setUser({ ...user, quote: e.target.value })}
        />

        {user.video.url ? (
          <div className="uploaded-video">
            <p>
              Looking fabulous!
            </p>
            <p>
              Please check if your video is visible in the preview:
            </p>
            <video
              controls
              preload="none"
            >
              <source src={user.video.url} type="video/mp4" />
            </video>

            <div
              className="underline center pointer mb-3"
              onClick={() => setUser({ ...user, video: {} })}
            >
              Remove video
            </div>
          </div>
        ) : (
          <>
            <p className="center">
              Upload your video message (max. 2GB)
            </p>

            <div key="dropzone">
              <div {...getRootProps()}>
                <input {...getInputProps()} />
                <Button
                  type="secondary"
                  className="d-block m-auto my-3"
                >
                  Upload video
                </Button>
              </div>
            </div>
          </>
        )}

        <Link to={`/${brand.handle}/join/5`}>
          <Button
            type="primary"
            className="w-100"
            disabled={!user.quote && !user.video.file}
          >
            Continue
          </Button>
        </Link>
      </div>
    </div>
  )
}
