import firebase from 'firebase'
import { notification, message } from 'antd'

export default function logout() {
  return firebase.auth().signOut()
    .then(() => {
      message.success('You are logged out')
      return 'success'
    })
    .catch(error => {
      // TODO: report error
      console.error(error)
      notification.error({
        message: 'Couldn\'t log you out',
        description: 'Please try again later',
      })
    })
}
