import React from 'react'
import { Carousel } from 'react-responsive-carousel'
import { Link } from 'react-router-dom'
import { Button } from 'antd'

import { ReactComponent as Profile } from '../../icons/profile.svg'
import { ReactComponent as Quote } from '../../icons/quote.svg'

export default function PendingSuperfanCarousel({ superfans, data }) {
  return (
    <Carousel renderThumbs={() => []}>
      {superfans.map(superfan => (
        <div key={superfan.id}>
          <div className="pending-card">
            <div className="d-flex direction-column align-center">
              <Link to={`/dashboard/${data.brand.handle}/superfans/${superfan.id}`} className="superfan-profile d-flex direction-column align-center">
                {superfan.imageUrl ? (
                  <img src={superfan.imageUrl} alt="icon" className="profile round" />
                ) : (
                  <Profile className="profile light" />
                )}
                <div className="center">
                  <p className="m-0 regular">
                    {superfan.firstName} {superfan.lastName}
                  </p>
                  {superfan.reach && (
                  <p className="light">
                    Reaches {superfan.reach} friends
                  </p>
                  )}
                </div>
              </Link>
            </div>
            <div>
              <Quote className="icon" />
              <p className="copy">
                {superfan.quote && superfan.quote.length > 120 ? `${superfan.quote.slice(0, 120)}...` : superfan.quote}
              </p>
            </div>
            <Link to={`/dashboard/${data.brand.handle}/superfans/${superfan.id}`} className="button-container">
              <Button
                type="primary"
                className="mb-6 d-block m-auto"
              >
                Evaluate
              </Button>
            </Link>
          </div>
        </div>
      ))}
    </Carousel>
  )
}
