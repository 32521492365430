import React from 'react'
import { Spin } from 'antd'

export default function Loader() {
  return (
    <div className="full-screen d-flex align-center justify-center">
      <div className="d-flex align-center justify-center direction-column" style={{ width: '300px', marginTop: '-200px' }}>
        <img src="/img/logo.svg" alt="superfan-logo" style={{ width: '200px', marginBottom: '30px' }} />
        <Spin size="large" />
      </div>
    </div>
  )
}
