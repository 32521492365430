import React from 'react'
import { Link } from 'react-router-dom'
import {
  Button, Table,
} from 'antd'

import Reach from './charts/Reach'
import Value from './charts/Value'
import SuperfanCarousel from './SuperfanCarousel'
import { ReactComponent as Profile } from '../../icons/profile.svg'

const { Column } = Table

export default function Statistics({
  data,
  dateRange,
  setDateRange,
  startDate,
  acceptedSuperfans,
  superfansOverTime,
}) {

  const superfanTableData = acceptedSuperfans.map(superfan => ({
    ...superfan,
    key: superfan.id,
    profile: (
      <Link to={`/dashboard/${data.brand.handle}/superfans/${superfan.id}`} className="superfan-profile d-flex align-center">
        {superfan.imageUrl ? (
          <img src={superfan.imageUrl} alt="icon" className="profile-img round" />
        ) : (
          <Profile className="profile-img light" />
        )}
        <h2 className="mb-0 ml-3">
          {superfan.firstName} {superfan.lastName}
        </h2>
      </Link>
    ),
    reachFormatted: superfan.reach ? superfan.reach.toLocaleString('nl') : 'unknown',
  }))

  return (
    <div className="statistics">
      <div className="dashboard-top" style={{ gridArea: 'dashboard-top' }}>
        <div className="title">
          <div className="greeting">
            <h1 className="bold">
              Statistics
            </h1>
          </div>
          <Link to={`/dashboard/${data.brand.handle}/stories/add`}>
            <Button type="primary">
              Add story
            </Button>
          </Link>
        </div>
        <div className="separator" />
      </div>

      <Reach
        superfansOverTime={superfansOverTime}
        startDate={startDate}
        dateRange={dateRange}
        setDateRange={setDateRange}
      />

      <div className="superfans component" style={{ gridArea: 'superfans' }}>
        <h2>
          Superfans by reach
        </h2>
        <Table dataSource={superfanTableData} showHeader={false} pagination={false}>
          <Column title="Profile" dataIndex="profile" key="profile" />
          <Column
            title="Reach"
            dataIndex="reachFormatted"
            key="reach"
            defaultSortOrder="descend"
            sorter={(a, b) => a.reach - b.reach}
          />
        </Table>
      </div>

      <Value
        superfansOverTime={superfansOverTime}
        stories={data.brand.stories.filter(story => story.created)}
      />

      <div className="quotes component" style={{ gridArea: 'quotes' }}>
        <h2>
          Superfan&apos;s quotes
        </h2>
        <SuperfanCarousel superfans={acceptedSuperfans} data={data} />
      </div>
    </div>
  )
}
