import React, { useState } from 'react'
import firebase from 'firebase'
import {
  Button, Checkbox, message, Modal,
} from 'antd'
import PersonalInformation from './PersonalInformation'
import Emoji from '../Emoji'

export default function OnboardingModal({
  visible, setVisible, data, setData,
}) {
  const [terms, setTerms] = useState()
  const [step, setStep] = useState(1)

  const db = firebase.firestore()

  const handleClick = () => {
    if (step === 1) {
      setStep(2)
    } else {
      db
        .collection('users')
        .doc(data.user.uid)
        .update({ acceptedTerms: true })
        .then(() => setVisible())
        .catch(error => message.error(error.message))
    }
  }

  return (
    <Modal
      className="onboarding-modal"
      visible={visible}
      closable={false}
      footer={[
        <Button
          type="primary"
          disabled={!terms}
          onClick={handleClick}
        >
          {step === 1 ? 'Next' : 'Accept and continue to dashboard'}
        </Button>,
      ]}
    >
      <h1 className="m-0 pr-3">
        Welcome to the superfan dashboard of {data.brand.name} <Emoji symbol="🙌🏾" /> <Emoji symbol="🙌🏼" />
      </h1>
      {step === 1 ? (
        <>
          <p>
            Please accept the T&amp;C&apos;s and let us know who you are to continue
          </p>
          <PersonalInformation
            data={data}
            setData={setData}
          />
          <Checkbox
            checked={terms}
            onChange={() => setTerms(!terms)}
            className="mt-3"
          >
            I have read and accept the <a href="/terms-of-use.pdf" target="_blank">Terms of Use</a>
          </Checkbox>
        </>
      ) : (
        <>
          <p>
            Please review the platform guidelines and accept them to continue
          </p>
          <ul className="checklist">
            <li>
              Collect superfans with kindness and activate them with love.
            </li>
            <li>
              We use the platform as intended and prevent mal use. We remain responsible for our account.
            </li>
            <li>
              We don’t spread hate, division, and other things that contradict Superfan’s Terms & Conditions.
            </li>
          </ul>
        </>
      )}
    </Modal>
  )
}
