/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useState, useCallback } from 'react'
import firebase from 'firebase'
import queryString from 'query-string'
import { useLocation, useHistory } from 'react-router-dom'
import { useDropzone } from 'react-dropzone'
import update from 'immutability-helper'
import {
  Input, Space, message, Popconfirm,
} from 'antd'

import FloatLabel from '../FloatLabel'
import { ReactComponent as Image } from '../../icons/image.svg'
import authenticate from '../../api/authenticate'

let timeOut

export default function PersonalInformation({ data, setData }) {
  const [user, setUser] = useState(data.user)
  const [dropzone, setDropzone] = useState(!data.user.imageUrl && true)
  const [loginPop, setLoginPop] = useState()
  const [loading, setLoading] = useState()
  const location = useLocation()
  const history = useHistory()

  const params = queryString.parse(location.search)
  const db = firebase.firestore()
  const storage = firebase.storage()

  useEffect(() => {
    const unsubscribe = db
      .collection('users')
      .doc(data.user.uid)
      .onSnapshot(async doc => {
        const userData = doc.data()
        setData({
          ...data,
          user: {
            ...data.user,
            ...userData,
          },
        })
      })

    return () => {
      unsubscribe()
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const storeValue = (key, value) => {
    if (value !== data.user[key]) {
      db.collection('users').doc(user.uid).set({
        [key]: value,
      }, { merge: true })
        .then(() => message.success('Updated data'))
        .catch(() => message.error('Couldn\'t save data'))
    }
  }

  const { getRootProps, getInputProps } = useDropzone({
    accept: 'image/jpeg, image/png',
    onDrop: acceptedFiles => {
      const file = acceptedFiles[0]
      const fileExtension = file.name.slice(file.name.lastIndexOf('.'))
      const ref = storage.ref().child(`images/user-profiles/${user.uid}${fileExtension}`)
      ref.put(file).then(() => {
        ref.getDownloadURL().then(url => {
          setUser(update(user, { imageUrl: { $set: url } }))
          storeValue('imageUrl', url)
        })
      })
    },
  })

  const handleChange = (key, value) => {
    setUser(update(user, { [key]: { $set: value } }))
    clearTimeout(timeOut)
    timeOut = setTimeout(() => storeValue(key, value), 1000)
  }

  const updateEmail = useCallback(
    email => {
      firebase.auth().currentUser.updateEmail(email)
        .then(() => {
          message.success('Updated data')
          window.localStorage.removeItem('newEmail')
          setData(d => update(d, { user: { email: { $set: email } } }))

          // Also update email in user profile
          db
            .collection('users')
            .doc(data.user.uid)
            .set({ email }, { merge: true })
        })
        .catch(error => {
          if (error.code === 'auth/requires-recent-login') {
            setLoginPop(true)
          } else if (error.code === 'auth/invalid-email') {
            setLoginPop(false)
            message.info('Invalid email')
          } else {
            setLoginPop(false)
            message.error('Couldn\'t save data')
          }
        })
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [],
  )

  const handleEmailChange = (email) => {
    setUser(update(user, { email: { $set: email } }))
    clearTimeout(timeOut)
    if (email !== data.user.email) {
      timeOut = setTimeout(() => updateEmail(email), 2000)
    }
  }

  // Update email if landing with a Sign In link
  useEffect(() => {
    const { newEmail } = params
    if (newEmail) {
      setUser(u => update(u, { email: { $set: newEmail } }))
      updateEmail(newEmail)
      history.push(`/dashboard/${data.brand.handle}/account`)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Space direction="vertical" size="large">
      <h2 className="m-0">
        Personal information
      </h2>

      {data.user.imageUrl ? (
        <div
          className="dropzone-container"
          onMouseEnter={() => setDropzone(true)}
          onMouseLeave={() => setDropzone()}
        >
          <div
            {...getRootProps({
              className: 'dropzone',
              style: {
                border: 'none',
                background:
                          dropzone ? `
                          linear-gradient(
                            rgba(0, 0, 0, 0.45),
                            rgba(0, 0, 0, 0.45)
                          ),
                          url(${user.imageUrl})` : `
                          url(${user.imageUrl})`,
              },
            })}
          >
            <input {...getInputProps()} />
            <div style={{ opacity: dropzone ? 100 : 0, textAlign: 'center' }}>
              <Image className="white" />
              <p className="w-100 white">
                <span className="bold">
                  Upload new profile picture here
                </span>
                {' '}
                or just drag and drop
              </p>
            </div>
          </div>
        </div>
      ) : (
        <div
          className="dropzone-container"
        >
          <div {...getRootProps({ className: 'dropzone' })}>
            <input {...getInputProps()} />
            <Image className="secondary" />
            <p className="w-100">
              <span className="bold secondary">
                Upload profile picture here
              </span>
              {' '}
              or just drag and drop
            </p>
          </div>
        </div>
      )}

      <FloatLabel
        label="First Name"
        value={user.firstName}
      >
        <Input
          value={user.firstName}
          onChange={e => handleChange('firstName', e.target.value)}
        />
      </FloatLabel>

      <FloatLabel
        label="Last Name"
        value={user.lastName}
      >
        <Input
          value={user.lastName}
          onChange={e => handleChange('lastName', e.target.value)}
        />
      </FloatLabel>

      <FloatLabel
        label="Email"
        value={user.email}
      >
        <Popconfirm
          title="Please re-authenticate with your existing email to confirm change"
          visible={loginPop}
          onConfirm={async () => {
            setLoading(true)
            const response = await authenticate(data.user.email, `/dashboard/${data.brand.handle}/account?newEmail=${user.email}`)
            if (response === 'success') {
              setLoading('sent')
            } else {
              message.error('Couldn\'t connect to server')
              setLoading()
            }
          }}
          onCancel={() => {
            setLoginPop(false)
            setUser(update(user, { email: { $set: data.user.email } }))
          }}
          okText={loading === 'sent' ? 'Email sent' : 'Send login link'}
          okButtonProps={{
            loading: loading === true,
            disabled: loading === 'sent',
          }}
        >
          <Input
            value={user.email}
            onChange={e => handleEmailChange(e.target.value)}
          />
        </Popconfirm>
      </FloatLabel>
    </Space>
  )
}
