import React from 'react'
import {
  Button, Modal,
} from 'antd'
import copyToClipboard from '../../utils/copyToClipboard'

export default function InviteModal({
  data, visible, setVisible,
}) {

  const url = `${process.env.REACT_APP_DOMAIN}/${data.brand.handle}`

  return (
    <Modal
      className="onboarding-modal"
      visible={visible}
      footer={[
        <Button
          type="secondary"
          onClick={() => setVisible(false)}
        >
          Close
        </Button>,
      ]}
    >
      <h1 className="m-0 pr-3">
        Invite superfans
      </h1>
      <p>
        Share this link to your brand page
      </p>
      <div
        className="dropzone"
        onClick={() => copyToClipboard(url)}
      >
        <p className="secondary m-0">
          Click here to copy to clipboard
        </p>
        {url}
      </div>
    </Modal>
  )
}
