import getStories from './getStories'
import getSuperfans from './getSuperfans'

export default async function loadBrand(brandData) {
  const superfans = await getSuperfans(brandData.id)
  const stories = await getStories(brandData.id)

  return ({
    ...brandData,
    superfans: superfans.sort((a, b) => a.created - b.created),
    stories: stories.sort((a, b) => a.created - b.created),
  })
}
