import React, { useEffect, useState } from 'react'
import firebase from 'firebase'
import {
  Row, Col, Button, message, Popconfirm, Table,
} from 'antd'
import moment from 'moment'
import TextArea from 'antd/lib/input/TextArea'
import {
  useParams, Link, useHistory,
} from 'react-router-dom'
import update from 'immutability-helper'
import { EyeOutlined } from '@ant-design/icons'

import getValue from '../../utils/getValue'
import colors from '../../styles/colors.scss'

// Import SVG's
import { ReactComponent as Profile } from '../../icons/profile.svg'
import { ReactComponent as ChevronLeft } from '../../icons/chevron-left.svg'
import { ReactComponent as Mail } from '../../icons/mail.svg'
import { ReactComponent as Antenna } from '../../icons/antenna.svg'
import { ReactComponent as Diamond } from '../../icons/diamond.svg'
import { ReactComponent as Quote } from '../../icons/quote.svg'
import { ReactComponent as Heart } from '../../icons/heart.svg'
import { ReactComponent as Comment } from '../../icons/comment.svg'
import { ReactComponent as Check } from '../../icons/check.svg'

import SocialIcon from '../SocialIcon'
import getSuperfans from '../../api/getSuperfans'
import getEmailData from '../../api/getEmailData'

const { Column } = Table

export default function SuperfanDetail({ data, setData, setPreselectedSuperfans }) {
  const [loading, setLoading] = useState()
  const [loadingReject, setLoadingReject] = useState()
  const [popconfirm, setPopconfirm] = useState()
  const [motivation, setMotivation] = useState()
  const [videoMessages, setVideoMessages] = useState([])
  const db = firebase.firestore()
  const history = useHistory()
  const { id } = useParams()

  const { superfans } = data.brand

  const superfan = superfans.find(s => s.userID === id)

  const storiesInvited = data.brand.stories.filter(story => story.created && story.superfans && story.superfans.some(s => s.id === id))
  const storiesViewed = data.brand.stories.filter(story => story.created && story.views && story.views.some(s => s.id === id))
  const value = storiesViewed.reduce((a, b) => a + getValue(b.views.find(s => s.id === id).reach), 0)

  useEffect(() => {
    const getVideoMessages = async () => {
      const videoMessagesArray = []

      for (const videoMessage of superfan.videoMessages) {
        const videoMessageObject = await db
          .collection('videoMessages')
          .doc(videoMessage.id)
          .get()
          .then(doc => ({
            ...doc.data(),
            ...videoMessage,
          }))

        const emailEvents = await getEmailData(videoMessage.messageUuid)

        videoMessagesArray.push({
          ...videoMessageObject,
          emailEvents,
        })
      }

      setVideoMessages(videoMessagesArray)
    }

    if (superfan.videoMessages && superfan.videoMessages.length > 0) {
      getVideoMessages()
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const videoMessagesTable = videoMessages.map(videoMessage => ({
    key: videoMessage.id,
    sent: moment(videoMessage.created).format('MMMM Do YYYY, HH:mm'),
    opens: videoMessage.emailEvents.filter(event => event.name === 'opened').length,
    clicks: videoMessage.emailEvents.filter(event => event.name === 'clicks').length,
    button: (
      <Link to={`/${data.brand.handle}/video-messages/${videoMessage.id}`} className="button-container">
        <Button type="primary">
          View message
        </Button>
      </Link>
    ),
  }))
  return (
    <>
      <div className="superfan-detail">
        <Link to={`/dashboard/${data.brand.handle}/superfans`} className="d-inline-flex align-center regular">
          <ChevronLeft className="mr-1" /> <span className="regular">Superfans</span>
        </Link>

        <div className="content-box">
          <Row align="bottom">
            <Col xs={24} md={16} xl={12}>
              <div className="name mb-4">
                {superfan.imageUrl ? (
                  <img src={superfan.imageUrl} alt="icon" className="round" />
                ) : (
                  <Profile className="light" />
                )}
                <h1>
                  {superfan.firstName} {superfan.lastName}
                </h1>
              </div>
            </Col>
          </Row>
          <Row gutter={[24, 24]}>
            <Col xs={24} md={8} xl={6}>
              <h2>
                Personal data
              </h2>
              <Col span={24} className="profile-info box d-flex align-center justify-between">
                <div className="d-flex align-center">
                  <Mail /> <a className="regular" href={`mailto:${superfan.email}`}>{superfan.email}</a>
                </div>
                {superfan.emailVerified && (
                  <Check style={{ color: colors.tertiary }} className="m-0" />
                )}
              </Col>
              {/* TODO: add other social accounts */}
              {superfan.socialAccounts?.instagram?.username && (
              <Col span={24} className="profile-info box d-flex justify-between my-3 align-center">
                <div className="d-flex align-center">
                  <SocialIcon channel="instagram" />
                  <a className="regular" href={`https://www.instagram.com/${superfan.socialAccounts.instagram.username}`} target="_blank" rel="noopener noreferrer">
                    {superfan.socialAccounts.instagram.username}
                  </a>
                </div>
                {superfan.socialAccounts.instagram.followers}
              </Col>
              )}
              {superfan.socialAccounts?.linkedIn?.username && (
              <Col span={24} className="profile-info box d-flex justify-between my-3 align-center">
                <div className="d-flex align-center">
                  <SocialIcon channel="linkedin" />
                  <a className="regular" href={`https://www.linkedin.com/in/${superfan.socialAccounts.linkedIn.username}`} target="_blank" rel="noopener noreferrer">
                    {superfan.firstName} {superfan.lastName}
                  </a>
                </div>
                {superfan.socialAccounts.linkedIn.connections}
              </Col>
              )}
              {superfan.status === 'accepted' && (
                <Link to={`/dashboard/${data.brand.handle}/video-message`}>
                  <Button
                    type="primary"
                    onClick={() => setPreselectedSuperfans([superfan.id])}
                  >
                    Send video message
                  </Button>
                </Link>
              )}
            </Col>
            <Col xs={24} md={8} xl={6}>
              {superfan.status === 'accepted' && (
              <>
                <h2>
                  Story stats
                </h2>
                <Col span={24} className="profile-info box d-flex justify-between align-center">
                  <div className="d-flex align-center">
                    <Antenna />
                    {storiesInvited.length}
                  </div>
                  stories invited
                </Col>
                <Col span={24} className="profile-info box d-flex justify-between my-3 align-center">
                  <div className="d-flex align-center">
                    <EyeOutlined />
                    {storiesViewed.length}
                  </div>
                  stories viewed
                </Col>
                <Col span={24} className="profile-info box d-flex justify-between my-3 align-center">
                  <div className="d-flex align-center">
                    <Diamond />
                    € {value}
                  </div>
                  potential value
                </Col>
              </>
              )}
            </Col>
            <Col xs={24} md={12}>
              {superfan.quote && (
                <>
                  <h2>
                    Quote:
                  </h2>
                  <div className="quote">
                    <Quote className="icon" />
                    <div className="copy">
                      <p>
                        {superfan.quote}
                      </p>
                    </div>
                  </div>
                </>
              )}

              {superfan.video && (
                <>
                  <h2>
                    Video:
                  </h2>
                  <video
                    controls
                    preload="auto"
                  >
                    <source src={superfan.video} type="video/mp4" />
                    <p className="vjs-no-js">
                      To view this video please enable JavaScript, and consider upgrading to a
                      web browser that supports HTML5 video
                    </p>
                  </video>
                </>
              )}
            </Col>
          </Row>
          {superfan.status === 'accepted' && superfan.stories && (
          <>
            <h2 className="m-0">
              Shared stories ({superfan.stories.length})
            </h2>
            <div className="posts">
              {superfan.stories.map(storyID => {
                const post = data.brand.posts.find(s => s.id === storyID)
                return (
                  <div
                    key={post.id}
                    className="post-container"
                  >
                    <div
                      className="post"
                      style={{ backgroundImage: `url("${post.image}")` }}
                    >
                      <img className="profile" src={superfan.image} alt="profile" />
                      <div className="tag">
                        @{data.brand.name}
                      </div>
                    </div>
                    <Row className="metrics">
                      <Col className="metric" xs={12}>
                        <Heart /> {post.likes}
                      </Col>
                      <Col className="metric" xs={12}>
                        <Comment /> {post.comments}
                      </Col>
                    </Row>
                  </div>
                )
              })}
            </div>
          </>
          )}

          <h2>
            Video messages
          </h2>
          <div className="table">
            <Table
              dataSource={videoMessagesTable}
            >
              <Column title="Sent" dataIndex="sent" key="sent" />
              <Column title="Opens" dataIndex="opens" key="opens" />
              <Column title="Clicks" dataIndex="clicks" key="clicks" />
              <Column title="" dataIndex="button" key="button" />
            </Table>
          </div>
        </div>
      </div>
      {superfan.status !== 'accepted' && (
        <div className="d-flex justify-end mt-3">
          {superfan.status === 'pending' && (
            <Popconfirm
              icon={null}
              title={(
                <div className="popconfirm-reject">
                  Would you like to provide a motivation?
                  <TextArea
                    onChange={e => setMotivation(e.target.value)}
                  />
                </div>
              )}
              visible={popconfirm}
              onConfirm={() => {
                setLoadingReject(true)
                db.collection('applications').doc(superfan.applicationID).set({ status: 'rejected', motivation: motivation || null }, { merge: true }).then(async () => {
                  const newSuperfans = await getSuperfans(data.brand.id)
                  if (newSuperfans) {
                    setData(update(data, { brand: { superfans: { $set: newSuperfans } } }))
                    message.success(`Succesfully rejected ${superfan.firstName}`)
                    history.push(`/dashboard/${data.brand.handle}/superfans`)
                    setLoadingReject()
                  } else {
                    message.error('Error rejecting Superfan')
                    setLoadingReject()
                  }
                })
              }}
              onCancel={() => setPopconfirm(false)}
              okText="Reject"
              okButtonProps={{ loading: loadingReject }}
            >
              <Button
                type="secondary mr-3"
                onClick={() => setPopconfirm(true)}
              >
                Reject
              </Button>
            </Popconfirm>
          )}
          <Button
            type="primary"
            loading={loading}
            onClick={() => {
              setLoading(true)
              db.collection('applications').doc(superfan.applicationID).set({ status: 'accepted' }, { merge: true }).then(async () => {
                const newSuperfans = await getSuperfans(data.brand.id)
                if (newSuperfans) {
                  setData(update(data, { brand: { superfans: { $set: newSuperfans } } }))
                  message.success(`${superfan.firstName} is now a Superfan of ${data.brand.name} 🤩`)
                  history.push(`/dashboard/${data.brand.handle}/superfans`)
                } else {
                  message.error('Error accepting Superfan')
                }
              })
            }}
          >
            Accept
          </Button>
        </div>
      )}
    </>
  )
}
