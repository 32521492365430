import firebase from 'firebase'

export default function createUser(email) {
  const password = Math.random().toString(36).substr(2, 9)

  return firebase
    .auth()
    .createUserWithEmailAndPassword(email, password)
    .then(() => firebase.auth().currentUser.uid)
    .catch(error => {
      // TODO: report error
      console.log(error)
    })
}
