import firebase from 'firebase'

export default async function getSuperfans(brandID) {
  const db = firebase.firestore()

  const applications = []
  await db
    .collection('applications')
    .where('brandID', '==', brandID)
    .get()
    .then(querySnapshot => {
      querySnapshot.forEach(doc => {
        applications.push({
          ...doc.data(),
          applicationID: doc.id,
        })
      })
    })
    .catch(error => console.log(error))

  const superfans = []
  for (const application of applications) {
    // eslint-disable-next-line no-await-in-loop
    const superfan = await db
      .collection('users')
      .doc(application.userID)
      .get()
      .then(doc => doc.data())
      .catch(error => console.log(error))

    superfans.push({
      ...application,
      ...superfan,
      id: application.userID,
    })
  }
  return superfans
}
