import {
  Button, Col, Input, message, Row,
} from 'antd'
import React, { useState } from 'react'
import update from 'react-addons-update'
import createContact from '../emails/createContact'
import sendCompanySignupEmail from '../emails/sendCompanySignupEmail'
import validateEmail from '../utils/validateEmail'
import FloatLabel from './FloatLabel'

export default function CompanySignupFields() {
  const [user, setUser] = useState({})
  const [isEmailSent, setIsEmailSent] = useState()
  const [loading, setLoading] = useState()

  const confirmHeader = (
    <>
      <h1 className="title">
        <span className="secondary">Sent!</span> Sit back and relax.
      </h1>
      <p className="m-0">
        Thanks for leaving your details. We’ll get in touch with your shortly about your superfans.
      </p>
    </>
  )

  const signupHeader = (
    <>
      <h1 className="title">
        Get your first superfans <span className="secondary">waitlisted</span>
      </h1>
      <p className="m-0">
        Want to know how many superfans of your brand are already excited to collaborate? We want to help you get started as easy and quickly as possible. Leave your details below, and we’ll connect you to first superfans whom are excited to collaborate with you.
      </p>
    </>
  )

  return (
    <Row gutter={[24, 16]}>
      <Col xs={24}>
        {isEmailSent === true ? confirmHeader : signupHeader }
      </Col>
      <Col xs={24}>
        <Row gutter={[24, 16]}>
          {!isEmailSent && (
          <>
            <Col xs={24} md={12}>
              <FloatLabel
                label="First Name"
                value={user.firstName}
              >
                <Input
                  value={user.firstName}
                  name="first-name"
                  onChange={e => setUser(update(user, { firstName: { $set: e.target.value } }))}
                />
              </FloatLabel>
            </Col>
            <Col xs={24} md={12}>
              <FloatLabel
                label="Last Name"
                value={user.lastName}
              >
                <Input
                  value={user.lastName}
                  name="last-name"
                  onChange={e => setUser(update(user, { lastName: { $set: e.target.value } }))}
                />
              </FloatLabel>
            </Col>
            <Col xs={24} className="p-0" />
            <Col xs={24} md={12}>
              <FloatLabel
                label="Company"
                value={user.company}
              >
                <Input
                  value={user.company}
                  name="company"
                  onChange={e => setUser(update(user, { company: { $set: e.target.value } }))}
                />
              </FloatLabel>
            </Col>
            <Col xs={24} md={12}>
              <FloatLabel
                label="Email address"
                value={user.email}
              >
                <Input
                  value={user.email}
                  name="email"
                  onChange={e => setUser(update(user, { email: { $set: e.target.value } }))}
                  onBlur={e => setUser(update(user, { isValidEmail: { $set: validateEmail(e.target.value) } }))}
                />
              </FloatLabel>
              {user.isValidEmail === false && (
              <p className="primary">
                Please use valid email address
              </p>
              )}
            </Col>
            <Col xs={24}>
              <Button
                type="primary"
                loading={loading}
                disabled={!user.firstName || !user.lastName || !user.company || !user.email}
                onClick={async () => {
                  setLoading(true)
                  const emailData = {
                    email: user.email,
                    firstName: user.firstName,
                    lastName: user.lastName,
                    company: user.company,
                  }
                  const emailResponse = await sendCompanySignupEmail(emailData)
                  await createContact(emailData)
                  if (emailResponse === true) {
                    setIsEmailSent(true)
                    setLoading()
                  } else {
                    message.error('Something went wrong sending your form')
                    setLoading()
                  }
                }}
              >
                Send
              </Button>
              {isEmailSent === 'error' && (
              <p className="primary">
                Something went wrong, please check your information and try again
              </p>
              )}
            </Col>
          </>
          )}
        </Row>
      </Col>
    </Row>
  )
}
