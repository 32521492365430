import firebase from 'firebase'

export default async function getBrands(adminOf) {
  const db = firebase.firestore()
  const brands = []

  await db
    .collection('brands')
    .where('__name__', 'in', adminOf)
    .get()
    .then(querySnapshot => {
      querySnapshot.forEach(doc => {
        brands.push({
          ...doc.data(),
          id: doc.id,
        })
      })
    })

  return brands
}
