import getValue from './getValue'

const getStoryValue = views => {
  const value = []
  if (views) {
    views.forEach(view => {
      value.push(getValue(view.reach))
    })
  }

  return value.reduce((a, b) => a + b, 0)
}

export default getStoryValue
