import firebase from 'firebase'

export default function signInWithEmailLink(email) {
  // Additional state parameters can also be passed via URL.
  // This can be used to continue the user's intended action before triggering
  // the sign-in operation.
  // The client SDK will parse the code from the link for you.
  return firebase.auth().signInWithEmailLink(email || '', window.location.href)
    .then(result => {
      // Clear email from storage.
      window.localStorage.removeItem('emailForSignIn')
      // You can access the new user via result.user
      // Additional user info profile not available via:
      // result.additionalUserInfo.profile == null
      // You can check if the user is new or existing:
      // result.additionalUserInfo.isNewUser
      return result.user
    })
    .catch(error => error)
}
