import React, { useState } from 'react'
import {
  Button, Card, Col, Row, Slider,
} from 'antd'

import getValue from '../utils/getValue'
import colors from '../styles/colors.scss'
import CompanySignupFields from './CompanySignupFields'

export default function CalculatePotential({ setModalStatus }) {
  const [superfans, setSuperfans] = useState(30)

  const getCategory = () => {
    if (superfans <= 50) {
      return '0 - 50'
    }
    if (superfans <= 100) {
      return '51 - 100'
    }
    if (superfans <= 300) {
      return '51 - 300'
    }

    return '300+'
  }

  const getPrice = () => {
    if (superfans <= 50) {
      return 2000
    }

    return 3500
  }

  return (
    <div className="calculate-potential">
      <div className="bg-ripple-half">
        <div className="container">
          <Row gutter={[24, 24]}>
            <Col xs={24} md={12}>
              <h1 className="title">
                Calculate the potential of your <span className="secondary">superfans</span>
              </h1>
              <p className="large">
                Use our calculator to find out how much value your superfans create for your brand. The more superfans join your tribe, the stronger and more valuable your movement will be.
              </p>
              <Slider
                className="mt-6"
                tooltipVisible={false}
                value={superfans}
                max={500}
                onChange={value => setSuperfans(value)}
                marks={{
                  0: {
                    style: {
                      color: superfans <= 50 && colors.secondary,
                    },
                    label: 0,
                  },
                  50: {
                    style: {
                      color: superfans <= 100 && colors.secondary,
                    },
                    label: 50,
                  },
                  100: {
                    style: {
                      color: superfans > 50 && superfans <= 300 && colors.secondary,
                    },
                    label: 100,
                  },
                  300: {
                    style: {
                      color: superfans > 100 && colors.secondary,
                    },
                    label: 300,
                  },
                }}
                tooltipPlacement="bottom"
              />
              <Card className="mt-6 calculator-data">
                <p className="large m-0">
                  With <span className="highlight" style={{ background: colors.tertiary }}>{superfans}</span> superfans
                </p>
                <p className="large m-0">
                  You&apos;ll reach <span className="highlight" style={{ background: colors.secondary }}>{(superfans * 500).toLocaleString('nl')}</span> people
                </p>
                <p className="large m-0">
                  Estimated value of <span className="highlight" style={{ background: colors.tertiary }}>€ {(getValue(500) * superfans).toLocaleString('nl')}</span><span className="small light">/message</span>
                </p>
              </Card>
            </Col>
            <Col xs={24} md={12} className="d-flex justify-center">
              <Card style={{ width: 300 }} className="pricing-card">
                <div className="pricing">
                  <h1>
                    <span className="secondary">Standard</span> membership
                  </h1>

                  <p className="bold">
                    Pricing
                  </p>
                  <div className="d-flex justify-between">
                    <p>
                      {getCategory()} superfans
                    </p>
                    <p>
                      € {(getPrice()).toLocaleString('nl')},-
                    </p>
                  </div>
                  <div className="d-flex justify-between">
                    <p className="tertiary">
                      Pilot discount 50%
                    </p>
                    <p className="tertiary bold">
                      - € {(getPrice() / 2).toLocaleString('nl')},-
                    </p>
                  </div>
                  <div className="separator" />
                  <div className="d-flex justify-between">
                    <p className="bold">
                      Total
                    </p>
                    <p className="bold">
                      € {(getPrice() / 2).toLocaleString('nl')},-
                    </p>
                  </div>
                </div>
                <div className="pricing-overlay">
                  <h2 className="secondary">
                    Custom prices only
                  </h2>
                </div>
                <Button
                  type="primary"
                  className="w-100 my-3"
                  onClick={() => setModalStatus(true)}
                >
                  Book demo
                </Button>
                <p className="small bold m-0">
                  Not satisfied?
                </p>
                <p className="small">
                  We only like to receive happy money from happy customers. Get your money back if you’re not happy (only within the first month).
                </p>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
      <div className="container">
        <Row gutter={[24, 24]}>
          <Col xs={24} md={16}>
            <h1 className="title">
              We&apos;ve got everything set for you to get started
            </h1>
          </Col>
        </Row>
        <Row gutter={[24, 24]}>
          <Col xs={24} md={8}>
            <img className="mb-4" src="/img/fist.svg" alt="fist" />
            <h1>
              <span className="secondary mb-3">First</span> superfans
            </h1>
            <p>
              We help you to connect to your first superfans so that you can get started easily.
            </p>
          </Col>
          <Col xs={24} md={8}>
            <img className="mb-4" src="/img/brush.svg" alt="brush" />
            <h1>
              <span className="secondary mb-3">Custom</span> web page
            </h1>
            <p>
              Embed our webpage in your own website to collect your superfans. Fully customizable to your brand’s style.
            </p>
          </Col>
          <Col xs={24} md={8}>
            <img className="mb-4" src="/img/heart-notification.svg" alt="heart" />
            <h1>
              <span className="secondary mb-3">Superfan</span> tool
            </h1>
            <p>
              Our Superfan tool is easy and intuitive. Collect, manage and activate your superfans, all in one environment.
            </p>
          </Col>
        </Row>
      </div>
      <div className="bg-gray-light">
        <div className="container">
          <Row gutter={[24, 16]}>
            <Col xs={24} lg={14}>
              <CompanySignupFields />
            </Col>
          </Row>
        </div>
      </div>
    </div>
  )
}
