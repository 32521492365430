import { Menu } from 'antd'
import React from 'react'
import { Link, useLocation } from 'react-router-dom'

export default function SuperAdminMenu({ setCollapseSider }) {
  const location = useLocation()

  const getActiveTab = () => {
    if (location.pathname.includes('/dashboard/super-admin/admins')) {
      return 1
    }

    if (location.pathname.includes('/dashboard/super-admin/linkedin')) {
      return 2
    }

    return 0
  }
  return (
    <Menu theme="light" mode="inline" defaultSelectedKeys={[getActiveTab().toString()]}>
      <Menu.Item key="0">
        <Link to="/dashboard/super-admin/brands" className="tab" onClick={() => setCollapseSider(true)}>
          <h2>
            Brands
          </h2>
        </Link>
      </Menu.Item>
      <Menu.Item key="1">
        <Link to="/dashboard/super-admin/admins" className="tab" onClick={() => setCollapseSider(true)}>
          <h2>
            Super Admins
          </h2>
        </Link>
      </Menu.Item>
      <Menu.Item key="2">
        <Link to="/dashboard/super-admin/linkedin" className="tab" onClick={() => setCollapseSider(true)}>
          <h2>
            LinkedIn
          </h2>
        </Link>
      </Menu.Item>
    </Menu>
  )
}
