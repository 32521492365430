import React, { useEffect, useState } from 'react'
import firebase from 'firebase'
import {
  Input, Table,
} from 'antd'

const { Column } = Table

export default function LinkedInReach() {
  const [users, setUsers] = useState([])
  const [reach, setReach] = useState({})

  const db = firebase.firestore()

  useEffect(() => {
    (
      async () => {
        const usersArray = []
        await db
          .collection('users')
          .get()
          .then(querySnapshot => {
            querySnapshot.forEach(doc => {
              const user = doc.data()
              if (user.socialAccounts?.linkedIn?.username && !user.socialAccounts?.linkedIn?.connections) {
                usersArray.push({
                  ...doc.data(),
                  id: doc.id,
                })
              }
            })
          })

        setUsers(usersArray)
      }
    )()
  }, [db])

  const getInstagramFollowers = (id) => {
    const user = users.find(u => u.id === id)
    return user.socialAccounts?.instagram?.followers || 0
  }

  const saveReach = (id, newReach) => {
    db
      .collection('users')
      .doc(id)
      .update({
        'socialAccounts.linkedIn.connections': newReach,
        reach: newReach + getInstagramFollowers(id),
      })
      .then(() => setReach({
        ...reach,
        [id]: {
          reach: newReach,
          saved: true,
        },
      }))
  }

  const tableData = users.map(user => ({
    ...user,
    key: user.id,
    name: (
      <a
        href={`https://www.linkedin.com/in/${user.socialAccounts.linkedIn.username}`}
        target="_blank"
        rel="noopener noreferrer"
      >
        <div className="d-flex align-center">
          <img
            className="profile-img round d-flex align-center mr-3"
            src={user.imageUrl}
            alt="profile"
          />
          <p className="m-0 bold regular">
            {user.firstName} {user.lastName}
          </p>
        </div>
      </a>
    ),
    reach: (
      <Input
        value={reach[user.id]?.reach || ''}
        type="number"
        onChange={e => setReach({
          ...reach,
          [user.id]: {
            reach: parseInt(e.target.value),
            saved: false,
          },
        })}
        onBlur={e => saveReach(user.id, parseInt(e.target.value))}
      />
    ),
    saved: reach[user.id]?.saved && <img src="/img/check.svg" alt="check" />,
  }))

  return (
    <div className="stories">
      <div className="dashboard-top" style={{ gridArea: 'dashboard-top' }}>
        <div className="title">
          <h1 className="bold m-0">
            LinkedIn users
          </h1>
        </div>
        <div className="separator" />
        <div className="table">
          <Table dataSource={tableData}>
            <Column title="Name" dataIndex="name" key="name" />
            <Column title="Connections" dataIndex="reach" key="reach" />
            <Column title="Saved" dataIndex="saved" key="saved" />
          </Table>
        </div>
      </div>
    </div>
  )
}
