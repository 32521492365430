import React, { useEffect, useState } from 'react'
import axios from 'axios'
import queryString from 'query-string'
import { Button, Spin } from 'antd'
import { useLocation, useHistory } from 'react-router-dom'

const brand = localStorage.getItem('brand')
localStorage.removeItem('brand')

export default function LinkedInHandler() {
  const location = useLocation()
  const history = useHistory()
  const params = queryString.parse(location.search)
  const { code } = params
  const [error, setError] = useState()

  useEffect(() => {

    if (code) {
      (async () => {
        try {
          const baseUrl = process.env.REACT_APP_FUNCTIONS_URL
          const accessTokenUrl = `${baseUrl}/getLinkedInAccessToken`
          const response = await axios.get(accessTokenUrl, { params: { code } })
          const accessToken = response.data.access_token

          const userUrl = `${baseUrl}/getLinkedInUser`
          const userResponse = await axios.get(userUrl, { params: { accessToken } })
          const userData = userResponse.data
          const imageUrl = userData.profilePicture['displayImage~'].elements[2].identifiers[0].identifier

          const base64Response = await axios.post(`${process.env.REACT_APP_FUNCTIONS_URL}/getImageBase64`, { imageUrl })
          const base64 = base64Response.data
          const preview = `data:image/png;base64, ${base64}`

          const emailUrl = `${baseUrl}/getLinkedInEmail`
          const emailResponse = await axios.get(emailUrl, { params: { accessToken } })
          const email = emailResponse.data.elements[0]['handle~'].emailAddress

          const user = {
            firstName: userData.localizedFirstName,
            lastName: userData.localizedLastName,
            email,
            image: {
              base64,
              url: preview,
              extension: '.jpg',
            },
            username: userData.vanityName,
          }

          if (brand) {
            history.push({
              pathname: `/${brand}/join/2`,
              state: {
                user,
                linkedInRedirect: true,
              },
            })
          } else {
            setError('Oh oh... Something went wrong, please contact info@superfan.world if issue persists')
          }

        } catch (e) {
          setError(e.message)
        }

      })()
    } else {
      setError('Couldn\'t connect LinkedIn account.')
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div className="full-screen d-flex align-center justify-center">
      <div className="d-flex align-center justify-center direction-column" style={{ width: '300px', marginTop: '-200px' }}>
        <img src="/img/logo.svg" alt="superfan-logo" style={{ width: '200px', marginBottom: '30px' }} />

        {error && (
          <>
            <p className="primary center">
              {error}
            </p>
            <Button
              type="primary"
              onClick={() => {
                history.push({
                  pathname: `/${brand}/join/2`,
                  state: {
                    linkedInRedirect: true,
                  },
                })
              }}
            >
              Return to application
            </Button>
          </>
        )}

        {(!error) && (
        <Spin size="large" />
        )}
      </div>
    </div>
  )
}
