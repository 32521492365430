import firebase from 'firebase'
import moment from 'moment'

export default async function saveStory(story, brandID, publish) {
  try {
    const db = firebase.firestore()
    const storage = firebase.storage()

    let doc
    if (story.id) {
      doc = await db.collection('stories').doc(story.id)
    } else {
      doc = await db.collection('stories').doc()
    }

    const images = story.images || []

    for (const i in story.imageFiles) {
      const file = story.imageFiles[i]
      const ref = await storage.ref().child(`stories/${doc.id}/${i}${file.extension}`)
      await ref.put(file)
      const imageUrl = await ref.getDownloadURL().then(url => url)

      images.push(imageUrl)
    }

    const upload = {
      ...story,
      images,
      brandID,
      id: doc.id,
    }

    delete upload.imageFiles

    if (publish) {
      if (story.duration === 'unlimited') {
        upload.expires = 0
      } else {
        upload.expires = moment().add(story.duration, 'hours').valueOf()
      }
      upload.created = Date.now()
      upload.views = []
    } else {
      upload.lastEdited = Date.now()
    }

    return doc
      .set(upload)
      .then(() => ({
        status: 'success',
        savedStory: upload,
      }))
  } catch (error) {
    return ({
      status: 'error',
      error,
    })
  }
}
