import React, { useEffect, useState } from 'react'
import firebase from 'firebase'
import {
  Row, Col, Table, Button, message, Modal, Input,
} from 'antd'
import { Link, useParams } from 'react-router-dom'

import getSuperfans from '../../api/getSuperfans'
import getStories from '../../api/getStories'
import getValue from '../../utils/getValue'
import removeAdminOf from '../../api/removeAdminOf'
import setAdminOf from '../../api/setAdminOf'
import Floatlabel from '../FloatLabel'

// Import SVG's
import { ReactComponent as ChevronLeft } from '../../icons/chevron-left.svg'
import { ReactComponent as User } from '../../icons/user.svg'
import { ReactComponent as Antenna } from '../../icons/antenna.svg'
import SaveBrand from './SaveBrand'
import validateEmail from '../../utils/validateEmail'

const { Column } = Table

export default function BrandDetail({ data }) {
  const [superfans, setSuperfans] = useState([])
  const [stories, setStories] = useState([])
  const [admins, setAdmins] = useState([])
  const [reload, setReload] = useState()
  const [loading, setLoading] = useState()
  const [modal, setModal] = useState()
  const [email, setEmail] = useState()
  const db = firebase.firestore()
  const { id } = useParams()
  const brand = data.brands.find(b => b.id === id)

  useEffect(() => {
    const loadData = async () => {
      setSuperfans(await getSuperfans(brand.id))
      setStories(await getStories(brand.id))
      if (brand.admins) {
        const adminsArray = []
        for (const uid of brand.admins) {
          const user = await db
            .collection('users')
            .doc(uid)
            .get()
            .then(doc => ({
              ...doc.data(),
              id: doc.id,
            }))

          adminsArray.push(user)
        }
        setAdmins(adminsArray)
      }
    }
    loadData()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reload])

  const reach = superfans.reduce((a, b) => a + (b.reach || 0), 0)
  const value = superfans.reduce((a, b) => a + (getValue(b.reach || 0)), 0)

  const tableData = admins.map(admin => ({
    ...admin,
    key: admin.id,
    name: `${admin.firstName || '-'} ${admin.lastName || ''}`,
    email: admin.email,
    button: (
      <div className="d-flex justify-end">
        <Button
          type="secondary"
          loading={loading === admin.id}
          onClick={async () => {
            setLoading(admin.id)
            const response = await removeAdminOf(admin.id, brand.id)
            if (response === true) {
              message.success(`${admin.firstName} succesfully removed as admin`)
              setReload(admin.id)
              setLoading()
            } else {
              message.error('Something went wrong')
              setLoading()
            }
          }}
        >
          Remove
        </Button>
      </div>
    ),
  }))

  return (
    <div className="story-detail">
      <Link to="/dashboard/super-admin/brands" className="d-inline-flex align-center regular relative z-1">
        <ChevronLeft className="mr-1" /> <span className="regular">Brands</span>
      </Link>

      <Row gutter={[24, 24]}>
        <Col xs={24} md={12} lg={8}>
          <Row>
            <Col xs={24}>
              <div className="content-box">
                <div className="name">
                  <div
                    className="logo-container d-flex align-center"
                    style={{ backgroundImage: `url(${brand.logo})` }}
                  />
                  <h1>
                    {brand.name}
                  </h1>
                </div>
              </div>
            </Col>
            <Col xs={24}>
              <div className="content-box">
                <h2>
                  Metrics
                </h2>
                <Row gutter={[24, 24]} className="analytics">
                  <Col xs={24} md={12}>
                    <div className="metric">
                      <p className="light small">
                        Superfans
                      </p>
                      <div className="d-flex align-center">
                        <User />
                        <h1 className="m-0">
                          {superfans.filter(superfan => superfan.status === 'accepted').length}
                        </h1>
                      </div>
                    </div>
                  </Col>
                  <Col xs={24} md={12}>
                    <div className="metric">
                      <p className="light small">
                        Stories
                      </p>
                      <div className="d-flex align-center">
                        <User />
                        <h1 className="m-0">
                          {stories.filter(story => story.expires).length}
                        </h1>
                      </div>
                    </div>
                  </Col>
                  <Col xs={24} md={12}>
                    <div className="metric">
                      <p className="light small">
                        Reach
                      </p>
                      <div className="d-flex align-center">
                        <Antenna />
                        <h1 className="m-0">
                          {reach.toLocaleString('nl')}
                        </h1>
                      </div>
                    </div>
                  </Col>
                  <Col xs={24} md={12}>
                    <div className="metric">
                      <p className="light small">
                        Est. value
                      </p>
                      <div className="d-flex align-center">
                        <h1 className="m-0 tertiary">
                          € {value}
                        </h1>
                      </div>
                    </div>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </Col>
        <Col xs={24} md={12} lg={16} className="admins-table">
          <div className="content-box">
            <div className="d-flex justify-between align-center mb-3">
              <h2 className="mb-0">
                Admins
              </h2>
              <Button
                type="primary"
                onClick={() => setModal(true)}
              >
                Add admin
              </Button>
            </div>
            <Table dataSource={tableData} showHeader={false}>
              <Column title="Name" dataIndex="name" key="name" />
              <Column title="Email" dataIndex="email" key="email" />
              <Column title="" dataIndex="button" key="button" />
            </Table>
          </div>
        </Col>
        <Col xs={24}>
          <div className="separator" />
        </Col>
      </Row>
      <Modal
        visible={modal}
        onCancel={() => setModal()}
        okButtonProps={{
          loading: loading === 'modal',
          disabled: !validateEmail(email),
        }}
        okText="Invite"
        onOk={async () => {
          setLoading('modal')
          const response = await setAdminOf(email, brand.id)
          if (response === true) {
            setModal()
            setReload(email)
            message.success(`${email} has been invited as an admin of ${brand.name} 😎`)
            setLoading()
          } else {
            message.error('Something went wrong')
            setLoading()
          }
        }}
      >
        <Floatlabel
          value={email}
          label="Email"
        >
          <Input
            value={email}
            onChange={e => setEmail(e.target.value)}
          />
        </Floatlabel>
      </Modal>
      <SaveBrand
        savedBrand={brand}
      />
    </div>
  )
}
