import React, { useEffect, useState } from 'react'
import firebase from 'firebase'
import {
  Switch, Route, useLocation, useHistory, Link,
} from 'react-router-dom'

import { ReactComponent as ArrowLeft } from '../icons/arrow-left.svg'
import SuperfanSignup1 from '../components/brand/SuperfanSignup1'
import SuperfanSignup2 from '../components/brand/SuperfanSignup2'
import SuperfanSignup3 from '../components/brand/SuperfanSignup3'
import SuperfanSignup4 from '../components/brand/SuperfanSignup4'
import SuperfanSignup5 from '../components/brand/SuperfanSignup5'
import SuperfanSignup6 from '../components/brand/SuperfanSignup6'
import SuperfanSignup7 from '../components/brand/SuperfanSignup7'
import SuperfanSignup8 from '../components/brand/SuperfanSignup8'
import SuperfanSignup9 from '../components/brand/SuperfanSignup9'

export default function SuperfanSignup({ brand }) {
  const location = useLocation()
  const [user, setUser] = useState({
    image: {},
    video: {},
    socialAccounts: {},
  })

  const history = useHistory()

  useEffect(() => {
    const signIn = async () => {
      let userData = { ...user }

      const signedInUser = firebase.auth().currentUser

      if (signedInUser) {
        const db = firebase.firestore()
        userData = await db
          .collection('users')
          .doc(signedInUser.uid)
          .get()
          .then(doc => {
            const u = doc.data()

            if (u) {
              return {
                ...userData,
                ...u,
                socialAccounts: {
                  ...userData.socialAccounts,
                  ...u.socialAccounts,
                },
                email: signedInUser.email,
                uid: signedInUser.uid,
                isValidEmail: true,
              }
            }

            return userData
          })
      }

      if (location.state?.linkedInRedirect) {
        const linkedInUser = location.state.user
        const instagram = JSON.parse(localStorage.getItem('instagram'))
        localStorage.removeItem('instagram')

        if (linkedInUser) {
          userData = {
            ...user,
            firstName: linkedInUser.firstName,
            lastName: linkedInUser.lastName,
            email: linkedInUser.email,
            ...signedInUser,
            image: linkedInUser.image,
            socialAccounts: {
              instagram: instagram || userData.socialAccounts.instagram || {},
              linkedIn: {
                username: linkedInUser.username,
              },
            },
            isStarted: true,
          }
        }
      }

      setUser(userData)
    }

    signIn()

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const { pathname, search } = useLocation()

  if (pathname.includes('join/8') && search.includes('email')) {
    return (
      <div className="superfan-signup floater">
        <div className="container">
          <SuperfanSignup8
            brand={brand}
          />
        </div>
      </div>
    )
  }

  if (user.superfanOf && user.superfanOf.includes(brand.id)) {
    return (
      <div className="superfan-signup floater">
        <div className="container">
          <SuperfanSignup9 brand={brand} />
        </div>
      </div>
    )
  }

  const getPreviousStep = () => {
    const currentStep = parseInt(pathname.slice(-1))

    if (Number.isNaN(currentStep) || currentStep === 1) {
      return ''
    }

    return `join/${currentStep - 1}`
  }

  const BackArrow = () => (
    <Link to={`/${brand.handle}/${getPreviousStep()}`}>
      <ArrowLeft className="regular" />
    </Link>
  )

  if (location.state?.linkedInRedirect) {
    return (
      <div className="superfan-signup floater">
        <div className="container">
          <BackArrow />
          <SuperfanSignup2
            brand={brand}
            user={user}
            setUser={setUser}
          />
        </div>
      </div>
    )
  }

  if (!user.isStarted) {
    setUser({ ...user, isStarted: true })
    history.push(`/${brand.handle}/join${location.search}`)
  }

  if (user.isCompleted) {
    if (pathname.includes('join/6') || pathname.includes('join/7')) {
      return (
        <div className="superfan-signup floater">
          <div className="container">
            <Switch>
              <Route path="/:handle/join/6">
                <SuperfanSignup6
                  brand={brand}
                />
              </Route>
              <Route path="/:handle/join/7">
                <SuperfanSignup7
                  user={user}
                />
              </Route>
            </Switch>
          </div>
        </div>
      )
    }

    return (
      <div className="superfan-signup floater">
        <div className="container">
          <SuperfanSignup9 brand={brand} />
        </div>
      </div>
    )
  }

  return (
    <div className="superfan-signup floater">
      <div className="container">
        <Switch>
          <Route path="/:handle/join/2">
            <>
              <BackArrow />
              <SuperfanSignup2
                brand={brand}
                user={user}
                setUser={setUser}
              />
            </>
          </Route>
          <Route path="/:handle/join/3">
            <>
              <BackArrow />
              <SuperfanSignup3
                brand={brand}
                user={user}
                setUser={setUser}
              />
            </>
          </Route>
          <Route path="/:handle/join/4">
            <>
              <BackArrow />
              <SuperfanSignup4
                brand={brand}
                user={user}
                setUser={setUser}
              />
            </>
          </Route>
          <Route path="/:handle/join/5">
            <>
              <BackArrow />
              <SuperfanSignup5
                brand={brand}
                user={user}
                setUser={setUser}
              />
            </>
          </Route>
          <Route path={['/:handle/join', '/:handle/join/1']}>
            <>
              <BackArrow />
              <SuperfanSignup1
                brand={brand}
                user={user}
                setUser={setUser}
              />
            </>
          </Route>
        </Switch>
      </div>
    </div>
  )
}
