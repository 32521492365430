import React, { useEffect, useState } from 'react'
import firebase from 'firebase'
import update from 'immutability-helper'
import { message } from 'antd'
import moment from 'moment'
import { Switch, Route } from 'react-router-dom'
import DashboardHome from '../components/dashboard/DashboardHome'
import getValue from '../utils/getValue'
import getSuperfans from '../api/getSuperfans'
import getStories from '../api/getStories'
import Account from '../components/dashboard/Account'
import Stories from '../components/dashboard/Stories'
import Story from './Story'
import Superfans from '../components/dashboard/Superfans'
import SuperfanDetail from '../components/dashboard/SuperfanDetail'
import Statistics from '../components/dashboard/Statistics'
import VideoMessage from '../components/dashboard/VideoMessage'
import SaveBrand from '../components/dashboard/SaveBrand'

export default function DashboardSwitch({ data, setData, path }) {
  const [preselectedSuperfans, setPreselectedSuperfans] = useState([])
  const [dateRange, setDateRange] = useState({
    amount: 6,
    unit: 'months',
    string: 'Past half year',
  })
  const db = firebase.firestore()
  const startDate = moment().subtract(dateRange.amount, dateRange.unit)

  const pendingSuperfans = data.brand.superfans.filter(s => s.status === 'pending')
  const acceptedSuperfans = data.brand.superfans.filter(s => s.status === 'accepted')

  const superfansOverTime = Array.from(Array(dateRange.amount), (x, i) => {
    const rangeStart = moment().subtract(dateRange.amount - i - 1, dateRange.unit).startOf('month')
    const rangeEnd = moment().subtract(dateRange.amount - i - 1, dateRange.unit).endOf('month')
    const superfansInRange = acceptedSuperfans.filter(s => s.created <= rangeEnd)
    const reach = superfansInRange.reduce((a, b) => a + (b.reach || 0), 0)
    return {
      date: rangeStart,
      superfans: superfansInRange.length,
      reach,
      value: superfansInRange.reduce((a, b) => a + (getValue(b.reach || 0)), 0),
    }
  })

  useEffect(() => {
    const unsubscribeApplications = db
      .collection('applications')
      .where('brandID', '==', data.brand.id)
      .onSnapshot(async snapshot => {
        snapshot.docChanges().forEach(change => {
          if (change.type === 'added') {
            const newApplication = change.doc.data()
            if (newApplication.created > moment().subtract(20, 'seconds').valueOf()) {
              message.info('A new superfan has applied 🥰')
            }
          }
        })
        const newSuperfans = await getSuperfans(data.brand.id)
        if (newSuperfans) setData(update(data, { brand: { superfans: { $set: newSuperfans.sort((a, b) => a.created - b.created) } } }))
      })

    const unsubscribeStories = db
      .collection('stories')
      .where('brandID', '==', data.brand.id)
      .onSnapshot(async () => {
        const newStories = await getStories(data.brand.id)
        if (newStories) setData(update(data, { brand: { stories: { $set: newStories.sort((a, b) => a.created - b.created) } } }))
      })

    return () => {
      unsubscribeApplications()
      unsubscribeStories()
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  return (
    <Switch>
      <Route exact path={path}>
        <DashboardHome
          data={data}
          dateRange={dateRange}
          setDateRange={setDateRange}
          startDate={startDate}
          acceptedSuperfans={acceptedSuperfans}
          pendingSuperfans={pendingSuperfans}
          superfansOverTime={superfansOverTime}
        />
      </Route>
      <Route path={`${path}/account`}>
        <Account data={data} setData={setData} />
      </Route>
      <Route exact path={`${path}/stories`}>
        <Stories data={data} />
      </Route>
      <Route path={[`${path}/stories/add/:step`, `${path}/stories/add`, `${path}/stories/:id/:step`, `${path}/stories/:id`]}>
        <Story
          data={data}
          setData={setData}
          acceptedSuperfans={acceptedSuperfans}
          preselectedSuperfans={preselectedSuperfans}
          setPreselectedSuperfans={setPreselectedSuperfans}
        />
      </Route>
      <Route exact path={`${path}/superfans`}>
        <Superfans data={data} setPreselectedSuperfans={setPreselectedSuperfans} />
      </Route>
      <Route exact path={`${path}/video-message`}>
        <VideoMessage
          data={data}
          preselectedSuperfans={preselectedSuperfans}
          acceptedSuperfans={acceptedSuperfans}
          setPreselectedSuperfans={setPreselectedSuperfans}
        />
      </Route>
      <Route path={`${path}/superfans/:id`}>
        <SuperfanDetail
          data={data}
          setData={setData}
          setPreselectedSuperfans={setPreselectedSuperfans}
        />
      </Route>
      <Route path={`${path}/statistics`}>
        <Statistics
          data={data}
          dateRange={dateRange}
          setDateRange={setDateRange}
          startDate={startDate}
          acceptedSuperfans={acceptedSuperfans}
          superfansOverTime={superfansOverTime}
        />
      </Route>
      <Route path={`${path}/landing-page`}>
        <SaveBrand
          savedBrand={data.brand}
        />
      </Route>
    </Switch>
  )
}
