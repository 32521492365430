import { Menu } from 'antd'
import React from 'react'
import { Link, useLocation } from 'react-router-dom'

export default function DashboardMenu({ data, setCollapseSider, path }) {
  const location = useLocation()
  const pendingCount = data.brand.superfans.filter(s => s.status === 'pending').length

  const getActiveTab = () => {
    if (location.pathname.includes(`${path}/stories`)) {
      return 1
    }

    if (location.pathname.includes(`${path}/superfans`)) {
      return 2
    }

    if (location.pathname.includes(`${path}/statistics`)) {
      return 3
    }

    if (location.pathname.includes(`${path}/landing-page`)) {
      return 4
    }

    return 0
  }
  return (
    <Menu theme="light" mode="inline" defaultSelectedKeys={[getActiveTab().toString()]}>
      <Menu.Item key="0">
        <Link to={path} className="tab" onClick={() => setCollapseSider(true)}>
          <h2>
            Dashboard
          </h2>
        </Link>
      </Menu.Item>
      <Menu.Item key="2">
        <Link to={`${path}/superfans`} className="tab" onClick={() => setCollapseSider(true)}>
          <h2>
            Superfans
          </h2>
          {pendingCount > 0 && (
          <div className="notification-count">
            {pendingCount}
          </div>
          )}
        </Link>
      </Menu.Item>
      <Menu.Item key="1">
        <Link to={`${path}/stories`} className="tab" onClick={() => setCollapseSider(true)}>
          <h2>
            Stories
          </h2>
        </Link>
      </Menu.Item>
      <Menu.Item key="3">
        <Link to={`${path}/statistics`} className="tab" onClick={() => setCollapseSider(true)}>
          <h2>
            Statistics
          </h2>
        </Link>
      </Menu.Item>
      <Menu.Item key="4">
        <Link to={`${path}/landing-page`} className="tab" onClick={() => setCollapseSider(true)}>
          <h2>
            Landing page
          </h2>
        </Link>
      </Menu.Item>
    </Menu>
  )
}
