/* eslint-disable react/jsx-props-no-spreading */
import React from 'react'
import { Link } from 'react-router-dom'
import {
  Col,
  Row,
} from 'antd'
import { ReactComponent as ChevronLeft } from '../../icons/chevron-left.svg'
import PersonalInformation from './PersonalInformation'

export default function Account({ data, setData, superAdmin }) {

  return (
    <div className="account">
      <Link to={superAdmin ? '/dashboard/super-admin' : `/dashboard/${data.brand.handle}`} className="d-inline-flex align-center regular">
        <ChevronLeft className="mr-1" /> <span className="regular">Dashboard</span>
      </Link>
      <h1>
        Account
      </h1>
      <div className="content-box">
        <Row>
          <Col xs={24} md={12} lg={8}>
            <PersonalInformation
              data={data}
              setData={setData}
            />
          </Col>
        </Row>
      </div>
    </div>
  )
}
