import React from 'react'
import { Link } from 'react-router-dom'
import {
  Button, Switch, Table,
} from 'antd'
import setBrandPassword from '../../api/setBrandPassword'

const { Column } = Table

export default function Brands({ data }) {
  const { brands } = data

  const tableData = brands.map(brand => ({
    ...brand,
    key: brand.id,
    name: (
      <Link to={`/dashboard/super-admin/brands/${brand.id}`}>
        <div className="d-flex align-center">
          <div
            className="logo-container d-flex align-center mr-3"
            style={{ backgroundImage: `url(${brand.logo})` }}
          />
          <p className="m-0 bold regular">
            {brand.name}
          </p>
        </div>
      </Link>
    ),
    password: (
      <Switch
        checked={brand.hasPassword}
        onChange={checked => setBrandPassword(brand.id, checked)}
      />
    ),
    // TODO: add superfans and stories
    button: (
      <Link to={`/dashboard/super-admin/brands/${brand.id}`} className="button-container">
        <Button type="secondary">
          Edit
        </Button>
      </Link>
    ),
  }))

  return (
    <div className="stories">
      <div className="dashboard-top" style={{ gridArea: 'dashboard-top' }}>
        <div className="title">
          <h1 className="bold m-0">
            Brands
          </h1>
          <Link to="/dashboard/super-admin/brands/add">
            <Button type="primary">
              Add brand
            </Button>
          </Link>
        </div>
        <div className="separator" />
        <div className="table">
          <Table dataSource={tableData}>
            <Column title="Name" dataIndex="name" key="name" />
            <Column title="Password" dataIndex="password" key="password" />
            <Column title="" dataIndex="button" key="button" />
          </Table>
        </div>
      </div>
    </div>
  )
}
