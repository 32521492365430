import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { Layout } from 'antd'

import useWindowDimensions from '../utils/useWindowDimensions'

import DashboardMenu from '../components/dashboard/menus/DashboardMenu'
import SuperAdminMenu from '../components/dashboard/menus/SuperAdminMenu'
import DashboardSwitch from './DashboardSwitch'
import SuperAdminSwitch from './SuperAdminSwitch'
import OnboardingModal from '../components/dashboard/OnboardingModal'
import UserDropdown from '../components/UserDropdown'

const {
  Header, Content, Sider,
} = Layout

export default function Dashboard({
  data, setData, setLoggedIn, superAdmin,
}) {
  const [collapseSider, setCollapseSider] = useState(true)
  const [onboarding, setOnboarding] = useState(!data.user.acceptedTerms)

  const { width } = useWindowDimensions()
  const path = superAdmin ? '/dashboard/super-admin' : `/dashboard/${data.brand.handle}`

  return (
    <div className="dashboard">
      <Layout>
        <Sider
          className="side-panel"
          theme="light"
          breakpoint="lg"
          collapsedWidth="0"
          collapsed={width < 992 ? collapseSider : false}
          onCollapse={() => setCollapseSider(!collapseSider)}
        >
          <Link to="/" className="d-block">
            <img className="logo" src="/img/logo.svg" alt="superfan-logo" />
          </Link>
          {data.brands && (
          <Link to="/dashboard" className="d-inline-block padding-left">
            <div onClick={() => setLoggedIn('switcher')} className="link">
              Switch brand
            </div>
          </Link>
          )}
          {superAdmin ? (
            <SuperAdminMenu setCollapseSider={setCollapseSider} />
          ) : (
            <DashboardMenu
              data={data}
              setCollapseSider={setCollapseSider}
              path={path}
            />
          )}
        </Sider>

        <Layout className="main">
          <Header
            className="site-layout-sub-header-background"
            style={{ padding: 0 }}
          >
            <div className="top" style={{ gridArea: 'top' }}>
              <div className="d-flex align-center">
                <Link to={path}>
                  <img className="brand-logo" src={superAdmin ? '/img/logo.svg' : data.brand.logo} alt="brand-logo" />
                </Link>
              </div>

              <UserDropdown
                user={data.user}
                path={path}
                showAccount
              />
            </div>
          </Header>
          <Content className="main-content component">
            {superAdmin ? (
              <SuperAdminSwitch
                data={data}
                setData={setData}
              />
            ) : (
              <DashboardSwitch
                data={data}
                setData={setData}
                path={path}
              />
            )}
          </Content>
        </Layout>
      </Layout>
      {onboarding && !superAdmin && (
      <OnboardingModal
        visible={onboarding}
        setVisible={setOnboarding}
        data={data}
        setData={setData}
      />
      )}
    </div>
  )
}
