import React from 'react'
import { ResponsiveBar } from '@nivo/bar'

import colors from '../../../styles/colors.scss'
import getStoryValue from '../../../utils/getStoryValue'

export default function Value({ superfansOverTime, stories }) {
  const predictedValue = superfansOverTime[superfansOverTime.length - 1].value

  let data
  let mostRecentStory
  if (stories) {
    const activeAndCompletedStories = stories.filter(story => story.expires)
    data = activeAndCompletedStories.map((story, i) => ({
      story: (i + 1).toString(),
      value: getStoryValue(story.views),
    }))
    mostRecentStory = stories.length
  } else {
    data = []
    mostRecentStory = 0
  }

  data.push({
    story: (mostRecentStory + 1).toString(),
    predicted: predictedValue,
  })

  const getChartColor = id => {
    switch (id) {
      case 'value':
        return colors.tertiary
      case 'predicted':
        return colors.tertiaryFaded
      default:
        return colors.tertiary
    }
  }

  return (
    <div className="value component" style={{ gridArea: 'value' }}>
      <h2 className="m-0">
        Marketing value
      </h2>
      <p className="small light m-0">
        Total
      </p>
      <p className="large bold font-secondary tertiary m-0">
        {data.reduce((a, b) => a + (b.value || 0), 0).toLocaleString('nl-NL', {
          style: 'currency', currency: 'EUR', minimumFractionDigits: 0, maximumFractionDigits: 0,
        })}
      </p>
      <div className="chart">
        <ResponsiveBar
          data={data}
          keys={['value', 'predicted']}
          indexBy="story"
          margin={{
            top: 10, right: 20, bottom: 30, left: 50,
          }}
          padding={0.5}
          enableLabel={false}
          axisTop={null}
          axisRight={null}
          axisBottom={{
            orient: 'bottom',
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
          }}
          axisLeft={{
            orient: 'left',
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            format: v => v.toLocaleString('nl-NL', {
              style: 'currency', currency: 'EUR', minimumFractionDigits: 0, maximumFractionDigits: 0,
            }),
          }}
          tooltipFormat={v => v.toLocaleString('nl-NL', {
            style: 'currency', currency: 'EUR', minimumFractionDigits: 0, maximumFractionDigits: 0,
          })}
          colors={serie => getChartColor(serie.id)}
          enablePoints={false}
          enableArea
          areaOpacity={0.1}
          useMesh
        />
      </div>
    </div>
  )
}
