import {
  Button, Col, Input, message, Row,
} from 'antd'
import Modal from 'antd/lib/modal/Modal'
import React, { useState } from 'react'
import update from 'react-addons-update'
import createContact from '../emails/createContact'
import sendRequestDemoEmail from '../emails/sendRequestDemoEmail'
import validateEmail from '../utils/validateEmail'
import FloatLabel from './FloatLabel'

export default function RequestDemoModal({ visible, setVisible }) {
  const [user, setUser] = useState({})
  const [isEmailSent, setIsEmailSent] = useState()
  const [loading, setLoading] = useState()

  const confirmHeader = (
    <>
      <h1 className="title">
        <span className="secondary">Sent!</span> Sit back and relax.
      </h1>
      <p className="m-0">
        Thanks for leaving your details. We’ll get in touch with your shortly about your demo.
      </p>
    </>
  )

  const signupHeader = (
    <>
      <h1 className="title">
        Request a <span className="secondary">demo</span>
      </h1>
      <p className="m-0">
        We will create a brand page for you and walk you through the dashboard, no strings attached
      </p>
    </>
  )

  return (
    <Modal
      className="modal-hide-footer"
      visible={visible !== undefined}
      onCancel={() => setVisible()}
      width={620}
    >
      <Row gutter={[24, 24]} className="padding">
        <Col xs={24}>
          {isEmailSent === true ? confirmHeader : signupHeader }
        </Col>
        <Col xs={24}>
          <Row gutter={[24, 16]}>
            {!isEmailSent && (
            <>
              <Col xs={24} md={12}>
                <FloatLabel
                  label="First Name"
                  value={user.firstName}
                >
                  <Input
                    value={user.firstName}
                    name="first-name-request-demo"
                    onChange={e => setUser(update(user, { firstName: { $set: e.target.value } }))}
                  />
                </FloatLabel>
              </Col>
              <Col xs={24} md={12}>
                <FloatLabel
                  label="Last Name"
                  value={user.lastName}
                >
                  <Input
                    value={user.lastName}
                    name="last-name-request-demo"
                    onChange={e => setUser(update(user, { lastName: { $set: e.target.value } }))}
                  />
                </FloatLabel>
              </Col>
              <Col xs={24} className="p-0" />
              <Col xs={24} md={12}>
                <FloatLabel
                  label="Company"
                  value={user.company}
                >
                  <Input
                    value={user.company}
                    name="company-request-demo"
                    onChange={e => setUser(update(user, { company: { $set: e.target.value } }))}
                  />
                </FloatLabel>
              </Col>
              <Col xs={24} md={12}>
                <FloatLabel
                  label="Email address"
                  value={user.email}
                >
                  <Input
                    value={user.email}
                    name="email-request-demo"
                    onChange={e => setUser(update(user, { email: { $set: e.target.value } }))}
                    onBlur={e => setUser(update(user, { isValidEmail: { $set: validateEmail(e.target.value) } }))}
                  />
                </FloatLabel>
                {user.isValidEmail === false && (
                <p className="primary">
                  Please use valid email address
                </p>
                )}
              </Col>
              <Col xs={24}>
                <Button
                  type="primary"
                  loading={loading}
                  disabled={!user.firstName || !user.lastName || !user.company || !user.email}
                  onClick={async () => {
                    setLoading(true)
                    const emailData = {
                      email: user.email,
                      firstName: user.firstName,
                      lastName: user.lastName,
                      company: user.company,
                    }
                    const emailResponse = await sendRequestDemoEmail(emailData)
                    await createContact(emailData)
                    if (emailResponse === true) {
                      setIsEmailSent(true)
                      setLoading()
                    } else {
                      message.error('Something went wrong sending your form')
                      setLoading()
                    }
                  }}
                >
                  Send
                </Button>
              </Col>
            </>
            )}
          </Row>
        </Col>
      </Row>
    </Modal>
  )
}
