import React from 'react'
import update from 'react-addons-update'
import getLinkedInAuthCode from '../../api/getLinkedInAuthCode'

export default function ConnectLinkedIn({
  user,
  setUser,
  brand,
}) {

  const isLinkedInConnected = user.socialAccounts.linkedIn?.username !== undefined

  const handleClick = async () => {
    localStorage.setItem('brand', brand.handle)
    if (user.socialAccounts.instagram?.username) {
      localStorage.setItem('instagram', JSON.stringify(user.socialAccounts.instagram))
    }
    getLinkedInAuthCode()
  }

  return (
    <div
      className={`social-account mb-3 ${isLinkedInConnected && 'connected'}`}
      onClick={isLinkedInConnected ? () => setUser(update(user, { socialAccounts: { linkedIn: { $set: {} } } })) : handleClick}
    >
      <div className="left">
        <img src="/img/social/linkedin.png" alt="linkedin" />
        {isLinkedInConnected ? `${user.firstName} ${user.lastName}` : 'LinkedIn'}
      </div>
      <div className="right mr-2">
        {isLinkedInConnected ? 'Remove' : 'Connect'}
      </div>
    </div>
  )
}
