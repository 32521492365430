import React from 'react'
import { Link, useHistory } from 'react-router-dom'
import { Dropdown, Menu } from 'antd'
import logout from '../api/logout'
import { ReactComponent as ChevronDown } from '../icons/chevron-down.svg'
import { ReactComponent as Profile } from '../icons/profile.svg'

export default function UserDropdown({ user, path, showAccount }) {
  const history = useHistory()

  const userMenu = (
    <Menu>
      {showAccount && (
        <Menu.Item>
          <Link to={`${path}/account`}>
            Account
          </Link>
        </Menu.Item>
      )}
      <Menu.Item
        onClick={async () => {
          const loggedOut = await logout()
          if (loggedOut) history.push('/')
        }}
      >
        Logout
      </Menu.Item>
    </Menu>
  )
  return (
    <Dropdown overlay={userMenu}>
      <div className="user-dropdown">
        {user.imageUrl ? (
          <img className="profile-image" src={user.imageUrl} alt="user-profile" />
        ) : (
          <Profile className="light profile-image" />
        )}
        <p className="mb-0 large">
          {`${user.firstName || ''} ${user.lastName || ''}`}
        </p>
        <ChevronDown />
      </div>
    </Dropdown>
  )
}
