import React, { useEffect } from 'react'
import firebase from 'firebase'
import { Switch, Route } from 'react-router-dom'
import getAllBrands from '../api/getAllBrands'
import Brands from '../components/dashboard/Brands'
import SaveBrand from '../components/dashboard/SaveBrand'
import BrandDetail from '../components/dashboard/BrandDetail'
import SuperAdmins from '../components/dashboard/SuperAdmins'
import Account from '../components/dashboard/Account'
import LinkedInReach from '../components/dashboard/LinkedInReach'

export default function SuperAdminSwitch({ data, setData }) {
  const db = firebase.firestore()
  useEffect(() => {
    const unsubscribe = db
      .collection('brands')
      .onSnapshot(async () => {
        const brands = await getAllBrands()
        setData({
          ...data,
          brands,
        })
      })
    return () => {
      unsubscribe()
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  return (
    <Switch>
      <Route path="/dashboard/super-admin/admins">
        <SuperAdmins data={data} />
      </Route>
      <Route path="/dashboard/super-admin/account">
        <Account superAdmin data={data} setData={setData} />
      </Route>
      <Route path="/dashboard/super-admin/linkedin">
        <LinkedInReach data={data} />
      </Route>
      <Route path="/dashboard/super-admin/brands/add">
        <SaveBrand data={data} />
      </Route>
      <Route path="/dashboard/super-admin/brands/:id">
        <BrandDetail data={data} />
      </Route>
      <Route>
        <Brands data={data} />
      </Route>
    </Switch>
  )
}
