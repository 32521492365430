import React from 'react'
import { useParams } from 'react-router-dom'
import StoryEditor from './StoryEditor'
import StoryDetail from '../components/dashboard/StoryDetail'

export default function Story({
  data, acceptedSuperfans, setData, preselectedSuperfans, setPreselectedSuperfans,
}) {
  const { id, step } = useParams()
  const { stories } = data.brand
  const story = stories.find(s => s.id === id)

  if (!story || (story.expires === undefined)) {
    return (
      <StoryEditor
        data={data}
        acceptedSuperfans={acceptedSuperfans}
        savedStory={story}
        step={step}
        setData={setData}
        preselectedSuperfans={preselectedSuperfans}
        setPreselectedSuperfans={setPreselectedSuperfans}
      />
    )
  }

  return <StoryDetail data={data} story={story} />
}
