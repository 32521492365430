/* eslint-disable react/jsx-props-no-spreading */
import React, { useState } from 'react'
import update from 'immutability-helper'
import {
  Button,
  Col, Dropdown, Input, message, Row,
} from 'antd'
import { DownOutlined } from '@ant-design/icons'
import TextArea from 'antd/lib/input/TextArea'
import { ChromePicker } from 'react-color'

import { useHistory } from 'react-router-dom'
import FloatLabel from '../FloatLabel'
import ImageUploader from '../ImageUploader'
import SocialChannelMenu from './menus/SocialChannelMenu'
import BrandHome from '../brand/BrandHome'
import saveBrand from '../../api/saveBrand'
import defaultBrandCopy from '../../lib/defaultBrandCopy'
import getSuperfanPrefix from '../../utils/getSuperfanPrefix'

export default function SaveBrand({ savedBrand }) {
  const [colorPicker, setColorPicker] = useState()
  const [loading, setLoading] = useState()
  const [hover, setHover] = useState()
  const [brand, setBrand] = useState((savedBrand && {
    ...savedBrand,
    imageFiles: {},
  }) || {
    colors: {
      primary: '#ff6056',
      secondary: '#3632f5',
      text: '#ffffff',
    },
    imageFiles: {},
    screenshots: [
      { channel: 'instagram' },
      { channel: 'instagram' },
      { channel: 'instagram' },
      { channel: 'instagram' },
    ],
  })

  const history = useHistory()
  const url = `${process.env.REACT_APP_DOMAIN}/${brand.handle || '{handle}'}`

  const handleUpload = (imageFile, id) => {
    setBrand(update(brand, {
      imageFiles: {
        [id]: {
          $set: imageFile,
        },
      },
    }))
  }

  const SocialPreview = ({ id }) => (
    <>
      <Dropdown
        overlay={<SocialChannelMenu brand={brand} setBrand={setBrand} id={id} />}
        className="mb-3"
      >
        <Button>
          {brand.screenshots[id].channel}
          <DownOutlined />
        </Button>
      </Dropdown>
      <ImageUploader
        imageFile={brand.imageFiles[`socialPreview${id}`]}
        id={`socialPreview${id}`}
        handleUpload={handleUpload}
        height="300px"
        contain
      />
    </>
  )

  return (
    <div className="super-admin">
      <Row gutter={[24, 24]}>
        <Col xs={24} md={12} lg={8}>
          <div className="component">
            <h1>
              {brand.id ? 'Edit brand' : 'Publish new brand'}
            </h1>
            <Row gutter={[24, 24]}>
              <Col xs={24}>
                <h2 className="m-0">
                  General
                </h2>
              </Col>
              <Col xs={24} md={12}>
                <FloatLabel
                  className="mt-0"
                  label="Name"
                  value={brand.name}
                >
                  <Input
                    value={brand.name}
                    onChange={e => setBrand({ ...brand, name: e.target.value })}
                  />
                </FloatLabel>
              </Col>
              <Col xs={24} md={12}>
                <FloatLabel
                  className="mt-0"
                  label="Handle"
                  value={brand.handle}
                >
                  <Input
                    value={brand.handle}
                    onChange={e => setBrand({ ...brand, handle: e.target.value })}
                  />
                </FloatLabel>
              </Col>
              <Col xs={24}>
                <FloatLabel
                  className="mt-0"
                  label="Default reply to email"
                  value={brand.defaultReplyTo}
                >
                  <Input
                    value={brand.defaultReplyTo}
                    onChange={e => setBrand({ ...brand, defaultReplyTo: e.target.value })}
                  />
                </FloatLabel>
              </Col>
              <Col xs={24}>
                <FloatLabel
                  className="mt-0"
                  label="Custom word for superfan"
                  value={brand.customSuperfan}
                >
                  <Input
                    value={brand.customSuperfan}
                    onChange={e => setBrand({ ...brand, customSuperfan: e.target.value })}
                  />
                </FloatLabel>
                This will replace the word &apos;superfan&apos; across all web pages and e-mails
              </Col>
              <Col xs={24}>
                <h2 className="mt-3 m-0">
                  Design
                </h2>
              </Col>
              <Col xs={24}>
                <span className="bold">
                  Logo
                </span>
                <ImageUploader
                  imageFile={brand.imageFiles.logo}
                  id="logo"
                  handleUpload={handleUpload}
                  height="100px"
                  contain
                />
              </Col>
              <Col xs={24} md={12}>
                <span className="bold">
                  Primary color
                </span>
                <Input
                  value={brand.colors.primary}
                  onChange={e => setBrand(update(brand, { colors: { primary: { $set: e.target.value } } }))}
                  prefix={(
                    <div
                      className="color-thumbnail pointer"
                      style={{ backgroundColor: brand.colors.primary }}
                      onClick={() => (colorPicker === 'primary' ? setColorPicker() : setColorPicker('primary'))}
                    />
                  )}
                />
                {colorPicker === 'primary' && (
                  <ChromePicker
                    className="mt-3"
                    color={brand.colors.primary}
                    onChange={color => setBrand(update(brand, { colors: { primary: { $set: color.hex } } }))}
                  />
                )}
              </Col>
              <Col xs={24} md={12}>
                <span className="bold">
                  Secondary color
                </span>
                <Input
                  value={brand.colors.secondary}
                  onChange={e => setBrand(update(brand, { colors: { secondary: { $set: e.target.value } } }))}
                  prefix={(
                    <div
                      className="color-thumbnail pointer"
                      style={{ backgroundColor: brand.colors.secondary }}
                      onClick={() => (colorPicker === 'secondary' ? setColorPicker() : setColorPicker('secondary'))}
                    />
                  )}
                />
                {colorPicker === 'secondary' && (
                  <ChromePicker
                    className="mt-3"
                    color={brand.colors.secondary}
                    onChange={color => setBrand(update(brand, { colors: { secondary: { $set: color.hex } } }))}
                  />
                )}
              </Col>
              <Col xs={24}>
                <FloatLabel
                  className="mt-0"
                  label="Header CTA"
                  value={brand.headline?.CTA || 'default'}
                >
                  <Input
                    value={brand.headline?.CTA || `Become a${getSuperfanPrefix(brand.customSuperfan)} ${brand.customSuperfan || 'superfan'} of ${brand.name || '[your brand]'}`}
                    onChange={e => setBrand({ ...brand, headline: { ...brand.headline, CTA: e.target.value } })}
                    onMouseEnter={() => setHover('headlineCTA')}
                    onMouseLeave={() => setHover()}
                  />
                </FloatLabel>
              </Col>
              <Col xs={24}>
                <FloatLabel
                  className="mt-0"
                  label="Header title"
                  value={brand.headline?.title}
                >
                  <Input
                    value={brand.headline?.title}
                    onChange={e => setBrand({ ...brand, headline: { ...brand.headline, title: e.target.value } })}
                    onMouseEnter={() => setHover('headlineTitle')}
                    onMouseLeave={() => setHover()}
                  />
                </FloatLabel>
              </Col>
              <Col xs={24}>
                <p className="bold mb-0 gray-5">
                  Header paragraph
                </p>
                <TextArea
                  autoSize={{ minRows: 3, maxRows: 5 }}
                  placeholder="Header paragraph"
                  value={brand.headline?.paragraph}
                  onChange={e => setBrand({ ...brand, headline: { ...brand.headline, paragraph: e.target.value } })}
                  onMouseEnter={() => setHover('headlineParagraph')}
                  onMouseLeave={() => setHover()}
                />
              </Col>
              <Col xs={24}>
                <FloatLabel
                  className="mt-0"
                  label="Button text"
                  value={brand.headline?.button || 'default'}
                >
                  <Input
                    value={brand.headline?.button || defaultBrandCopy.headlineButton}
                    onChange={e => setBrand({ ...brand, headline: { ...brand.headline, button: e.target.value } })}
                    onMouseEnter={() => setHover('headlineButton')}
                    onMouseLeave={() => setHover()}
                  />
                </FloatLabel>
              </Col>
              <Col xs={24}>
                <span className="bold">
                  Header image (1000x1000px)
                </span>
                <ImageUploader
                  imageFile={brand.imageFiles.header}
                  id="header"
                  handleUpload={handleUpload}
                  height="300px"
                />
              </Col>
              <Col xs={24}>
                <FloatLabel
                  className="mt-0"
                  label="Custom qualification 1"
                  value={brand.qualifications?.a || 'default'}
                >
                  <Input
                    value={brand.qualifications?.a || defaultBrandCopy.qualificationA}
                    onChange={e => setBrand({ ...brand, qualifications: { ...brand.qualifications, a: e.target.value } })}
                    onMouseEnter={() => setHover('qualificationA')}
                    onMouseLeave={() => setHover()}
                  />
                </FloatLabel>
              </Col>
              <Col xs={24}>
                <FloatLabel
                  className="mt-0"
                  label="Custom qualification 2"
                  value={brand.qualifications?.b || 'default'}
                >
                  <Input
                    value={brand.qualifications?.b || defaultBrandCopy.qualificationB}
                    onChange={e => setBrand({ ...brand, qualifications: { ...brand.qualifications, b: e.target.value } })}
                    onMouseEnter={() => setHover('qualificationB')}
                    onMouseLeave={() => setHover()}
                  />
                </FloatLabel>
              </Col>
              <Col>
                <span className="bold">
                  Text color (on colored backgrounds)
                </span>
                <Input
                  value={brand.colors.text || '#ffffff'}
                  onChange={e => setBrand(update(brand, { colors: { text: { $set: e.target.value } } }))}
                  prefix={(
                    <div
                      className="color-thumbnail pointer"
                      style={{ backgroundColor: brand.colors.text }}
                      onClick={() => (colorPicker === 'text' ? setColorPicker() : setColorPicker('text'))}
                    />
                  )}
                />
                {colorPicker === 'text' && (
                  <ChromePicker
                    className="mt-3"
                    color={brand.colors.text || '#ffffff'}
                    onChange={color => setBrand(update(brand, { colors: { text: { $set: color.hex } } }))}
                  />
                )}
              </Col>
              <Col xs={24}>
                <h2 className="mt-3 m-0">
                  Social images
                </h2>
              </Col>
              <Col xs={24} md={12}>
                <SocialPreview id={0} />
              </Col>
              <Col xs={24} md={12}>
                <SocialPreview id={2} />
              </Col>
              <Col xs={24} md={12}>
                <SocialPreview id={1} />
              </Col>
              <Col xs={24} md={12}>
                <SocialPreview id={3} />
              </Col>
              <Col xs={24}>
                <h2 className="mt-3 m-0">
                  Representative
                </h2>
              </Col>
              <Col xs={24}>
                <FloatLabel
                  className="mt-0"
                  label="Custom quote"
                  value={brand.representative?.quote || 'default'}
                >
                  <Input
                    value={brand.representative?.quote || defaultBrandCopy.representativeQuote}
                    onChange={e => setBrand({ ...brand, representative: { ...brand.representative, quote: e.target.value } })}
                    onMouseEnter={() => setHover('representativeQuote')}
                    onMouseLeave={() => setHover()}
                  />
                </FloatLabel>
              </Col>
              <Col xs={24}>
                <span className="bold">
                  Profile image (100x100px)
                </span>
                <ImageUploader
                  imageFile={brand.imageFiles.representative}
                  id="representative"
                  handleUpload={handleUpload}
                  height="100px"
                  contain
                />
              </Col>
              <Col xs={24} md={12}>
                <FloatLabel
                  className="mt-0"
                  label="Name"
                  value={brand.representative?.name}
                >
                  <Input
                    value={brand.representative?.name}
                    onChange={e => setBrand({ ...brand, representative: { ...brand.representative, name: e.target.value } })}
                    onMouseEnter={() => setHover('representativeName')}
                    onMouseLeave={() => setHover()}
                  />
                </FloatLabel>
              </Col>
              <Col xs={24} md={12}>
                <FloatLabel
                  className="mt-0"
                  label="Title"
                  value={brand.representative?.title}
                >
                  <Input
                    value={brand.representative?.title}
                    onChange={e => setBrand({ ...brand, representative: { ...brand.representative, title: e.target.value } })}
                    onMouseEnter={() => setHover('representativeTitle')}
                    onMouseLeave={() => setHover()}
                  />
                </FloatLabel>
              </Col>
              <Col xs={24}>
                <span className="bold">
                  Email header image (750x280px)
                </span>
                <ImageUploader
                  imageFile={brand.imageFiles.emailHeader}
                  id="emailHeader"
                  handleUpload={handleUpload}
                  height="200px"
                />
              </Col>
              <Col xs={24}>
                <span className="bold">
                  Text for Superfan Accepted email
                </span>
                <Input.TextArea
                  value={brand.emails?.accepted || defaultBrandCopy.acceptedEmail}
                  onChange={e => setBrand({ ...brand, emails: { ...brand.emails, accepted: e.target.value } })}
                />
              </Col>
              <Col xs={24}>
                <Button
                  type="primary"
                  loading={loading}
                  className="mt-3"
                  onClick={async () => {
                    setLoading(true)
                    const response = await saveBrand(brand)
                    if (response === true) {
                      if (!brand.id) history.push('/dashboard/super-admin/brands')
                      message.success(`${brand.name} published 😻`)
                      setLoading()
                    } else {
                      message.error('Oh, oh.. Something went wrong')
                      console.error(response)
                      setLoading()
                    }
                  }}
                >
                  Publish
                </Button>
              </Col>
            </Row>
          </div>
        </Col>
        <Col xs={24} md={12} lg={16}>
          <p>
            Page lives on <a href={url} target="_blank" rel="noopener noreferrer"><span className="bold">{url}</span></a>
          </p>
          <div className="brand component">
            <div className="d-flex justify-center mt-3">
              <img src={brand.logo || (brand.imageFiles.logo && brand.imageFiles.logo.preview) || '/img/logo.svg'} style={{ height: '50px' }} alt="logo" />
            </div>
            <BrandHome brand={brand} hover={hover} />
          </div>
        </Col>
      </Row>
    </div>
  )
}
