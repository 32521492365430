import React, { useState } from 'react'
import {
  Row, Col, Input, Button,
} from 'antd'
import FloatLabel from './FloatLabel'

export default function Password() {
  const [password, setPassword] = useState()
  const [error, setError] = useState()

  const handleSubmit = () => {
    if (password === process.env.REACT_APP_PASSWORD) {
      localStorage.setItem('password', password)
      window.location.reload()
    } else {
      setError('Sorry, wrong password')
    }
  }

  return (
    <div className="full-screen d-flex align-center justify-center">
      <div className="d-flex align-center justify-center direction-column" style={{ width: '300px', marginTop: '-200px' }}>
        <img src="/img/logo.svg" alt="superfan-logo" style={{ width: '200px', marginBottom: '30px' }} />
        <Row gutter={[12, 16]}>
          <Col xs={24}>
            <FloatLabel
              label="Password"
              value={password}
              className="mt-4"
            >
              <Input
                name="password"
                type="password"
                value={password}
                onChange={e => setPassword(e.target.value)}
                onPressEnter={handleSubmit}
              />
            </FloatLabel>
          </Col>
          <Col xs={24}>
            <Button
              type="primary"
              className="w-100"
              onClick={handleSubmit}
            >
              Continue
            </Button>
          </Col>

          {error && (
          <p className="primary center">
            {error}
          </p>
          )}
        </Row>
      </div>
    </div>

  )
}
