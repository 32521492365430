import axios from 'axios'

export default function authenticate(email, continuePath) {
  const url = `${process.env.REACT_APP_FUNCTIONS_URL}/sendSignInEmail`
  const data = { email, continuePath }
  const headers = { accept: 'application/json' }

  return axios
    .post(url, data, { headers })
    .then(() => {
      window.localStorage.setItem('emailForSignIn', email)
      return 'success'
    })
    .catch(error => error)
}
