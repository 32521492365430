import React, { useState } from 'react'
import firebase from 'firebase'
import { useHistory } from 'react-router-dom'

import { Spin } from 'antd'
import { ReactComponent as Illustration } from '../../icons/illustration-1.svg'
import loadBrand from '../../api/loadBrand'

export default function AdminSwitcher({ data, setData, setLoggedIn }) {
  const [loading, setLoading] = useState()
  const db = firebase.firestore()
  const history = useHistory()

  return (
    <div className="login">
      <div className="left" style={{ gridArea: 'left' }}>
        <Illustration />
      </div>
      <div className="right">
        <div className="login-box">
          <p>
            Hello {data.user.firstName},
            <br />
            you&apos;re an admin of multiple brands
          </p>
          <h1>
            Choose a dashboard
          </h1>
          <div className="scrollbox vertical mt-3 brand-list padding pb-0">
            {data.user.superAdmin && (
              <div className="mb-3">
                {loading === 'superAdmin' ? (
                  <Spin className="ml-3" />
                ) : (
                  <div
                    className="d-flex align-center pointer brand-item"
                    onClick={async () => {
                      setLoggedIn('superAdmin')
                      history.push('/dashboard/super-admin')
                    }}
                  >
                    <div
                      className="logo-container d-flex align-center mr-3"
                      style={{ backgroundImage: 'url(/img/logo.svg)' }}
                    />
                    <p className="m-0 bold">
                      Super Admin
                    </p>
                  </div>
                )}
                <div className="separator mt-3" />
              </div>
            )}
            {data.brands.map((brand, i) => (
              <div key={brand.id} className="mb-3">
                {loading === i ? (
                  <Spin className="ml-3" />
                ) : (
                  <div
                    className="d-flex align-center pointer brand-item"
                    onClick={async () => {
                      setLoading(i)
                      const brandID = brand.id
                      const brandData = await db
                        .collection('brands')
                        .doc(brandID)
                        .get()
                        .then(doc => ({
                          id: doc.id,
                          ...doc.data(),
                        }))
                        .catch(() => {
                          setLoggedIn('unknown')
                        })

                      const b = await loadBrand(brandData)
                      setData({
                        ...data,
                        brand: b,
                      })

                      setLoggedIn(true)
                      setLoading()
                      history.push(`/dashboard/${brand.handle}`)
                    }}
                  >
                    <div
                      className="logo-container d-flex align-center mr-3"
                      style={{ backgroundImage: `url(${brand.logo})` }}
                    />
                    <p className="m-0 bold">
                      {brand.name}
                    </p>
                  </div>
                )}
                {i !== (data.brands.length - 1) && (
                  <div className="separator mt-3" />
                )}
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}
