import React from 'react'
import update from 'react-addons-update'
import { Menu } from 'antd'

const SocialChannelMenu = ({ id, brand, setBrand }) => (
  <Menu>
    <Menu.Item
      onClick={() => setBrand(update(brand, {
        stories: {
          [id]: { channel: { $set: 'instagram' } },
        },
      }))}
    >
      instagram
    </Menu.Item>
    <Menu.Item
      onClick={() => setBrand(update(brand, {
        stories: {
          [id]: { channel: { $set: 'linkedin' } },
        },
      }))}
    >
      linkedin
    </Menu.Item>
    <Menu.Item
      onClick={() => setBrand(update(brand, {
        stories: {
          [id]: { channel: { $set: 'tiktok' } },
        },
      }))}
    >
      tiktok
    </Menu.Item>
  </Menu>
)

export default SocialChannelMenu
