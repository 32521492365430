import React from 'react'
import { Menu } from 'antd'

export default function DateRangeMenu({ setDateRange }) {
  return (
    <Menu>
      <Menu.Item
        onClick={() => setDateRange({
          amount: 3,
          unit: 'months',
          string: 'Past quarter',
        })}
      >
        Past quarter
      </Menu.Item>
      <Menu.Item
        onClick={() => setDateRange({
          amount: 6,
          unit: 'months',
          string: 'Past half year',
        })}
      >
        Past half year
      </Menu.Item>
      <Menu.Item
        onClick={() => setDateRange({
          amount: 1,
          unit: 'year',
          string: 'Past year',
        })}
      >
        Past year
      </Menu.Item>
    </Menu>
  )
}
