/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useState } from 'react'
import firebase from 'firebase'
import { Link } from 'react-router-dom'
import { useDropzone } from 'react-dropzone'
import {
  Button, Input, Popconfirm, message,
} from 'antd'
import { ReactComponent as Profile } from '../../icons/profile.svg'
import { ReactComponent as Edit } from '../../icons/edit.svg'
import FloatLabel from '../FloatLabel'
import Emoji from '../Emoji'
import validateEmail from '../../utils/validateEmail'
import authenticate from '../../api/authenticate'

export default function SuperfanSignup3({ brand, user, setUser }) {
  const [loginPop, setLoginPop] = useState()
  const [loading, setLoading] = useState()

  const checkEmail = async email => {
    if (validateEmail(email)) {
      const signInMethods = await firebase.auth().fetchSignInMethodsForEmail(email)
      const signedInUser = firebase.auth().currentUser
      const isUserSignedInWithDifferentEmail = signedInUser && (email !== signedInUser.email) && signInMethods?.length > 0
      const isEmailExisting = !signedInUser && signInMethods?.length > 0
      if (isUserSignedInWithDifferentEmail || isEmailExisting) {
        setLoginPop(true)
      } else {
        setLoginPop(false)
        setUser({ ...user, isValidEmail: true, isEditingEmail: false })
      }
    } else {
      setUser({ ...user, isValidEmail: false, isEditingEmail: false })
    }
  }

  useEffect(() => {
    if (user.email) {
      checkEmail(user.email)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const { getRootProps, getInputProps } = useDropzone({
    accept: 'image/jpeg, image/png',
    onDrop: acceptedFiles => {
      const newFile = acceptedFiles[0]
      const preview = URL.createObjectURL(acceptedFiles[0])
      const extension = newFile.name.slice(newFile.name.lastIndexOf('.'))
      setUser({
        ...user,
        image: {
          file: newFile,
          extension,
          url: preview,
        },
      })
    },
  })

  return (
    <div className="box">
      <h1 className="m-0 mt-3">
        <Emoji symbol="👩🏽 👦🏼" />&nbsp;&nbsp;Personal details
      </h1>
      <p>
        We are excited to get to know you! Please, use a profile picture were your face is visible.
      </p>

      <div key="dropzone">
        <div {...getRootProps({ className: 'profile' })}>
          <input {...getInputProps()} />
          {(user.image.url || user.imageUrl) ? (
            <img src={user.image.url || user.imageUrl} alt="profile" />
          ) : (
            <Profile className="light" />
          )}
          <Edit className="edit secondary" />
        </div>
      </div>

      <FloatLabel
        label="First Name"
        value={user.firstName}
      >
        <Input
          value={user.firstName}
          name="first-name"
          onChange={e => setUser({ ...user, firstName: e.target.value })}
        />
      </FloatLabel>

      <FloatLabel
        label="Last Name"
        value={user.lastName}
      >
        <Input
          value={user.lastName}
          name="last-name"
          onChange={e => setUser({ ...user, lastName: e.target.value })}
        />
      </FloatLabel>

      <FloatLabel
        label="Email"
        value={user.email}
      >
        <Popconfirm
          title="We've found an existing Superfan account with this email address, please sign in to continue"
          visible={loginPop}
          onConfirm={async () => {
            setLoading(true)
            const { email } = user
            const response = await authenticate(email, `${brand.handle}/join/3`)
            if (response === 'success') {
              message.success(`Sign-in link sent to ${email}`)
              setLoading('sent')
              window.localStorage.setItem('newEmail', email)
            } else {
              message.error('Couldn\'t connect to server')
              setLoading()
            }
          }}
          onCancel={() => {
            setLoginPop(false)
            setUser({ ...user, email: '' })
          }}
          okText={loading === 'sent' ? 'Email sent' : 'Send sign in link'}
          okButtonProps={{
            loading: loading === true,
            disabled: loading === 'sent',
          }}
        >
          <Input
            value={user.email}
            name="email"
            onChange={e => setUser({ ...user, email: e.target.value, isEditingEmail: true })}
            onBlur={e => checkEmail(e.target.value)}
          />
        </Popconfirm>
      </FloatLabel>
      {user.isValidEmail === false && (
      <p className="primary mt--3 mb-5">
        Please use a valid email address
      </p>
      )}

      <div className="small-container">
        <Link to={`/${brand.handle}/join/4`}>
          <Button
            type="primary"
            className="w-100"
            disabled={!user.firstName || !user.lastName || !user.email || (!user.image && !user.imageUrl) || loading === 'sent' || !user.isValidEmail || loginPop || user.isEditingEmail}
          >
            Continue
          </Button>
        </Link>
      </div>
    </div>
  )
}
