import React, { useState, useEffect } from 'react'
import firebase from 'firebase'
import queryString from 'query-string'

import { useParams, useHistory, useLocation } from 'react-router-dom'
import { message, Row, Col } from 'antd'
import Loader from '../Loader'

export default function ViewVideoMessage({ brand }) {
  const [video, setVideo] = useState()

  const history = useHistory()
  const location = useLocation()
  const urlParams = queryString.parse(location.search)
  const superfanID = urlParams.id

  const params = useParams()
  const videoID = params.id
  const db = firebase.firestore()

  useEffect(() => {
    const getStory = async () => {
      const s = await db
        .collection('videoMessages')
        .doc(videoID)
        .get()
        .then(doc => doc.data())
        .catch(error => console.error(error))

      if (s) {
        setVideo(s)
      } else {
        history.push(`/${brand.handle}`)
        message.error('Sorry, couldn\'t find video message 🧐')
      }
    }
    getStory()
  }, [brand.handle, db, history, superfanID, videoID])

  if (video) {
    return (
      <div className="stories floater">
        <div className="container">
          <div className="box">
            <h1>
              A video message from the {brand.name} team
            </h1>
            <Row gutter={[24, 24]}>
              <Col xs={24} md={12}>
                <video
                  controls
                  preload="auto"
                >
                  <source src={video.video} type="video/mp4" />
                  <p className="vjs-no-js">
                    To view this video please enable JavaScript, and consider upgrading to a
                    web browser that supports HTML5 video
                  </p>
                </video>
              </Col>
              <Col xs={24} md={12}>
                {video.message && (
                  <>
                    <h2 className="mb-0">
                      Message
                    </h2>
                    <div className="message scrollbox vertical">
                      <p className="m-0 pre-wrap">
                        {video.message}
                      </p>
                    </div>
                  </>
                )}
              </Col>
            </Row>
            <text> Want to reply? <a href={`mailto:${brand.defaultReplyTo}?subject=Superfan Story`}>Email us</a>  </text>
          </div>
        </div>
      </div>
    )
  }

  return <Loader />
}
