import firebase from 'firebase'

export default async function getAllBrands() {
  const db = firebase.firestore()
  const brands = []
  await db
    .collection('brands')
    .get()
    .then(querySnapshot => {
      querySnapshot.forEach(doc => {
        brands.push({
          ...doc.data(),
          id: doc.id,
        })
      })
    })
  return brands
}
