import React from 'react'

export default function SocialPreview({ channel, image, className }) {
  return (
    <div
      className={`social-preview${' ' + className}`}
      style={{
        backgroundImage: `url(${image})`,
      }}
    >
      <img className="phone" src="/img/social/phone.png" alt="phone" />
      <img className="channel" src={`/img/social/${channel}.svg`} alt="social" />
    </div>
  )
}
