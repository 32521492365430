import React from 'react'
import { Link } from 'react-router-dom'
import {
  Row, Col, Button,
} from 'antd'
import Fade from 'react-reveal/Fade'
import Bounce from 'react-reveal/Bounce'
import Flip from 'react-reveal/Flip'
import HeaderGraphic from './HeaderGraphic'
import { ReactComponent as LongArrow } from '../../icons/long-arrow.svg'
import { ReactComponent as Quote } from '../../icons/quote.svg'
import SocialPreview from '../SocialPreview'
import ImageGraphic from '../ImageGraphic'
import Emoji from '../Emoji'
import defaultBrandCopy from '../../lib/defaultBrandCopy'
import getSuperfanPrefix from '../../utils/getSuperfanPrefix'

export default function BrandHome({ brand, hover }) {
  const { colors } = brand

  const getPreview = id => {
    if (!brand.imageFiles) {
      return undefined
    }

    return brand.imageFiles[id] && brand.imageFiles[id].preview
  }

  return (
    <div className="brand-home">
      <div className="container main-container">
        <div className="header">
          <Row className="header-row">
            <Fade>
              <div className="background-image" style={{ backgroundImage: `url(${getPreview('header') || brand.headline?.image})` }} />
            </Fade>
            <Col xs={24}>
              <HeaderGraphic
                foregroundColor={colors.primary}
                backgroundColor={colors.secondary}
              >
                <h1 className={`title my-3 ${hover === 'headlineCTA' ? 'blink' : ''}`}>
                  {brand.headline?.CTA || `Become a${getSuperfanPrefix(brand.customSuperfan)} ${brand.customSuperfan || 'superfan'} of ${brand.name || '[your brand]'}`}
                </h1>
              </HeaderGraphic>
            </Col>

            <Col xs={24} md={10}>
              <Flip bottom>
                <div className="headline">
                  <h1 className={hover === 'headlineTitle' ? 'blink' : ''}>
                    {brand.headline?.title || 'Header title'}
                  </h1>
                  <p className={`super-large ${hover === 'headlineParagraph' ? 'blink' : ''}`}>
                    {brand.headline?.paragraph || 'Header paragraph'}
                  </p>
                </div>
              </Flip>
              <div className="cta mb-6 d-flex justify-center">
                <Link to={`/${brand.handle}/join`}>
                  <Button style={{ backgroundColor: colors.primary }} type="primary" className="px-6">
                    <span className={hover === 'headlineButton' ? 'blink' : ''}>
                      {brand.headline?.button || defaultBrandCopy.headlineButton}
                    </span>
                  </Button>
                </Link>
              </div>
            </Col>
          </Row>
          <div className="read-more">
            <p>
              read more
            </p>
            <LongArrow style={{ color: colors.primary }} />
          </div>
        </div>
      </div>

      <div style={{ backgroundColor: colors.secondary }}>
        <div className="container">
          <h1 className="title center mt-6" style={{ color: colors.text || 'white' }}>
            Do you qualify as a verified {brand.customSuperfan || 'superfan'}?
          </h1>
          <div className="xs-container">
            <ul className="checklist large semibold" style={{ color: colors.text || 'white' }}>
              <li className={hover === 'qualificationA' ? 'blink' : ''}>
                {brand.qualifications?.a || defaultBrandCopy.qualificationA}
              </li>
              <li className={hover === 'qualificationB' ? 'blink' : ''}>
                {brand.qualifications?.b || defaultBrandCopy.qualificationB}
              </li>
              <li>
                You have a social account
                <br />
                <span className="normal medium">
                  You qualify, no matter how many followers you have.
                </span>
              </li>
            </ul>
            <Link to={`/${brand.handle}/join`}>
              <Button style={{ backgroundColor: colors.primary }} type="primary" className="w-100 d-block mt-5">
                Yes, this is me
              </Button>
            </Link>
            <p className="center mt-2" style={{ color: colors.text || 'white' }}>
              No obligations. You’ll stay in control.
            </p>
          </div>
        </div>
      </div>

      <Row className="md-container mt-6">
        <Col xs={24}>
          <h1 className="title">
            What it means to be a{getSuperfanPrefix(brand.customSuperfan)} <span className="secondary">{brand.customSuperfan || 'superfan'}</span>
          </h1>
        </Col>
        <Col xs={24} md={12}>
          <div className="small-centered">
            <Fade left>
              <h2 className="m-0">
                <span className="emoji-bullet"><Emoji symbol="💌" /></span>
                1. Receive
              </h2>
              <p className="custom-ul">
                You receive beautiful stories in photo&apos;s, video&apos;s and text that are crafted for you.
              </p>
            </Fade>
          </div>
        </Col>

        <Col xs={24} md={12}>
          <div className="small-centered">
            <Fade right>
              <div className="ul-right">
                <h2 className="m-0">
                  <span className="emoji-bullet"><Emoji symbol="📲" /></span>
                  2. Share
                </h2>
                <p className="custom-ul">
                  Give the stories your personal touch and share them on your social media.
                </p>
              </div>
            </Fade>
          </div>
        </Col>

        <Col xs={24} md={12}>
          <div className="small-centered">
            <Fade left>
              <h2 className="m-0">
                <span className="emoji-bullet"><Emoji symbol="💡" /></span>
                3. Inspire
              </h2>
              <p className="custom-ul">
                Inspire your friends with your statement and support the brands you believe in.
              </p>
            </Fade>
          </div>
        </Col>

        <Col xs={24} md={12}>
          <div className="small-centered">
            <Fade right>
              <div className="ul-right">
                <h2 className="m-0">
                  <span className="emoji-bullet"><Emoji symbol="🎉" /></span>
                  4. Celebrate together
                </h2>
                <p className="custom-ul">
                  Celebrate our achievements together with other verified {brand.customSuperfan || 'superfan'}s and our team.
                </p>
              </div>
            </Fade>
          </div>
        </Col>
      </Row>

      {brand.superfans && brand.superfans.count > 4 && (
        <Row justify="center" className="sm-container overflow-x-hidden">
          <Col>
            <HeaderGraphic
              foregroundColor={colors.primary}
              backgroundColor={colors.secondary}
              style={{}}
              boxStyle={{}}
            >
              <h1>
                We proudly present
                <br />
                our {brand.superfans.count} {brand.customSuperfan || 'superfan'}s
              </h1>
            </HeaderGraphic>
          </Col>
          <Col className="profiles">
            {brand.superfans.images.map(img => (
              <Bounce key={img}>
                <ImageGraphic
                  img={img}
                  style={{
                    marginTop: Math.random() * 20,
                    marginBottom: Math.random() * 20,
                    marginLeft: Math.random() * 10 + 7,
                  }}
                />
              </Bounce>
            ))}
          </Col>
        </Row>
      )}

      <Row className="container">
        <Col span={24}>
          <h1 className="mt-4 mb-7 title">
            <Emoji symbol="🌊" /> Our {brand.customSuperfan || 'superfan'}s create waves
          </h1>
        </Col>
        <Col xs={12} lg={{ span: 7, offset: 4 }} className="d-flex align-center direction-column previews">
          <Fade>
            <SocialPreview
              image={getPreview('socialPreview0') || brand.screenshots[0].image}
              channel={brand.screenshots[0].channel}
              className="first preview"
            />
          </Fade>
          <Fade>
            <SocialPreview
              image={getPreview('socialPreview1') || brand.screenshots[1].image}
              channel={brand.screenshots[1].channel}
              className="preview"
            />
          </Fade>
          <p className="mega-large bold">
            {/* ...by  <span className="highlight" style={{ backgroundColor: colors.primary }}>111</span> {brand.customSuperfan || 'superfan'}s */}
            ...with <span className="highlight" style={{ backgroundColor: colors.primary }}>authentic</span> and <span className="highlight" style={{ backgroundColor: colors.primary }}>genuine</span> stories
          </p>
        </Col>

        <Col xs={12} lg={{ span: 7, offset: 1 }} className="d-flex align-center direction-column previews">
          <p className="mega-large bold">
            {/* <span className="highlight" style={{ backgroundColor: colors.primary }}>103.010</span> people reached... */}
            They reach out to their <span className="highlight" style={{ backgroundColor: colors.primary }}>friends</span>...
          </p>
          <Fade>
            <SocialPreview
              image={getPreview('socialPreview2') || brand.screenshots[2].image}
              channel={brand.screenshots[2].channel}
              className="preview"
            />
          </Fade>
          <Fade>
            <SocialPreview
              image={getPreview('socialPreview3') || brand.screenshots[3].image}
              channel={brand.screenshots[3].channel}
              className="preview"
            />
          </Fade>
        </Col>
      </Row>

      <div style={{ backgroundColor: colors.secondary }}>
        <Row className="sm-container">
          <Col>
            <Flip bottom>
              <div className="quote">
                <Quote className="icon" style={{ color: colors.primary }} />
                <div className="copy">
                  <h1 className={`${hover === 'representativeQuote' ? 'blink' : ''}`} style={{ color: colors.text || 'white' }}>
                    {brand.representative?.quote || defaultBrandCopy.representativeQuote}
                  </h1>
                </div>
                <div className="d-flex align-center">
                  <img src={getPreview('representative') || brand.representative?.image} alt="founder" className="round profile-img mr-3" />
                  <div>
                    <p className={`m-0 ${hover === 'representativeName' ? 'blink' : ''}`} style={{ color: colors.text || 'white' }}>
                      {brand.representative?.name || 'Representative name'}
                    </p>
                    <p className={`bold m-0 ${hover === 'representativeTitle' ? 'blink' : ''}`} style={{ color: colors.text || 'white' }}>
                      {brand.representative?.title || 'Representative title'}
                    </p>
                  </div>
                </div>
              </div>
            </Flip>
          </Col>
        </Row>
      </div>

      <div className="bg-gray">
        <div className="container d-flex align-center">
          <img src="/img/logo.svg" alt="superfan-logo" className="footer-logo" />
          <h3 className="m-0">
            &nbsp;helps us to make this possible.
          </h3>
        </div>
      </div>
    </div>
  )
}
