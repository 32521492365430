import React, { useState } from 'react'
import {
  Row, Col, Button,
} from 'antd'
import Fade from 'react-reveal/Fade'

import { Link } from 'react-router-dom'
import Modal from 'antd/lib/modal/Modal'
import SocialPreview from './SocialPreview'
import SuggestBrandFields from './SuggestBrandFields'

export default function BecomeSuperfan() {
  const [modalStatus, setModalStatus] = useState()

  return (
    <div className="join">
      <div className="container container-x">
        <Row>
          <Col xs={23} md={12} lg={10} className="mb-9">
            <h1 className="title">
              Become <span className="secondary">Superfan</span>
            </h1>

            <p className="mb-6">
              Do you love spreading missions for the brand that you are a superfan of? Superfans spread stories of mission-driven brands through their social network. We connect you to the brand you love.
            </p>

            <div className="checklist-container padding">
              <ul className="checklist secondary large">
                <li>Spread meaningful messages of the brands you love</li>
                <li>Get recognised by the brand on their social media </li>
                <li>Join the brand’s Superfan tribe</li>
              </ul>
            </div>

          </Col>
          <Col xs={0} md={{ span: 11, offset: 1 }} lg={{ span: 11, offset: 3 }}>
            <Row className="bg-ripple">
              <Col xs={12} lg={{ span: 7, offset: 4 }} className="d-flex align-center direction-column previews">
                <Fade>
                  <SocialPreview
                    image="/img/story-1.png"
                    channel="instagram"
                    className="first preview"
                  />
                </Fade>
                <Fade>
                  <SocialPreview
                    image="/img/story-2.png"
                    channel="instagram"
                    className="preview"
                  />
                </Fade>
              </Col>

              <Col xs={12} lg={{ span: 7, offset: 1 }} className="d-flex align-center direction-column previews mt-5">
                <Fade>
                  <SocialPreview
                    image="/img/story-3.png"
                    channel="instagram"
                    className="preview"
                  />
                </Fade>
                <Fade>
                  <SocialPreview
                    image="/img/story-4.png"
                    channel="instagram"
                    className="preview"
                  />
                </Fade>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row gutter={[24, 24]}>
          <Col xs={24} md={20}>
            <h1 className="title m-0">
              Our <span className="secondary">mission-driven brands</span> that want <span className="secondary">you</span> to join their tribe
            </h1>
          </Col>
          <Col xs={24} md={12}>
            <p>
              Become a recognised superfan of one the brands that spread meaningful stories to make the world a better place.
            </p>
          </Col>
        </Row>
        <Row gutter={[24, 24]}>
          <Col xs={24} lg={8} className="d-flex direction-column align-center">
            <div className="brand-logo-container mb-5" style={{ backgroundImage: 'url(/img/brands/klabu.png)' }} />
            <Link to="/klabu">
              <Button type="primary">
                Read more
              </Button>
            </Link>
          </Col>
          <Col xs={24} lg={8} className="d-flex direction-column align-center">
            <div className="brand-logo-container mb-5" style={{ backgroundImage: 'url(/img/brands/dutch-weed-burger.png)' }} />
            <Link to="/dutch-weed-burger">
              <Button type="primary">
                Read more
              </Button>
            </Link>
          </Col>
          <Col xs={24} lg={8} className="d-flex direction-column align-center">
            <div className="brand-logo-container mb-5" style={{ backgroundImage: 'url(/img/brands/forest-forces.png)' }} />
            <Link to="/forest-forces">
              <Button type="primary">
                Read more
              </Button>
            </Link>
          </Col>
        </Row>
        <Row gutter={[24, 24]} className="mt-8">
          <Col xs={24}>
            <h1 className="title m-0">
              Your voice <span className="secondary">matters</span>
            </h1>
          </Col>
          <Col xs={24} md={6}>
            <img className="mb-4" src="/img/diamond.svg" alt="diamond" />
            <h1>
              Receive
            </h1>
            <p>
              You receive beautiful stories in photos, videos and text that are crafted for you.
            </p>
          </Col>
          <Col xs={24} md={6}>
            <img className="mb-4" src="/img/heart-notification.svg" alt="personalize" />
            <h1>
              Personalize
            </h1>
            <p>
              Give the stories your personal touch and share them on your social media.
            </p>
          </Col>
          <Col xs={24} md={6}>
            <img className="mb-4" src="/img/lamp.svg" alt="lamp" />
            <h1>
              Inspire
            </h1>
            <p>
              Inspire your friends with your statement and support the brands you believe in.
            </p>
          </Col>
          <Col xs={24} md={6}>
            <img className="mb-4" src="/img/celebrate.svg" alt="celebrate" />
            <h1>
              Celebrate
            </h1>
            <p>
              Get recognized by the brand and celebrate together on a personal level.
            </p>
          </Col>
        </Row>
      </div>
      <div className="bg-gray-light">
        <div className="container">
          <Row gutter={[24, 16]}>
            <Col xs={24}>
              <h1 className="title mb-0">
                Is the brand you love <span className="secondary">missing</span>?
              </h1>
            </Col>
            <Col xs={24} md={12}>
              <p>
                Are you a superfan of a brand that we don’t collaborate with? Let us know, and we’ll follow-up with the brand that you’re waiting for them.
              </p>
            </Col>
          </Row>
          <Button
            type="primary mb-6"
            onClick={() => setModalStatus(true)}
          >
            Let us know
          </Button>
        </div>
      </div>
      <Modal
        className="modal-hide-footer suggest-brand-modal"
        visible={modalStatus !== undefined}
        onCancel={() => setModalStatus()}
        width={620}
      >
        <>
          <div className="padding">
            <h1>
              What brand are you <span className="secondary">superfan</span> of?
            </h1>
            <p>
              We always strive to extend our partnerships. Let us know which brand you love but you’re missing in our list. We’ll update you as soon as we know more about this brand.
            </p>
            <div className="bg-gray-light margin-x--2 padding padding-x-2 my-4">
              <h2>
                Other superfans submitted
              </h2>
              <div className="d-flex align-center">
                <div className="small-brand-logo-container" style={{ backgroundImage: 'url(/img/brands/adidas.png)' }} />
                <div className="small-brand-logo-container ml-3" style={{ backgroundImage: 'url(/img/brands/oatly.png)' }} />
                <div className="small-brand-logo-container ml-3" style={{ backgroundImage: 'url(/img/brands/patagonia.png)' }} />
              </div>
            </div>
            <SuggestBrandFields />
          </div>
        </>
      </Modal>
    </div>
  )
}
