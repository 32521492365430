import React from 'react'
import { Button, notification } from 'antd'

export default function CookieConsent() {

  notification.open({
    message: 'A little heads-up about your privacy',
    description: (
      <span>
        Please read our <a href="/privacy-policy.pdf" target="_blank">privacy policy</a> here and agree below to use our site
      </span>
    ),
    duration: 0,
    placement: 'bottomRight',
    closeIcon: <></>,
    btn: (
      <Button
        type="primary"
        onClick={() => {
          window.localStorage.setItem('cookieConsent', true)
          notification.close('cookieConsent')
        }}
      >
        I agree
      </Button>
    ),
    key: 'cookieConsent',
  })

  return null
}
