import React from 'react'
import { Dropdown, Button } from 'antd'
import { ResponsiveBar } from '@nivo/bar'
import numeral from 'numeral'
import moment from 'moment'

import { DownOutlined } from '@ant-design/icons'
import colors from '../../../styles/colors.scss'
import DateRangeMenu from '../menus/DateRangeMenu'

export default function SuperfansChart({
  superfans,
  pendingCount,
  startDate,
  dateRange,
  setDateRange,
  showDateSelector,
}) {

  const getChartColor = id => {
    switch (id) {
      case 'accepted':
        return colors.secondaryLight
      case 'current':
        return colors.secondary
      case 'pending':
        return colors.primaryLight
      default:
        return colors.secondaryLight
    }
  }

  const data = []

  for (const object of superfans) {
    const date = moment(object.date)

    if (date > startDate && date < moment()) {
      if (date.format('MMM') === moment().format('MMM')) {
        data.push({
          date: date.format('MMM'),
          accepted: null,
          current: object.superfans,
          pending: pendingCount,
        })
      } else {
        data.push({
          date: date.format('MMM'),
          accepted: object.superfans,
        })
      }
    }
  }

  return (
    <div className="superfans-chart component" style={{ gridArea: 'superfans-chart' }}>
      <div className="d-flex justify-between">
        <div>
          <h2 className="m-0">
            Superfans
          </h2>
          <div className="legend">
            <div className="thumbnail" style={{ background: getChartColor('current') }} />
            <p className="m-0">
              <span className="bold">{superfans[superfans.length - 1].superfans.toLocaleString('nl-NL')}</span> currently
            </p>
            <div className="thumbnail" style={{ background: getChartColor('pending') }} />
            <p className="m-0">
              <span className="bold">{pendingCount.toLocaleString('nl-NL')}</span> pending
            </p>
          </div>
        </div>

        {showDateSelector && (
        <div
          className="date-selector"
          style={{ gridArea: 'date-selector' }}
        >
          <Dropdown
            overlay={
              <DateRangeMenu setDateRange={setDateRange} />
          }
          >
            <Button>
              {dateRange.string}
              <DownOutlined />
            </Button>
          </Dropdown>
        </div>
        )}

      </div>
      <div className="chart">
        <ResponsiveBar
          data={data}
          keys={['accepted', 'current', 'pending']}
          indexBy="date"
          margin={{
            top: 10, right: 20, bottom: 30, left: 30,
          }}
          padding={0.5}
          enableLabel={false}
          axisTop={null}
          axisRight={null}
          axisBottom={{
            orient: 'bottom',
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
          }}
          axisLeft={{
            orient: 'left',
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            format: value => (Number.isInteger(value) ? numeral(value).format('0a') : ''),
          }}
          colors={serie => getChartColor(serie.id)}
          enablePoints={false}
          enableArea
          areaOpacity={0.1}
          useMesh
        />
      </div>
    </div>
  )
}
