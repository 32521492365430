/* eslint-disable react/jsx-props-no-spreading */
import React, { useState } from 'react'
import firebase from 'firebase'
import { Link, useHistory } from 'react-router-dom'
import {
  Button, Checkbox, message, Progress,
} from 'antd'
import createUser from '../../api/createUser'
import { ReactComponent as Check } from '../../icons/check.svg'
import { ReactComponent as Profile } from '../../icons/profile.svg'
import createContact from '../../emails/createContact'
import sendVerificationEmail from '../../emails/sendVerificationEmail'

export default function SuperfanSignup5({
  brand, user, setUser,
}) {
  const [loading, setLoading] = useState()
  const [uploadProgress, setUploadProgress] = useState(null)

  const db = firebase.firestore()
  const storage = firebase.storage()
  const history = useHistory()

  return (
    <div className="box">
      <h1 className="m-0 mt-3">
        {user.firstName}, you&apos;re all set!
      </h1>
      <p>
        Let’s check, check, double check. Make sure that you’ve filled in everything as you want.
      </p>

      <div className="summary-section">
        <div className="d-flex justify-between align-center">
          <div className="d-flex align-center">
            <Check className="tertiary" />
            <h2 className="m-0">
                &nbsp;&nbsp;Personal details
            </h2>
          </div>
          <Link to={`/${brand.handle}/join/3`}>
            <img width="17" height="17" src="/img/pencil.png" alt="pencil" />
          </Link>
        </div>
        <div className="box">
          {(user.image.url || user.imageUrl) ? (
            <img className="profile-image" src={user.image.url || user.imageUrl} alt="profile" />
          ) : (
            <Profile className="profile-image light" />
          )}
          <div>
            {user.firstName} {user.lastName}
            <br />
            {user.email}
          </div>
        </div>
      </div>

      <div className="separator mb-3" />

      <div className="summary-section">
        <div className="d-flex justify-between align-center">
          <div className="d-flex align-center">
            <Check className="tertiary" />
            <h2 className="m-0">
                &nbsp;&nbsp;Your motivation
            </h2>
          </div>
          <Link to={`/${brand.handle}/join/4`}>
            <img width="17" height="17" src="/img/pencil.png" alt="pencil" />
          </Link>
        </div>
        {user.quote && (
          <div className="box direction-column align-start">
            &quot;{user.quote}&quot;
          </div>
        )}
        {user.video.url && (
          <div className="box direction-column align-start">
            <video
              controls
              preload="none"
            >
              <source src={user.video.url} type="video/mp4" />
            </video>
          </div>
        )}
      </div>

      <div className="separator mb-3" />

      <div className="summary-section mb-3">
        <div className="d-flex justify-between align-center">
          <div className="d-flex align-center">
            <Check className="tertiary" />
            <h2 className="m-0">
                &nbsp;&nbsp;Social accounts
            </h2>
          </div>
          <Link to={`/${brand.handle}/join/2`}>
            <img width="17" height="17" src="/img/pencil.png" alt="pencil" />
          </Link>
        </div>
        {user.socialAccounts?.instagram?.username && (
          <div className="box">
            <img src="/img/social/instagram.png" alt="instagram" className="icon" />
            {user.socialAccounts.instagram.username}
          </div>
          )}

        {user.socialAccounts?.linkedIn?.username && (
          <div className="box">
            <img src="/img/social/linkedin.png" alt="linkedin" className="icon" />
            {user.firstName} {user.lastName}
          </div>
          )}

        {(!user.socialAccounts?.linkedIn?.username && !user.socialAccounts?.instagram?.username) && (
          <div className="box">
            No accounts connected
          </div>
        )}
      </div>

      <div className="separator mb-3" />

      <Checkbox
        className="mb-3"
        onChange={checked => setUser({ ...user, acceptedTerms: checked })}
        checked={user.acceptedTerms}
      >
        I have read and accept the <a href="/terms-of-use.pdf" target="_blank">Terms of Use</a>
      </Checkbox>

      <Button
        type="primary"
        className="w-100"
        disabled={!user.acceptedTerms}
        loading={loading}
        onClick={async () => {
          try {
            setLoading(true)

            let signedInUser = firebase.auth().currentUser

            if (signedInUser === null) {
              await createUser(user.email)
              signedInUser = await firebase.auth().currentUser
            }

            const userData = {
              superfanOf: user.superfanOf ? [...user.superfanOf, brand.id] : [brand.id],
              firstName: user.firstName,
              lastName: user.lastName,
              email: signedInUser.email,
              socialAccounts: user.socialAccounts,
            }

            if (user.imageUrl) userData.imageUrl = user.imageUrl

            if (user.image.base64) {
              const ref = await storage.ref().child(`images/user-profiles/${signedInUser.uid}${user.image.extension}`)
              userData.imageUrl = await ref
                .putString(user.image.base64, 'base64')
                .then(() => ref.getDownloadURL()
                  .then(url => url))
            }

            userData.reach = (
              user.socialAccounts.instagram?.followers || 0
            ) + (
              user.socialAccounts.linkedIn?.connections || 0
            )

            const applicationData = {
              brandID: brand.id,
              userID: signedInUser.uid,
              status: 'pending',
              emailVerified: signedInUser.emailVerified,
            }

            if (user.quote) applicationData.quote = user.quote

            const applicationID = `${brand.id}-${signedInUser.uid}`

            if (user.video.file) {
              const ref = await storage.ref().child(`videos/applications/${applicationID}${user.video.extension}`)
              const uploadVideo = new Promise((handleSuccess, handleError) => {
                const uploadTask = ref.put(user.video.file)

                uploadTask.on('state_changed', snapshot => {
                  const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100
                  setUploadProgress(progress)
                },
                error => handleError(error),
                () => {
                  uploadTask.snapshot.ref.getDownloadURL().then(downloadURL => {
                    handleSuccess(downloadURL)
                  })
                })
              })

              const videoUrl = await uploadVideo.then(downloadURL => downloadURL)

              applicationData.video = videoUrl
            }

            applicationData.created = Date.now()

            await db.collection('users').doc(signedInUser.uid).set(userData, { merge: true })

            await db.collection('applications').doc(`${applicationID}`).set(applicationData)

            const emailData = {
              email: user.email,
              firstName: user.firstName,
              lastName: user.lastName,
              brand: brand.name,
              superfan: brand.customSuperfan || 'superfan',
            }

            if (signedInUser.emailVerified) {
              setUser({ ...user, isCompleted: true })
              history.push(`/${brand.handle}/join/6`)
              setLoading()
            } else {
              const response = await sendVerificationEmail({
                ...emailData,
                continuePath: `/${brand.handle}/join/8?email=${user.email}&applicationID=${applicationID}`,
              })

              if (response === true) {
                setUser({ ...user, isCompleted: true })
                history.push(`/${brand.handle}/join/7`)
                setLoading()
              } else {
                throw new Error(response)
              }
            }

            createContact(emailData)

          } catch (error) {
            setLoading()
            message.error('Error saving data')
            console.log(error)
          }
        }}
      >
        Let&apos;s send
      </Button>
      {uploadProgress !== null && (
      <div className="d-flex align-center mt-3">
        <p className="mb-0 mr-3">Uploading video:</p>
        <Progress
          className="progress"
          percent={uploadProgress.toFixed(0)}
          size="small"
          status="active"
        />
      </div>
      )}
    </div>
  )
}
