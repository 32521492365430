import React, { useEffect, useState, useCallback } from 'react'
import firebase from 'firebase'
import axios from 'axios'
import queryString from 'query-string'
import {
  Spin, Input, Row, Col, Button,
} from 'antd'
import { useLocation, useHistory } from 'react-router-dom'
import verifyEmail from '../api/verifyEmail'
import FloatLabel from '../components/FloatLabel'
import signInWithEmailLink from '../api/signInWithEmailLink'

export default function EmailHandler() {
  const location = useLocation()
  const history = useHistory()
  const params = queryString.parse(location.search)
  const {
    mode, oobCode, continuePath,
  } = params
  const [success, setSuccess] = useState()
  const [error, setError] = useState()
  const [requestEmail, setRequestEmail] = useState()
  const [email, setEmail] = useState()

  const continuePathDecoded = decodeURIComponent(continuePath)
  const continuePathParams = queryString.parse(continuePathDecoded)
  const { applicationID } = continuePathParams

  const handleSignIn = useCallback(async userEmail => {
    const response = await signInWithEmailLink(userEmail)

    if (response.uid) {
      if (continuePath) {
        history.push(continuePath)
      } else {
        history.push('/')
      }
    } else {
      setError(response.message)
    }
  }, [continuePath, history])

  useEffect(() => {
    const handleVerifyEmail = async () => {
      const response = await verifyEmail(oobCode)
      if (response === true) {
        const handleVerifyEmailUrl = `${process.env.REACT_APP_FUNCTIONS_URL}/setEmailVerified`
        const headers = { accept: 'application/json' }

        if (applicationID) {
          axios
            .post(handleVerifyEmailUrl, { applicationID }, { headers })
            .then(() => {
              setSuccess('Email verified, redirecting..')
              setTimeout(() => {
                if (continuePath) {
                  history.push(continuePath)
                } else {
                  history.push('/')
                }
              }, 2000)
            })
            .catch(() => {
              setSuccess('')
              setError('Sorry, something went wrong verifying your email')
            })
        } else {
          setSuccess('Email verified, redirecting..')
          setTimeout(() => {
            if (continuePath) {
              history.push(continuePath)
            } else {
              history.push('/')
            }
          }, 2000)
        }
      } else {
        setError(response.message)
      }
    }

    const savedEmail = window.localStorage.getItem('emailForSignIn') || params.email

    switch (mode) {
      case 'signIn':
        if (firebase.auth().isSignInWithEmailLink(window.location.href)) {
          if (savedEmail) {
            handleSignIn(savedEmail)
          } else {
            setRequestEmail(true)
          }
        } else {
          setError('Sorry, this sign in link is not valid')
        }
        break
      case 'recoverEmail':
        // TODO: write handleRecoverEmail
        // handleRecoverEmail(oobCode)
        break
      case 'verifyEmail':
        handleVerifyEmail()
        break
      default:
        history.push('/')
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div className="full-screen d-flex align-center justify-center">
      <div className="d-flex align-center justify-center direction-column" style={{ width: '300px', marginTop: '-200px' }}>
        <img src="/img/logo.svg" alt="superfan-logo" style={{ width: '200px', marginBottom: '30px' }} />
        {requestEmail && (
          <Row gutter={[12, 16]}>
            <Col xs={24}>
              Please re-enter email to complete sign-in
              <FloatLabel
                label="Email"
                value={email}
                className="mt-4"
              >
                <Input
                  name="email"
                  value={email}
                  onChange={e => setEmail(e.target.value)}
                  onPressEnter={() => handleSignIn(email)}
                />
              </FloatLabel>
            </Col>
            <Col xs={24}>
              <Button
                type="primary"
                className="w-100"
                onClick={() => handleSignIn(email)}
              >
                Sign in
              </Button>
            </Col>
          </Row>
        )}

        {success && (
        <p className="tertiary center">
          {success}
        </p>
        )}

        {error && (
        <p className="primary center">
          {error}
        </p>
        )}

        {(!error && !success && !requestEmail) && (
        <Spin size="large" />
        )}
      </div>
    </div>
  )
}
