import React from 'react'
import {
  Row, Col,
} from 'antd'

import Ripple from './Ripple'

export default function About() {
  return (
    <div className="about container container-x">
      <Row>
        <Col xs={24} md={0} className="mobile-hero">
          <img src="/img/about-mobile.png" alt="hero" className="w-100" />
        </Col>
        <Col xs={23} md={12} lg={10} className="mb-9">
          <h1 className="title">
            Hi, we are <span className="secondary">Superfan</span>
          </h1>

          <p className="mb-6">
            We see brands behave like activists. By taking responsibility in today’s society, they stand for something while offering products and services customers enjoy. These brands have customers, fans and even superfans. We believe Superfans are the drivers of brand activism and together they add to a happier, healthier and more just world for all.
          </p>

          <p>
            That’s why we built the Superfan tool that makes word-of-mouth marketing easy by helping brands to collect and activate superfans.
          </p>
        </Col>
        <Col xs={0} md={{ span: 11, offset: 1 }} lg={{ span: 9, offset: 5 }} className="hero">
          <img src="/img/about.png" alt="hero" />
        </Col>
      </Row>
      <h1>
        Our <span className="secondary">mantra</span>
      </h1>
      <Row gutter={[24, 24]}>
        <Col xs={24} lg={7} className="d-flex justify-center">
          <Ripple>
            <h1>
              Lead with fun
            </h1>
            <p>
              Making the world a better place can be a serious business, we make sure we
              have fun while making this happen.
            </p>
          </Ripple>
        </Col>
        <Col xs={24} lg={{ span: 7, offset: 1 }} className="d-flex justify-center">
          <Ripple>
            <h1>
              Trust others
            </h1>
            <p>
              Surrender to the idea that other people have good intentions. Listen to understand and connect with positivity.
            </p>
          </Ripple>
        </Col>
        <Col xs={24} lg={{ span: 7, offset: 1 }} className="d-flex justify-center">
          <Ripple>
            <h1>
              Find your tribe
            </h1>
            <p>
              We all have a place in the world. Combining forces with a tribe makes you stronger, together.
            </p>
          </Ripple>
        </Col>
      </Row>
      <div className="bg-wave mx--padding" />
      <Row className="bg-secondary mx--padding padding">
        <Col xs={24} md={7} lg={{ span: 5, offset: 2 }} className="team mt-4 mb-4">
          <Row gutter={[24, 24]}>
            <Col className="d-flex direction-column align-center">
              <img src="/img/tim.jpg" alt="tim" className="profile round" />
              <a className="mt-2" href="https://www.linkedin.com/in/timmanschot/" target="_blank" rel="noopener noreferrer">
                <img src="/img/brands/linkedin.png" alt="linkedin" width="24" />
              </a>
            </Col>
            <Col className="d-flex direction-column align-center">
              <img src="/img/michiel.jpg" alt="michiel" className="profile round" />
              <a className="mt-2" href="https://www.linkedin.com/in/koningdemichiel/" target="_blank" rel="noopener noreferrer">
                <img src="/img/brands/linkedin.png" alt="linkedin" width="24" />
              </a>
            </Col>
          </Row>
        </Col>
        <Col xs={24} md={{ span: 14, offset: 1 }}>
          <h1 className="medium white">
            Tim and Michiel
          </h1>
          <p className="white">
            Michiel and Tim are social entrepreneurs with a strong focus on social and environmental justice. They believe in the power of people to create change if they speak-up about issues that matter to them. When millions of people started to call on governments during the amazon fires, it made them tick. When fans of vegan brands called on the EU to treat non-dairy fairly, it made them tick. When companies and their fans speak-up about Black Lives Matter, climate change, and pollution, it makes them tick.
          </p>
          <p className="white">
            Feel free to connect with us on LinkedIn
          </p>
        </Col>
      </Row>
    </div>
  )
}
