import React from 'react'
import { ReactComponent as Instagram } from '../icons/instagram.svg'
import { ReactComponent as LinkedIn } from '../icons/linkedin.svg'
import { ReactComponent as TikTok } from '../icons/tiktok.svg'
import { ReactComponent as Facebook } from '../icons/facebook.svg'

export default function SocialIcon({ channel, className }) {
  const icons = {
    instagram: Instagram,
    linkedin: LinkedIn,
    tiktok: TikTok,
    facebook: Facebook,
  }
  const Icon = icons[channel || 'instagram']
  return <Icon className={className} />
}
