/* eslint-disable react/jsx-props-no-spreading */
import React from 'react'
import { Link } from 'react-router-dom'
import { Button } from 'antd'
import Emoji from '../Emoji'

export default function SuperfanSignup6({ brand }) {
  return (
    <div className="box">
      <h1 className="m-0 mt-3 mb-5">
        <Emoji symbol="🎉" />&nbsp;&nbsp;Done! What happens next?
      </h1>

      <h2 className="m-0">
        <span className="emoji-bullet"><Emoji symbol="🔎" /></span>
        We will verify your details
      </h2>

      <div className="separator mb-3" />

      <h2 className="m-0">
        <span className="emoji-bullet"><Emoji symbol="📩" /></span>
        <span>
          We get in contact with you by email
        </span>
      </h2>
      <Link to={`/${brand.handle}`}>
        <Button
          type="secondary"
          className="w-100 mt-5"
        >
          Return to {brand.name}
        </Button>
      </Link>
    </div>
  )
}
