export default function getValue(reach) {
  let engagementRate
  let valuePerEngagement
  if (reach < 1000) {
    engagementRate = 0.08
    valuePerEngagement = 0.4
  } else if (reach < 5000) {
    engagementRate = 0.057
    valuePerEngagement = 0.25
  } else if (reach < 10000) {
    engagementRate = 0.04
    valuePerEngagement = 0.2
  } else if (reach < 100000) {
    engagementRate = 0.024
    valuePerEngagement = 0.1
  } else {
    engagementRate = 0.017
    valuePerEngagement = 0.07
  }
  return Math.round(reach * engagementRate * valuePerEngagement)
}
