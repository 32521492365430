/* eslint-disable react/jsx-props-no-spreading */
import React, { useState } from 'react'
import { useDropzone } from 'react-dropzone'

import { ReactComponent as Image } from '../icons/image.svg'

export default function ImageUploader({
  imageFile, handleUpload, id, height, contain,
}) {
  const [dropzone, setDropzone] = useState({})

  const { getRootProps, getInputProps } = useDropzone({
    accept: 'image/jpeg, image/png',
    onDrop: acceptedFiles => {
      const newFiles = acceptedFiles.map(file => Object.assign(
        file,
        { preview: URL.createObjectURL(file) },
      ))
      handleUpload(newFiles[0], id)
    },
  })

  return (
    imageFile ? (
      <div
        className="dropzone-container"
        onMouseEnter={() => setDropzone(true)}
        onMouseLeave={() => setDropzone()}
      >
        <div
          {...getRootProps({
            className: 'dropzone',
            style: {
              height,
              border: 'none',
              backgroundImage:
                dropzone ? `
                linear-gradient(
                  rgba(0, 0, 0, 0.45),
                  rgba(0, 0, 0, 0.45)
                  ),
                  url(${imageFile.preview})` : `
                  url(${imageFile.preview})`,
              backgroundSize: contain ? 'contain' : 'cover',
              backgroundRepeat: 'no-repeat',
              backgroundPosition: 'center',
            },
          })}
        >
          <input {...getInputProps()} />
          <div style={{ opacity: dropzone ? 100 : 0, textAlign: 'center' }}>
            <Image className="white" />
            <p className="w-100 white">
              <span className="bold">
                Upload {id} here
              </span>
              {' '}
              or just drag and drop
            </p>
          </div>
        </div>
      </div>
    ) : (
      <div
        className="dropzone-container"
      >
        <div {...getRootProps({ className: 'dropzone', style: { height } })}>
          <input {...getInputProps()} />
          <Image className="secondary" />
          <p className="w-100">
            <span className="bold secondary">
              Upload {id} here
            </span>
            {' '}
            or just drag and drop
          </p>
        </div>
      </div>
    )
  )
}
