import {
  Button, Col, Input, Row,
} from 'antd'
import Modal from 'antd/lib/modal/Modal'
import update from 'react-addons-update'
import Axios from 'axios'
import React, { useState } from 'react'
import getInstagramProfile from '../../api/getInstagramProfile'
import FloatLabel from '../FloatLabel'

export default function ConnectInstagram({
  user,
  setUser,
  brand,
}) {

  const [search, setSearch] = useState()
  const [instagramModal, setInstagramModal] = useState()

  const isInstagramConnected = user.socialAccounts.instagram?.username !== undefined

  const handleSearch = async () => {
    setInstagramModal('loading')
    const response = await getInstagramProfile(search)
    if (response.data) {
      const profile = response.data.user
      const { username } = profile
      const imageUrl = profile.profile_pic_url_hd
      const followers = profile.edge_followed_by.count
      const fullName = profile.full_name
      const firstName = fullName.slice(0, fullName.indexOf(' '))
      const lastName = fullName.slice(fullName.indexOf(' ') + 1)

      const res = await Axios.post(`${process.env.REACT_APP_FUNCTIONS_URL}/getImageBase64`, { imageUrl })
      const base64 = res.data
      const preview = `data:image/png;base64, ${base64}`

      const newUser = {
        ...user,
        socialAccounts: {
          ...user.socialAccounts,
          instagram: {
            username,
            followers,
          },
        },
        instaImg: preview,
      }

      if (!user.imageUrl) {
        newUser.image = {
          base64,
          url: preview,
          extension: '.jpg',
        }
      }

      if (!user.uid) {
        newUser.firstName = firstName
        newUser.lastName = lastName
      }

      setUser(newUser)
      setInstagramModal('loaded')
    } else {
      setInstagramModal('error')
    }
  }

  return (
    <>
      <div
        className={`social-account mb-3 ${isInstagramConnected && 'connected'}`}
        onClick={isInstagramConnected ? () => setUser(update(user, { socialAccounts: { instagram: { $set: {} } } })) : () => setInstagramModal(true)}
      >
        <div className="left">
          <img src="/img/social/instagram.png" alt="instagram" />
          {user.socialAccounts.instagram?.username || 'Instagram'}
        </div>
        <div className="right mr-2">
          {isInstagramConnected ? 'Remove' : 'Connect'}
        </div>
      </div>
      <Modal
        title="Connect Instagram"
        className="connect-instagram modal-hide-footer"
        visible={instagramModal !== undefined}
        onCancel={() => setInstagramModal()}
      >
        <Row gutter={[12, 16]}>
          <Col xs={24} md={18}>
            <FloatLabel
              label="Username"
              value={search}
            >
              <Input
                value={search}
                onChange={e => setSearch(e.target.value.toLowerCase())}
                onPressEnter={handleSearch}
              />
            </FloatLabel>
          </Col>
          <Col xs={24} md={6}>
            <Button
              type="primary"
              className="w-100"
              onClick={handleSearch}
              loading={instagramModal === 'loading'}
              disabled={!search}
            >
              Search
            </Button>
          </Col>
        </Row>

        <p>
          {instagramModal === 'error' && 'User not found'}
        </p>

        {instagramModal === 'loaded' && (
        <div className="instagram-profile d-flex align-center">
          <img src={user.instaImg} alt="profile" />
          <div className="mr-3">
            <p className="bold m-0">
              {user.firstName} {user.lastName}
            </p>
            <p className="light m-0">
              {user.socialAccounts.instagram?.followers.toLocaleString('nl-nl')} followers
            </p>
          </div>
          <Button
            type="primary"
            onClick={() => setInstagramModal()}
          >
            Connect
          </Button>

        </div>
        )}

        <p className="light">
          We will only share your profile&apos;s public information with {brand.name}
        </p>
      </Modal>
    </>
  )
}
