import React, { useEffect, useState } from 'react'
import firebase from 'firebase'
import {
  Link, Route, useLocation, Switch,
} from 'react-router-dom'
import {
  Button, Layout, Menu, Row, Col,
} from 'antd'
import useWindowDimensions from '../utils/useWindowDimensions'

import Home from '../components/Home'
import Emoji from '../components/Emoji'
import About from '../components/About'
import CalculatePotential from '../components/CalculatePotential'
import BecomeSuperfan from '../components/BecomeSuperfan'
import RequestDemoModal from '../components/RequestDemoModal'
import UserDropdown from '../components/UserDropdown'

const {
  Header, Content, Sider, Footer,
} = Layout

export default function HomeContainer() {
  const [collapseSider, setCollapseSider] = useState(true)
  const [modalStatus, setModalStatus] = useState()
  const [user, setUser] = useState()

  const { width } = useWindowDimensions()
  const location = useLocation()

  const mobileMenuBreakpoint = 918

  const db = firebase.firestore()

  useEffect(() => {
    const unsubscribe = firebase.auth().onAuthStateChanged(async u => {
      if (u) {
        const idToken = await firebase.auth().currentUser.getIdTokenResult()

        const { superAdmin, adminOf } = idToken.claims
        db
          .collection('users')
          .doc(u.uid)
          .get()
          .then(doc => {
            console.log(doc.data())
            console.log(idToken.claims.superAdmin)
            console.log(idToken.claims.adminOf)
            setUser({
              ...doc.data(),
              superAdmin,
              adminOf,
            })
          })
      } else {
        setUser()
      }
    })

    return unsubscribe
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const getActiveTab = () => {
    if (location.pathname.includes('/calculate-potential')) {
      return 1
    }

    if (location.pathname.includes('/join')) {
      return 2
    }

    if (location.pathname.includes('/about')) {
      return 3
    }

    return 0
  }

  const HomeMenu = () => (
    <div className={`d-flex align-center justify-between ${width < mobileMenuBreakpoint && 'direction-column'}`}>
      <Link to="/">
        <img className="logo" src="/img/logo.svg" alt="superfan-logo" />
      </Link>
      <div className={`d-flex align-center ${width < mobileMenuBreakpoint && 'direction-column'}`}>
        <Menu
          theme="light"
          mode={width >= mobileMenuBreakpoint ? 'horizontal' : 'vertical'}
          defaultSelectedKeys={[getActiveTab().toString()]}
        >
          <Menu.Item key="0">
            <Link to="/" className="tab" onClick={() => setCollapseSider(true)}>
              Homepage
            </Link>
          </Menu.Item>
          <Menu.Item key="1">
            <Link to="/calculate-potential" className="tab" onClick={() => setCollapseSider(true)}>
              Calculate potential
            </Link>
          </Menu.Item>
          <Menu.Item key="2">
            <Link to="/join" className="tab" onClick={() => setCollapseSider(true)}>
              Become superfan
            </Link>
          </Menu.Item>
          <Menu.Item key="3">
            <Link to="/about" className="tab" onClick={() => setCollapseSider(true)}>
              About us
            </Link>
          </Menu.Item>
          <Menu.Item>
            {user ? (
              <UserDropdown user={user} />
            ) : (
              <Link to="/dashboard" className="link">
                Sign in
              </Link>
            )}
          </Menu.Item>
          <Menu.Item>
            {(user?.adminOf || user?.superAdmin) ? (
              <Link to="/dashboard">
                <Button type="primary">
                  Dashboard
                </Button>
              </Link>
            ) : (
              <Button
                type="primary"
                onClick={() => setModalStatus(true)}
              >
                Request demo
              </Button>
            )}
          </Menu.Item>
        </Menu>
      </div>
    </div>
  )

  return (
    <div className="home-container">
      <Layout>
        {width < mobileMenuBreakpoint && (
        <Sider
          className="side-panel"
          theme="light"
          breakpoint="lg"
          collapsedWidth="0"
          collapsed={collapseSider}
          onCollapse={() => setCollapseSider(!collapseSider)}
        >
          <HomeMenu />
        </Sider>
        )}
        <Layout className="main">
          <div className="container pb-0">
            <Header
              className="bg-white p-0 mb-2"
            >
              {width < mobileMenuBreakpoint ? (
                <Link to="/" className="d-flex">
                  <img className="logo" src="/img/logo.svg" alt="superfan-logo" />
                </Link>
              ) : (
                <HomeMenu />
              )}
            </Header>
          </div>
          <Content className="main-content">
            <Switch>
              <Route path="/about">
                <About />
              </Route>
              <Route path="/calculate-potential">
                <CalculatePotential
                  setModalStatus={setModalStatus}
                />
              </Route>
              <Route path="/join">
                <BecomeSuperfan />
              </Route>
              <Route>
                <Home />
              </Route>
            </Switch>
          </Content>
          <Footer className="bg-white">
            <Row className="bg-white container">
              <Col xs={24} sm={12}>
                <p className="align-left">
                  Made with <Emoji symbol="⚡️" /> in Amsterdam Noord
                </p>
              </Col>
              <Col xs={24} sm={12}>
                <p>
                  Say hi at <a href="mailto:info@superfan.world">info@superfan.world</a> <Emoji symbol="💌" />
                </p>
              </Col>
            </Row>
          </Footer>
        </Layout>
      </Layout>
      <RequestDemoModal
        visible={modalStatus}
        setVisible={setModalStatus}
      />
    </div>
  )
}
