export default function getSuperfanPrefix(superfan) {
  if (superfan) {
    const vowels = ['a', 'e', 'i', 'o', 'u']
    if (vowels.includes(superfan.slice(0, 1))) {
      return 'n'
    }
  }

  return ''
}
