/* eslint-disable react/jsx-props-no-spreading */
import React from 'react'
import { Link } from 'react-router-dom'
import {
  Button, Collapse,
} from 'antd'
import Emoji from '../Emoji'
import ConnectInstagram from './ConnectInstagram'
import ConnectLinkedIn from './ConnectLinkedIn'

const { Panel } = Collapse

export default function SuperfanSignup2({
  brand, user, setUser,
}) {
  return (
    <div className="box">
      <h1 className="m-0 mt-3">
        <Emoji symbol="📲" />&nbsp;&nbsp;Social account
      </h1>
      <p>
        Connect with at least one of your social accounts, so we can retrieve your profile picture and details.
      </p>

      <ConnectInstagram
        user={user}
        setUser={setUser}
        brand={brand}
      />

      <ConnectLinkedIn
        user={user}
        setUser={setUser}
        brand={brand}
      />

      <Collapse ghost className="mb-3">
        <Panel header="Why do we ask you to connect your social accounts?" key="1">
          <p>
            Instagram and LinkedIn are currently the main channels for our {brand.customSuperfan || 'superfan'}s to share our mission and stories. You decide what and how to share the stories, and stay in full control. We only retrieve your profile picture, name, email and amount of followers. Your data won’t be used for other purposes.
          </p>
        </Panel>
      </Collapse>

      <div className="small-container">
        <Link to={`/${brand.handle}/join/3`}>
          <Button
            type="primary"
            className="w-100"
            disabled={!user.socialAccounts.instagram?.username && !user.socialAccounts.linkedIn?.username}
          >
            Continue
          </Button>
        </Link>
      </div>
    </div>
  )
}
