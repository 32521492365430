import React from 'react'
import { ReactComponent as Ripple } from '../icons/ripple.svg'

export default function PhotoRipple({ children }) {
  return (
    <div className="ripple">
      <Ripple />
      <div className="ripple-children">
        {children}
      </div>
    </div>
  )
}
