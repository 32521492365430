import React from 'react'
import moment from 'moment'
import { Carousel } from 'react-responsive-carousel'
import { Link } from 'react-router-dom'

import { ReactComponent as Profile } from '../../icons/profile.svg'
import { ReactComponent as Quote } from '../../icons/quote.svg'

export default function SuperfanCarousel({ superfans, data }) {
  return (
    <Carousel renderThumbs={() => []} className="carousel">
      {superfans.map(superfan => (
        <div key={superfan.id}>
          <div className="pending-card">
            <div className="d-flex direction-column align-center">
              <Link to={`/dashboard/${data.brand.handle}/superfans/${superfan.id}`} className="superfan-profile d-flex direction-column align-center">
                {superfan.imageUrl ? (
                  <img src={superfan.imageUrl} alt="icon" className="profile round" />
                ) : (
                  <Profile className="profile light" />
                )}
                <p className="m-0 regular">
                  {superfan.firstName} {superfan.lastName}
                </p>
              </Link>
              <p className="light">
                Superfan since {moment.unix(superfan.created / 1000).fromNow(true)}
              </p>

            </div>
            {superfan.quote && (
            <div className="quote-container">
              <div>
                <Quote className="icon" />
                <p className="mb-5">
                  {superfan.quote.length > 200 ? `${superfan.quote.slice(0, 200)}...` : superfan.quote}
                </p>
              </div>
            </div>
            )}
          </div>
        </div>
      ))}
    </Carousel>
  )
}
