import React from 'react'

export default function ImageGraphic({ img, style, boxStyle }) {
  return (
    <div className="image-graphic" style={style}>
      <div className="background" style={boxStyle} />
      <div
        className="foreground"
        style={{
          ...boxStyle,
          backgroundImage: `url(${img})`,
        }}
      />
    </div>
  )
}
