import React from 'react'
import { Row, Col } from 'antd'
import { Link } from 'react-router-dom'
import moment from 'moment'

// Import SVG's
import { ReactComponent as ChevronLeft } from '../../icons/chevron-left.svg'
import { ReactComponent as Doc } from '../../icons/doc.svg'
import { ReactComponent as User } from '../../icons/user.svg'
import { ReactComponent as Antenna } from '../../icons/antenna.svg'
import getReach from '../../utils/getReach'
import getStoryValue from '../../utils/getStoryValue'

export default function StoryDetail({ story, data }) {
  return (
    <div className="story-detail">
      <Link to={`/dashboard/${data.brand.handle}/stories`} className="d-inline-flex align-center regular">
        <ChevronLeft className="mr-1" /> <span className="regular">Stories</span>
      </Link>

      <div className="content-box">
        <div className="name">
          <Doc />
          <h1>
            {story.title}
          </h1>
          {story.expires ? (
            <div className={`expires small ${(story.expires - Date.now()) <= 36000000 ? 'red' : 'blue'}`}>
              {(story.expires - Date.now()) < 0 ? 'Expired' : 'Expiring'} {moment(story.expires).calendar()}
            </div>
          ) : (
            <div className="expires small green">
              Doesn&apos;t expire
            </div>
          )}
        </div>
        <Row gutter={[24, 24]} className="mt-3">
          <Col xs={24} md={12}>
            <h2 className="mb-0">
              Current analytics
            </h2>
            <Row gutter={[24, 24]} className="analytics">
              <Col xs={24} md={6}>
                <div className="metric">
                  <p className="light small center">
                    Superfans invited
                  </p>
                  <div className="d-flex align-center">
                    <User />
                    <h1 className="m-0">
                      {story.superfans.length}
                    </h1>
                  </div>
                </div>
              </Col>
              <Col xs={24} md={6}>
                <div className="metric">
                  <p className="light small center">
                    Views
                  </p>
                  <div className="d-flex align-center">
                    <User />
                    <h1 className="m-0">
                      {story.views ? story.views.length : '-'}
                    </h1>
                  </div>
                </div>
              </Col>
              <Col xs={24} md={6}>
                <div className="metric">
                  <p className="light small center">
                    Potential reach
                  </p>
                  <div className="d-flex align-center">
                    <Antenna />
                    <h1 className="m-0">
                      {getReach(story.views)}
                    </h1>
                  </div>
                </div>
              </Col>
              <Col xs={24} md={6}>
                <div className="metric">
                  <p className="light small center">
                    Est. value
                  </p>
                  <div className="d-flex align-center">
                    <h1 className="m-0 tertiary">
                      € {getStoryValue(story.views)}
                    </h1>
                  </div>
                </div>
              </Col>
            </Row>
            <h2 className="m-0 mt-4">
              Visuals
            </h2>
            <div className="posts scrollbox horizontal w-100">
              <div className="posts-container">
                {story.images && story.images.map(image => {
                  const queryIndex = image.lastIndexOf('?')
                  const extension = image.slice(queryIndex - 3, queryIndex)
                  return (
                    <div
                      key={image}
                      className="post-container"
                    >
                      <div className="post">
                        {['mp4', 'mov'].includes(extension) ? (
                          <video
                            controls
                            preload
                          >
                            <source src={image} type="video/mp4" />
                          </video>
                        ) : (
                          <img
                            src={image}
                            alt="story"
                            className="w-100"
                          />

                        )}
                      </div>
                    </div>
                  )
                })}
              </div>
            </div>
          </Col>
          <Col xs={24} md={12}>
            <h2 className="mb-0">
              Message
            </h2>
            <div className="message scrollbox vertical">
              <p className="pre-wrap">
                {story.description}
              </p>
              {story.hashtags && (
              <div className="mt-3">
                {story.hashtags.map(hashtag => (
                  <div className="d-flex align-baseline">
                    <p>
                      #{hashtag}&nbsp;
                    </p>
                  </div>
                ))}
              </div>
              )}
              {story.url && (
                <a href={story.url} target="_blank" rel="noopener noreferrer">{story.url}</a>
              )}
            </div>
          </Col>
        </Row>
      </div>
    </div>
  )
}
