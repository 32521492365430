import axios from 'axios'

export default function sendVerificationEmail(data) {
  const url = `${process.env.REACT_APP_FUNCTIONS_URL}/sendVerificationEmail`
  const headers = { accept: 'application/json' }

  return axios
    .post(url, data, { headers })
    .then(() => true)
    .catch(error => error)
}
