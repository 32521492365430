import React from 'react'
import ReactDOM from 'react-dom'
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from 'react-router-dom'

import * as firebase from 'firebase/app'

import './styles/app.scss'

import CookieConsent from './components/CookieConsent'
import ScrollToTop from './utils/ScrollToTop'
import LoginContainer from './containers/LoginContainer'
import HomeContainer from './containers/HomeContainer'
import Brand from './containers/Brand'
import EmailHandler from './containers/EmailHandler'
import LinkedInHandler from './containers/LinkedInHandler'

const productionFirebaseConfig = {
  apiKey: 'AIzaSyD48i6-oqa4nHUUY95Rc-gBRuuXIVoGbI8',
  authDomain: 'superfan-production.firebaseapp.com',
  databaseURL: 'https://superfan-production.firebaseio.com',
  projectId: 'superfan-production',
  storageBucket: 'superfan-production.appspot.com',
  messagingSenderId: '697981252722',
  appId: '1:697981252722:web:0e1c5841d303af49c8beeb',
  measurementId: 'G-SCTRKLSZ0D',
}

const stagingFirebaseConfig = {
  apiKey: 'AIzaSyDRaKiq_0OnsPAmjvrsC2BAy5VXG5hDge0',
  authDomain: 'superfan-world.firebaseapp.com',
  databaseURL: 'https://superfan-world.firebaseio.com',
  projectId: 'superfan-world',
  storageBucket: 'superfan-world.appspot.com',
  messagingSenderId: '106420564326',
  appId: '1:106420564326:web:f458f3fdc168a23f1658a7',
  measurementId: 'G-W200Y77WQR',
}

// Initialize Firebase
firebase.initializeApp(process.env.REACT_APP_ENVIRONMENT === 'production' ? productionFirebaseConfig : stagingFirebaseConfig)
firebase.analytics()

// Use local database in development
const db = firebase.firestore()
if (window.location.hostname === 'localhost') {
  db.settings({
    host: 'localhost:8080',
    ssl: false,
  })
}

const cookieConsent = window.localStorage.getItem('cookieConsent')

ReactDOM.render(
  <>
    {!cookieConsent && <CookieConsent />}
    <Router>
      <ScrollToTop />
      <Switch>
        <Route path="/email-handler">
          <EmailHandler />
        </Route>
        <Route path="/linkedin-handler">
          <LinkedInHandler />
        </Route>
        <Route path="/dashboard">
          <LoginContainer db={db} />
        </Route>
        <Route path={['/calculate-potential', '/join', '/about']}>
          <HomeContainer />
        </Route>
        <Route path="/:handle">
          <Brand />
        </Route>
        <Route>
          <HomeContainer />
        </Route>
      </Switch>
    </Router>
  </>,
  document.getElementById('root'),
)
