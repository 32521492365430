import React, { useState } from 'react'
import firebase from 'firebase'
import { Link, useHistory } from 'react-router-dom'
import moment from 'moment'
import {
  Button, Tabs, Table, Drawer, Row, Col, Checkbox, Dropdown, Menu, message,
} from 'antd'
import update from 'immutability-helper'
import useWindowDimensions from '../../utils/useWindowDimensions'
import SocialIcon from '../SocialIcon'

import { ReactComponent as Profile } from '../../icons/profile.svg'
import { ReactComponent as ThreeDots } from '../../icons/three-dots.svg'
import { ReactComponent as List } from '../../icons/list.svg'
import { ReactComponent as Grid } from '../../icons/grid.svg'
import { ReactComponent as Quote } from '../../icons/quote.svg'

const { TabPane } = Tabs
const { Column } = Table

export default function Superfans({ data, setPreselectedSuperfans }) {
  const { superfans } = data.brand
  const [tab, setTab] = useState('0')
  const [view, setView] = useState('list')
  const [selected, setSelected] = useState([])

  const history = useHistory()
  const db = firebase.firestore()
  const { width } = useWindowDimensions()

  const acceptedSuperfans = superfans.filter(s => s.status === 'accepted')
  const pendingSuperfans = superfans.filter(s => s.status === 'pending')
  const rejectedSuperfans = superfans.filter(s => s.status === 'rejected')

  const acceptedMenu = id => (
    <Menu>
      <Menu.Item>
        <Link to={`/dashboard/${data.brand.handle}/video-message`}>
          <div
            onClick={() => { setPreselectedSuperfans([id]) }}
          >
            Send video message
          </div>
        </Link>
      </Menu.Item>
      <Menu.Item>
        <Link to={`/dashboard/${data.brand.handle}/stories/add/content`}>
          <div
            onClick={() => { setPreselectedSuperfans([id]) }}
          >
            Send story
          </div>
        </Link>
      </Menu.Item>
    </Menu>
  )

  const pendingMenu = superfan => (
    <Menu>
      <Menu.Item>
        <div
          onClick={() => {
            db.collection('applications').doc(`${data.brand.id}-${superfan.id}`).update({ status: 'accepted' }).then(() => {
              message.success(`${superfan.firstName} is now a Superfan of ${data.brand.name} 🤩`)
            })
          }}
        >
          Accept
        </div>
      </Menu.Item>
      <Menu.Item>
        <div
          onClick={() => {
            db.collection('applications').doc(`${data.brand.id}-${superfan.id}`).update({ status: 'rejected' }).then(() => {
              message.success(`Succesfully rejected ${superfan.firstName}`)
            })
          }}
        >
          Reject
        </div>
      </Menu.Item>
    </Menu>
  )

  const rejectedMenu = superfan => (
    <Menu>
      <Menu.Item>
        <div
          onClick={() => {
            db.collection('applications').doc(`${data.brand.id}-${superfan.id}`).update({ status: 'accepted' }).then(() => {
              message.success(`${superfan.firstName} is now a Superfan of ${data.brand.name} 🤩`)
            })
          }}
        >
          Accept
        </div>
      </Menu.Item>
    </Menu>
  )

  const acceptedTableData = acceptedSuperfans.map(superfan => ({
    ...superfan,
    key: superfan.id,
    profile: (
      <Link to={`/dashboard/${data.brand.handle}/superfans/${superfan.id}`} className="superfan-profile">
        {superfan.imageUrl ? (
          <img src={superfan.imageUrl} alt="icon" className="round" />
        ) : (
          <Profile className="light" />
        )}
        <h2>
          {superfan.firstName} {superfan.lastName}
        </h2>
      </Link>
    ),
    quote: superfan.quote && (
      <div className="quote">
        {superfan.quote.length > 80 ? `${superfan.quote.slice(0, 80)}...` : superfan.quote}
      </div>
    ),
    contact: (
      <Link to={`/dashboard/${data.brand.handle}/video-message`} className="button-container">
        <Button
          type="primary"
          onClick={() => { setPreselectedSuperfans([superfan.id]) }}
        >
          Video message
        </Button>
      </Link>
    ),
    menu: (
      <Dropdown overlay={acceptedMenu(superfan.id)}>
        <ThreeDots />
      </Dropdown>
    ),
  }))

  const pendingTableData = pendingSuperfans.map(superfan => ({
    ...superfan,
    key: superfan.id,
    reach: superfan.reach ? superfan.reach.toLocaleString('nl-NL') : 'unknown',
    profile: (
      <Link to={`/dashboard/${data.brand.handle}/superfans/${superfan.id}`} className="superfan-profile">
        {superfan.imageUrl ? (
          <img src={superfan.imageUrl} alt="icon" className="round" />
        ) : (
          <Profile className="light" />
        )}
        <h2>
          {superfan.firstName} {superfan.lastName}
        </h2>
      </Link>
    ),
    accounts: superfan.socialAccounts && (
      Object.keys(superfan.socialAccounts).map(account => (
        <SocialIcon
          key={account}
          className="mr-1"
          channel={account}
        />
      ))
    ),
    request: moment.unix(superfan.created / 1000).fromNow(),
    button: (
      <div className="button-container">
        <Link to={`/dashboard/${data.brand.handle}/superfans/${superfan.id}`} className="button-container">
          <Button type="primary">
            View
          </Button>
        </Link>
      </div>
    ),
    quote: superfan.quote && (
      <div className="quote">
        {superfan.quote.length > 80 ? `${superfan.quote.slice(0, 80)}...` : superfan.quote}
      </div>
    ),
    menu: (
      <Dropdown overlay={pendingMenu(superfan)}>
        <ThreeDots className="menu" />
      </Dropdown>
    ),
  }))

  const rejectedTableData = rejectedSuperfans.map(superfan => ({
    ...superfan,
    key: superfan.id,
    reach: superfan.reach ? superfan.reach.toLocaleString('nl-NL') : 'unknown',
    profile: (
      <Link to={`/dashboard/${data.brand.handle}/superfans/${superfan.id}`} className="superfan-profile">
        {superfan.imageUrl ? (
          <img src={superfan.imageUrl} alt="icon" className="round" />
        ) : (
          <Profile className="light" />
        )}
        <h2>
          {superfan.firstName} {superfan.lastName}
        </h2>
      </Link>
    ),
    accounts: superfan.socialAccounts && (
      Object.keys(superfan.socialAccounts).map(account => (
        <SocialIcon
          key={account}
          className="mr-1"
          channel={account}
        />
      ))
    ),
    request: moment.unix(superfan.created / 1000).fromNow(),
    button: (
      <div className="button-container">
        <Link to={`/dashboard/${data.brand.handle}/superfans/${superfan.id}`} className="button-container">
          <Button type="secondary">
            Re-Evaluate
          </Button>
        </Link>
      </div>
    ),
    quote: superfan.quote && (
      <div className="quote">
        {superfan.quote.length > 80 ? `${superfan.quote.slice(0, 80)}...` : superfan.quote}
      </div>
    ),
    menu: (
      <Dropdown overlay={rejectedMenu(superfan)}>
        <ThreeDots className="menu" />
      </Dropdown>
    ),
  }))

  let displayedSuperfans

  if (tab === '0') displayedSuperfans = acceptedTableData
  if (tab === '1') displayedSuperfans = pendingTableData
  if (tab === '2') displayedSuperfans = rejectedTableData

  const setAllSelected = status => {
    const batch = db.batch()

    selected.forEach(id => {
      batch.set(db.collection('applications').doc(`${data.brand.id}-${id}`), { status }, { merge: true })
    })

    batch.commit().then(() => {
      setSelected([])
      message.success(`${selected.length} superfan${selected.length > 1 ? 's' : ''} ${status}`)
    })
  }

  return (
    <div className="superfans">
      <div className="title d-flex justify-between">
        <h1 className="bold m-0">
          Superfans
        </h1>
        <Button type="primary">
          Send invite
        </Button>
      </div>
      <Tabs onChange={key => setTab(key)}>
        <TabPane tab={`Accepted (${acceptedSuperfans.length})`} key="0" />
        <TabPane tab={`Pending (${pendingSuperfans.length})`} key="1" />
        <TabPane tab={`Rejected (${rejectedSuperfans.length})`} key="2" />
      </Tabs>
      <div className="separator" />
      <div className="header view-toggle">
        <List
          className={view === 'list' ? 'active' : ''}
          onClick={() => setView('list')}
        />
        <Grid
          className={view === 'grid' ? 'active' : ''}
          onClick={() => setView('grid')}
        />
      </div>
      {view === 'list' && (
        <div className="table">
          <Table
            dataSource={displayedSuperfans}
            rowSelection={{
              type: 'checkbox',
              selected,
              onChange: (selectedKeys) => setSelected(selectedKeys),
            }}
          >
            <Column title="Profile" dataIndex="profile" key="profile" />
            {width > 767 && (
            <>
              {tab === '0' && (
              <>
                <Column title="Quote" dataIndex="quote" key="quote" />
                <Column title="Contact" dataIndex="contact" key="contact" width={150} />
              </>
              )}
              {(tab === '1' || tab === '2') && (
              <>
                <Column title="Reach" dataIndex="reach" key="reach" />
                <Column title="Quote" dataIndex="quote" key="Quote" />
                <Column title="Request" dataIndex="request" key="request" />
                <Column title="" dataIndex="button" key="button" />
              </>
              )}
              <Column title="" dataIndex="menu" key="menu" />
            </>
            )}
          </Table>
        </div>
      )}

      {view !== 'list' && (
      <>
        <div className="header">
          <Checkbox
            className="mr-2"
            checked={selected.length === displayedSuperfans.length}
            indeterminate={selected.length > 0 && selected.length < displayedSuperfans.length}
            onClick={() => {
              if (selected.length < displayedSuperfans.length) {
                setSelected(displayedSuperfans)
              } else {
                setSelected([])
              }
            }}
          />
          <p className="light m-0">
            Select all
          </p>
        </div>
        <Row className="grid" gutter={[24, 24]}>
          {displayedSuperfans.map(superfan => (
            <Col xs={24} md={8} lg={6} key={superfan.id}>
              <div className="superfan-card">
                <div className="d-flex justify-between w-100">
                  <Checkbox
                    checked={selected.find(id => superfan.id === id) !== undefined}
                    onClick={() => {
                      const index = selected.findIndex(id => superfan.id === id)
                      if (index >= 0) {
                        setSelected(update(selected, { $splice: [[index, 1]] }))
                      } else {
                        setSelected(update(selected, { $push: [superfan.id] }))
                      }
                    }}
                  />
                  <Dropdown overlay={acceptedMenu(superfan.id)}>
                    <ThreeDots />
                  </Dropdown>
                </div>
                <Link to={`/dashboard/${data.brand.handle}/superfans/${superfan.id}`} className="superfan-profile">
                  {superfan.imageUrl ? (
                    <img src={superfan.imageUrl} alt="icon" className="round profile-img" />
                  ) : (
                    <Profile className="light profile-img" />
                  )}
                  <h2>
                    {superfan.firstName} {superfan.lastName}
                  </h2>
                </Link>
                <div className="quote">
                  <Quote className="icon" />
                  <div className="copy">
                    {superfan.quote}
                  </div>
                </div>
                <Link to={`/dashboard/${data.brand.handle}/superfans/${superfan.id}`} className="button-container">
                  <Button type="primary">
                    {tab === '0' ? 'Video message' : 'Evaluate'}
                  </Button>
                </Link>
              </div>
            </Col>
          ))}
        </Row>
      </>
      )}
      <Drawer
        className="selected-superfans"
        placement="bottom"
        height="60px"
        mask={false}
        closable={false}
        maskClosable={false}
        visible={selected.length > 0}
      >
        <div className="d-flex justify-between">
          <div>
            <span className="bold">{selected.length}</span>&nbsp;{tab === '1' && 'pending '}superfan{selected.length > 1 && 's'} selected
          </div>
          <Row gutter={12}>
            <Col xs={24} md={12} className="align-right white underline nowrap pointer">
              {tab === '0' ? (
                <div
                  onClick={() => {
                    setPreselectedSuperfans(selected)
                    history.push(`/dashboard/${data.brand.handle}/video-message`)
                  }}
                >
                  Send video message
                </div>
              ) : (
                <div onClick={() => setAllSelected('accepted')}>
                  Accept
                </div>
              )}
            </Col>
            <Col xs={24} md={12} className="align-right white underline nowrap pointer">
              {tab === '0' ? (
                <div
                  onClick={() => {
                    setPreselectedSuperfans(selected)
                    history.push(`/dashboard/${data.brand.handle}/stories/add`)
                  }}
                >
                  Send story
                </div>
              ) : (
                <div onClick={() => setAllSelected('rejected')}>
                  Reject
                </div>
              )}
            </Col>
          </Row>
        </div>
      </Drawer>
    </div>
  )
}
