import React, { useState, useEffect } from 'react'
import firebase from 'firebase'
import { useLocation } from 'react-router-dom'
import {
  Result, Input, Button,
} from 'antd'

import { MailOutlined, LoadingOutlined } from '@ant-design/icons'
import { ReactComponent as Illustration } from '../../icons/illustration-1.svg'

import authenticate from '../../api/authenticate'

import FloatLabel from '../FloatLabel'

export default function Login({ verifyEmail, errorMessage }) {
  const [loading, setLoading] = useState()
  const [verification, setVerification] = useState()
  const [step, setStep] = useState('login')
  const [error, setError] = useState(errorMessage)
  const [email, setEmail] = useState(window.localStorage.getItem('emailForSignIn'))

  useEffect(() => setError(errorMessage), [errorMessage])

  const location = useLocation()
  const { pathname } = location

  useEffect(() => {
    if (verifyEmail) {
      setVerification(true)
      setError('Please re-enter your email')
    } else {
      setVerification()
    }
  },
  [verifyEmail])

  async function handleSubmit() {
    setLoading(true)
    // Check if account exists
    const signInMethods = await firebase.auth().fetchSignInMethodsForEmail(email)

    if (signInMethods && signInMethods.length > 0) {
      const response = await authenticate(email, pathname)
      if (response === 'success') {
        setStep('success')
      } else {
        // TODO: report error
        setError('Oh oh.. something went wrong connecting to the server')
      }
    } else {
      setError('Couldn\'t find account with this address')
    }
    setLoading()
  }

  return (
    <div className="login">
      <div className="left" style={{ gridArea: 'left' }}>
        <Illustration />
      </div>
      <div className="right">
        <div className="login-box">
          <img className="logo" src="/img/logo.svg" alt="superfan-logo" />
          {step === 'login' && (
          <>
            <h1>Login</h1>
            <FloatLabel
              label="Email"
              value={email}
            >
              <Input
                value={email}
                type="email"
                name="email"
                onChange={e => setEmail(e.target.value.toLowerCase())}
                onPressEnter={handleSubmit}
              />
            </FloatLabel>

            <Button
              loading={loading}
              disabled={!email}
              className="ant-btn ant-btn-primary"
              type="submit"
              onClick={verification ? () => verifyEmail(email) : handleSubmit}
            >
              {verification ? 'Login' : 'Send sign-in link'}
            </Button>
            <p className="error">{error}</p>
          </>
          )}

          {step === 'success' && (
            <Result
              icon={<MailOutlined />}
              title="Sign-in link is on its way"
              subTitle="You can close this window"
            />
          )}

          {step === 'verify' && (
            <Result
              icon={<LoadingOutlined />}
              title="Logging you in.."
            />
          )}
        </div>
      </div>
    </div>
  )
}
