import React from 'react'
import { ResponsiveLine } from '@nivo/line'
import numeral from 'numeral'
import moment from 'moment'
import { Button, Dropdown } from 'antd'
import { DownOutlined } from '@ant-design/icons'

import colors from '../../../styles/colors.scss'
import DateRangeMenu from '../menus/DateRangeMenu'

export default function Reach({
  superfansOverTime, startDate, dateRange, setDateRange,
}) {

  const data = []

  for (const object of superfansOverTime) {
    if (moment(object.date) > startDate && moment(object.date) < moment()) {
      data.push({
        x: object.date.format('YYYY-MM'),
        y: object.reach,
      })
    }
  }

  const lineData = [
    {
      id: 'reach',
      data,
    },
  ]

  return (
    <div className="reach component" style={{ gridArea: 'reach' }}>
      <div className="d-flex justify-between align-center">
        <div>
          <h2 className="m-0">
            Movement reach
          </h2>
          <p className="small light m-0">
            current
          </p>
          <p className="large bold font-secondary secondary m-0">
            {superfansOverTime[superfansOverTime.length - 1].reach.toLocaleString('nl-NL')}
          </p>
        </div>
        <div className="date-selector">
          <Dropdown
            overlay={
              <DateRangeMenu setDateRange={setDateRange} />
          }
          >
            <Button>
              {dateRange.string}
              <DownOutlined />
            </Button>
          </Dropdown>
        </div>
      </div>
      <div className="chart">
        <ResponsiveLine
          data={lineData}
          margin={{
            top: 10, right: 20, bottom: 30, left: 30,
          }}
          xScale={{ type: 'time', format: '%Y-%m', precision: 'month' }}
          xFormat="time:%Y-%m"
          yScale={{
            type: 'linear', min: 0, max: 'auto', stacked: true, reverse: false,
          }}
          curve="basis"
          lineWidth={3}
          axisTop={null}
          axisRight={null}
          axisBottom={{
            format: '%b',
            orient: 'bottom',
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
          }}
          axisLeft={{
            orient: 'left',
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            format: value => numeral(value).format('0a'),
          }}
          tooltip={d => {
            const date = new Date(d.point.data.x)
            return (
              <div className="tooltip">
                <p className="date light">
                  {date.toLocaleDateString(undefined, { month: 'long', year: 'numeric' })}
                </p>
                <p className="value">
                  <span className="bold">{d.point.data.y}</span> people
                </p>
              </div>
            )
          }}
          colors={[colors.secondary]}
          enablePoints={false}
          enableArea
          areaOpacity={0.1}
          useMesh
        />
      </div>
    </div>
  )
}
