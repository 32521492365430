import axios from 'axios'

export default function getInstagramProfile(username) {
  const options = {
    method: 'GET',
    url: 'https://instagram28.p.rapidapi.com/user_info',
    params: { user_name: username },
    headers: {
      'x-rapidapi-key': '476d2f365bmsh1777eec3106ae18p106293jsnd7777807d8c8',
      'x-rapidapi-host': 'instagram28.p.rapidapi.com',
    },
  }

  return axios
    .request(options)
    .then(response => response.data)
    .catch(error => error)
}
