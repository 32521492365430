import firebase from 'firebase'

export default async function sendVideoMessage(video, brandID, setUploadProgress) {
  const db = firebase.firestore()
  const storage = firebase.storage()

  const doc = await db.collection('videoMessages').doc()

  const ref = await storage.ref().child(`videos/messages/${doc.id}`)

  const uploadVideo = new Promise((handleSuccess, handleError) => {
    const uploadTask = ref.put(video.file)

    uploadTask.on('state_changed', snapshot => {
      const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100
      setUploadProgress(progress)
    },
    error => handleError(error),
    () => {
      uploadTask.snapshot.ref.getDownloadURL().then(downloadURL => {
        handleSuccess(downloadURL)
      })
    })
  })

  return uploadVideo.then(videoUrl => {
    const upload = {
      message: video.message || '',
      video: videoUrl,
      brandID,
      superfans: video.superfans,
    }

    upload.created = Date.now()

    return doc
      .set(upload)
      .then(() => ({
        status: 'success',
      }))
  },
  error => ({
    status: 'error',
    error,
  }))
}
