import React, { useState } from 'react'
import moment from 'moment'
import { Link, useRouteMatch } from 'react-router-dom'
import {
  Button, Tabs, Table,
} from 'antd'
import { ReactComponent as Doc } from '../../icons/doc.svg'

import useWindowDimensions from '../../utils/useWindowDimensions'
import SocialIcon from '../SocialIcon'
import getHoursLeft from '../../utils/getHoursLeft'

const { TabPane } = Tabs
const { Column } = Table

export default function Stories({ data }) {
  const [tab, setTab] = useState('0')

  const { url } = useRouteMatch()
  const { width } = useWindowDimensions()

  const { stories } = data.brand

  const channels = ['instagram', 'linkedin', 'tiktok', 'facebook']

  const activeStories = []
  const completedStories = []
  const draftStories = []

  stories.forEach(story => {
    const timestamp = Date.now()
    if (story.expires > timestamp || story.expires === 0) {
      activeStories.push(story)
    } else if (story.expires <= timestamp) {
      completedStories.push(story)
    } else {
      draftStories.push(story)
    }
  })

  const activeTableData = activeStories.map(story => ({
    ...story,
    key: story.id,
    superfans: story.superfans ? story.superfans.length : '-',
    reach: story.reach ? story.reach.toLocaleString('nl-NL') : '-',
    title: (
      <Link to={`${url}/${story.id}`} className="story-title">
        {story.expires ? (
          <p className={`clock small ${getHoursLeft(story.expires) <= 10 ? 'red' : 'blue'}`}>
            Expires in <span className="bold">{getHoursLeft(story.expires)}h</span>
          </p>
        ) : (
          <div className="clock small small green">
            Doesn&apos;t expire
          </div>
        )}
        <Doc />
        <h2>
          {story.title}
        </h2>
      </Link>
    ),
    channels: story.categories.map(category => channels.includes(category) && (
      <SocialIcon
        key={category}
        className="mr-1"
        channel={category}
      />
    )),
    button: (
      <Link to={`${url}/${story.id}`} className="button-container">
        <Button type="secondary">
          View
        </Button>
      </Link>
    ),
  }))

  const draftTableData = draftStories.map(story => ({
    ...story,
    key: story.id,
    superfans: story.superfans ? story.superfans.length : '-',
    reach: story.reach ? story.reach_estimate.toLocaleString('nl-NL') : '-',
    title: (
      <Link to={`${url}/${story.id}`} className="story-title">
        <Doc />
        <h2>
          {story.title}
        </h2>
      </Link>
    ),
    channels: story.categories.map(category => channels.includes(category) && (
      <SocialIcon
        key={category}
        className="mr-1"
        channel={category}
      />
    )),
    lastEdited: moment(story.lastEdited).calendar(),
    button: (
      <Link to={`${url}/${story.id}`} className="button-container">
        <Button>
          Edit
        </Button>
      </Link>
    ),
  }))

  const completedTableData = completedStories.map(story => ({
    ...story,
    key: story.id,
    superfans: story.superfans ? story.superfans.length : '-',
    reach: story.reach ? story.reach.toLocaleString('nl-NL') : '-',
    title: (
      <Link to={`${url}/${story.id}`} className="story-title">
        <Doc />
        <h2>
          {story.title}
        </h2>
      </Link>
    ),
    channels: story.categories.map(category => channels.includes(category) && (
      <SocialIcon
        key={category}
        className="mr-1"
        channel={category}
      />
    )),
    button: (
      <Link to={`${url}/${story.id}`} className="button-container">
        <Button>
          View
        </Button>
      </Link>
    ),
  }))

  const getDataSource = () => {
    if (tab === '0') {
      return activeTableData
    }

    if (tab === '1') {
      return draftTableData
    }

    return completedTableData
  }

  return (
    <div className="stories">
      <div className="dashboard-top" style={{ gridArea: 'dashboard-top' }}>
        <div className="title">
          <h1 className="bold m-0">
            Stories
          </h1>
          <Link to={`${url}/add`}>
            <Button type="primary">
              Add story
            </Button>
          </Link>
        </div>
        <Tabs defaultActiveKey={tab} onChange={key => setTab(key)}>
          <TabPane tab={`Active (${activeStories.length})`} key="0" />
          <TabPane tab={`Draft (${draftStories.length})`} key="1" />
          <TabPane tab={`Completed (${completedStories.length})`} key="2" />
        </Tabs>
        <div className="separator" />
        <div className="table">
          <Table dataSource={getDataSource()} className={tab === '0' ? 'active' : ''}>
            <Column title="Title" dataIndex="title" key="title" />
            <Column
              title="Accounts"
              dataIndex="channels"
              key="channels"
            />
            {width > 767 && (
              <>
                <Column title={tab === '1' ? 'Reach est.' : 'Reach'} dataIndex="reach" key="reach" />
                <Column title="Superfans" dataIndex="superfans" key="superfans" />
                {tab === '1' && <Column title="Last edited" dataIndex="lastEdited" key="lastEdited" />}
                <Column title="" dataIndex="button" key="button" />
              </>
            )}
          </Table>
        </div>
      </div>
    </div>
  )
}
