/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useState } from 'react'
import { Link, useHistory } from 'react-router-dom'
import update from 'immutability-helper'
import { TweenOneGroup } from 'rc-tween-one'
import {
  Button, Input, Col, message, notification,
} from 'antd'
import { CloseCircleOutlined } from '@ant-design/icons'
import { useDropzone } from 'react-dropzone'
import FloatLabel from '../FloatLabel'
import { ReactComponent as Image } from '../../icons/image.svg'
import saveStory from '../../api/saveStory'

export default function StoryEditorContent({ story, setStory, data }) {
  const [files, setFiles] = useState([])
  const [loadingSave, setLoadingSave] = useState()
  const [videoHeight, setVideoHeight] = useState()
  const history = useHistory()

  const videoRef = node => {
    if (node !== null) {
      setVideoHeight(node.videoHeight)
    }
  }

  useEffect(() => {
    if (!story.brandID) {
      history.push(`/dashboard/${data.brand.handle}/stories/add/content`)
    }
  }, [data.brand.handle, history, story.brandID])

  const { getRootProps, getInputProps } = useDropzone({
    accept: 'image/jpeg, image/png, video/mp4',
    onDrop: acceptedFiles => {
      const newFiles = acceptedFiles.map(file => {
        const extension = file.name.slice(file.name.lastIndexOf('.'))
        return Object.assign(
          file,
          {
            preview: URL.createObjectURL(file),
            extension,
          },
        )
      })
      setFiles([
        ...files,
        ...newFiles,
      ])
      setStory({
        ...story,
        imageFiles: [
          ...story.imageFiles,
          ...newFiles,
        ],
      })
    },
  })

  const videoExtension = fileName => {
    const queryIndex = fileName.lastIndexOf('?')
    const extension = fileName.slice(queryIndex - 3, queryIndex)

    const videoExtensions = ['mp4', 'mov']

    if (videoExtensions.includes(extension)) {
      return extension
    }

    return false
  }

  const videoFile = file => {
    if (file.type.includes('video')) {
      return file.type.split('/').pop()
    }

    return false
  }

  useEffect(() => {
    if (videoHeight > 2000) {
      const imageFiles = story.imageFiles.slice(0, story.imageFiles.length - 1)
      setVideoHeight()
      setStory({
        ...story,
        imageFiles,
      })

      notification.error({
        message: 'Video resolution too large',
        description: 'Please upload a video of max 2000x2000 pixels',
        duration: 0,
      })
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [videoHeight])

  return (
    <>
      <div className="content-box">
        <h1>
          Story content
        </h1>
        <Col xs={24} lg={12}>
          <FloatLabel
            label="Story title"
            value={story.title}
            className="mt-4"
          >
            <Input
              value={story.title}
              onChange={e => setStory(update(story, { title: { $set: e.target.value } }))}
            />
          </FloatLabel>
        </Col>

        <h2 className="mt-4">
          Add content
        </h2>
        <TweenOneGroup
          className="posts"
          enter={{
            scale: 0.8,
            opacity: 0,
            type: 'from',
            duration: 100,
            onComplete: e => {
              e.target.style = ''
            },
          }}
          leave={{
            opacity: 0, width: 0, scale: 0, duration: 200,
          }}
          appear={false}
        >
          <div
            key="dropzone"
            className="dropzone-container post-container"
          >
            <div {...getRootProps({ className: 'dropzone post' })}>
              <input {...getInputProps()} />
              <Image className="secondary" />
              <p className="w-100 m-0">
                <span className="secondary bold">
                  Upload files here
                </span>
                {' '}
                or just drag and drop
                <br />
                <span className="small">
                  .jpg, .png, .mp4
                </span>
              </p>
            </div>
          </div>
          {story.imageFiles.map((image, i) => (
            <div
              key={image.preview}
              className="post-container"
            >
              <div className="post">
                <CloseCircleOutlined
                  onClick={() => {
                    setFiles(update(files, { $splice: [[i, 1]] }))
                    setStory(update(story, { imageFiles: { $splice: [[i, 1]] } }))
                  }}
                />
                {videoFile(image) ? (
                  <video
                    ref={videoRef}
                    controls
                    preload="none"
                  >
                    <source src={image.preview} type="video/mp4" />
                  </video>
                ) : (
                  <img src={image.preview} alt="upload" />
                )}
              </div>
            </div>
          ))}
          {story.images && story.images.map((image, i) => (
            <div
              key={image}
              className="post-container"
            >
              <div className="post">
                <CloseCircleOutlined
                  onClick={() => {
                    setFiles(update(files, { $splice: [[i, 1]] }))
                    setStory(update(story, { images: { $splice: [[i, 1]] } }))
                  }}
                />
                {videoExtension(image) ? (
                  <video
                    controls
                    preload
                  >
                    <source src={image} type="video/mp4" />
                  </video>
                ) : (
                  <img src={image} alt="upload" />
                )}
              </div>
            </div>
          ))}
        </TweenOneGroup>
      </div>
      <div className="buttons mt-3">
        <Button
          disabled={!story.title}
          type="secondary"
          className="mr-3"
          loading={loadingSave}
          onClick={async () => {
            setLoadingSave(true)
            const response = await saveStory(story, data.brand.id, false)

            if (response === true) {
              message.success('Story saved 💾')
              history.push(`/dashboard/${data.brand.handle}/stories`)
              setLoadingSave()
            } else {
              setLoadingSave()
              message.error('Something went wrong saving your story')
              console.log(response)
            }
          }}
        >
          Save as draft
        </Button>
        <Link to={`/dashboard/${data.brand.handle}/stories/${(story && story.id) || 'add'}/information`}>
          <Button
            type="primary"
            disabled={(story.imageFiles.length < 1 && !story.images) || !story.title}
          >
            Next
          </Button>
        </Link>
      </div>
    </>
  )
}
