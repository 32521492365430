import React, { useCallback, useEffect, useState } from 'react'
import firebase from 'firebase'

import { useHistory, useLocation } from 'react-router-dom'
import { message } from 'antd'
import Dashboard from './Dashboard'
import Login from '../components/dashboard/Login'
import Loader from '../components/Loader'
import AdminSwitcher from '../components/dashboard/AdminSwitcher'
import loadBrand from '../api/loadBrand'
import getAllBrands from '../api/getAllBrands'
import getBrands from '../api/getBrands'

export default function LoginContainer({ db }) {
  const [loggedIn, setLoggedIn] = useState('loading')
  const [data, setData] = useState()
  const location = useLocation()
  const history = useHistory()

  const handleLogin = useCallback(
    async user => {
      const userData = await db
        .collection('users')
        .doc(user.uid)
        .get()
        .then(doc => doc.data())
        .catch(() => {
          setLoggedIn('unknown')
        })

      if (!userData) {
        // TODO: redirect to sign-up
        setLoggedIn('unknown')
      } else {
        const u = {
          email: user.email,
          uid: user.uid,
          ...userData,
          lastSignInTime: user.metadata.lastSignInTime,
        }

        const idToken = await firebase.auth().currentUser.getIdTokenResult()

        const { superAdmin, adminOf } = idToken.claims

        const path = location.pathname.split('/')

        let brandData

        const brandHandle = path[2]

        // Directly redirect user to corresponding brand based on path
        if (brandHandle === 'super-admin' && superAdmin) {
          const brands = await getAllBrands()
          u.superAdmin = true
          setData({
            brands,
            user: u,
          })
          setLoggedIn('superAdmin')
        } else if (brandHandle) {
          await db
            .collection('brands')
            .where('handle', '==', brandHandle)
            .get()
            .then(querySnapshot => querySnapshot.forEach(doc => {
              brandData = {
                id: doc.id,
                ...doc.data(),
              }
            }))
            .catch(() => {
              setLoggedIn('unknown')
            })

          let brands

          if (superAdmin) {
            brands = await getAllBrands()
            u.superAdmin = true
          } else if (adminOf.length > 1) {
            brands = await getBrands(adminOf)
          }

          if (!brandData) {
            if (brands) {
              setData({
                brands,
                user: u,
              })

              setLoggedIn('switcher')
              message.error('The brand you\'re looking for doesn\'t exist or you are not an admin')
            } else {
              setLoggedIn('unknown')
              message.error('You\'re not an admin of any brands')
            }
          } else {
            const brand = await loadBrand(brandData)

            setData({
              brand,
              brands,
              user: u,
            })

            setLoggedIn(true)
          }

        } else if (superAdmin) {
          // Show admin switcher with all brands
          const brands = await getAllBrands()
          u.superAdmin = true

          setData({
            user: u,
            brands,
          })
          setLoggedIn('switcher')
        } else if (adminOf && adminOf.length <= 1) {
          // If there's only one brand, automatically load that brand
          const brandID = adminOf[0]
          await db
            .collection('brands')
            .doc(brandID)
            .get()
            .then(doc => {
              brandData = {
                id: doc.id,
                ...doc.data(),
              }
            })
            .catch(() => {
              setLoggedIn('unknown')
            })

          const brand = await loadBrand(brandData)
          setData({
            brand,
            user: u,
          })

          setLoggedIn(true)
          history.push(`/dashboard/${brandData.handle}`)
        } else if (adminOf && adminOf.length > 1) {
          // Show brand switcher if adminOf multiple brands
          const brands = await getBrands(adminOf)
          setData({
            brands,
            user: u,
          })

          setLoggedIn('switcher')
        } else {
          setLoggedIn('unknown')
          message.error('You\'re not an admin of any brand')
        }
      }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [],
  )

  useEffect(() => {
    const unsubscribe = firebase.auth().onAuthStateChanged(user => {
      // Check if user is logged in
      if (user) {
        handleLogin(user)
      } else {
        setLoggedIn()
      }
    })

    return unsubscribe
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (loggedIn === 'loading') {
    return (
      <Loader />
    )
  }

  if (loggedIn === 'failed') {
    return <Login errorMessage="Couldn't log you in, please try again or contact support" />
  }

  if (loggedIn === 'switcher' && data) {
    return <AdminSwitcher data={data} setData={setData} setLoggedIn={setLoggedIn} />
  }

  if (loggedIn === 'superAdmin' && data) {
    return <Dashboard data={data} setData={setData} setLoggedIn={setLoggedIn} superAdmin />
  }

  if (loggedIn === true && data) {
    return <Dashboard data={data} setData={setData} setLoggedIn={setLoggedIn} />
  }

  return (
    <Login />
  )
}
