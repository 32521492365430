/* eslint-disable react/jsx-props-no-spreading */
import React from 'react'
import { Link } from 'react-router-dom'
import { Button } from 'antd'
import Emoji from '../Emoji'

export default function SuperfanSignup9({ brand }) {
  return (
    <div className="box">
      <h1 className="m-0 mt-3 mb-5">
        <Emoji symbol="🙈" />&nbsp;&nbsp;You&apos;ve already applied to be a superfan of {brand.name}
      </h1>
      <p>
        If you applied more than a week ago and haven&apos;t heard anything yet, please contact us at <a href="mailto:info@superfan.world">info@superfan.world</a>
      </p>
      <Link to={`/${brand.handle}`}>
        <Button
          type="primary"
          className="w-100"
        >
          Return to brand page
        </Button>
      </Link>
    </div>
  )
}
