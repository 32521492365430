import firebase from 'firebase'

export default function verifyEmail(actionCode) {
  return firebase
    .auth()
    .applyActionCode(actionCode)
    .then(() => true)
    .catch(error => {
      console.error(error)
      return error
    })
}
