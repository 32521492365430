import React, { useEffect, useState } from 'react'
import {
  useRouteMatch, useHistory, Switch, Route,
} from 'react-router-dom'
import firebase from 'firebase'
import BrandHome from '../components/brand/BrandHome'
import ViewVideoMessage from '../components/brand/ViewVideoMessage'
import SuperfanSignup from './SuperfanSignup'
import Loader from '../components/Loader'
import ViewStory from '../components/brand/ViewStory'
import Password from '../components/Password'

export default function Brand() {
  const [brand, setBrand] = useState()
  const [loading, setLoading] = useState(true)

  const history = useHistory()
  const { params } = useRouteMatch()

  useEffect(() => {
    const db = firebase.firestore()

    const fetchBrandData = async () => {
      let brandID

      await db.collection('brands').where('handle', '==', params.handle)
        .get()
        .then(querySnapshot => {
          querySnapshot.forEach(doc => {
            brandID = doc.id
          })
        })
        .catch((error) => {
        // TODO: report error
          console.log('Error getting documents: ', error)
        })

      if (!brandID) {
        history.push('/')
      } else {
        const brandData = await db
          .collection('brands')
          .doc(brandID)
          .get()
          .then(doc => doc.data())

        if (brandData) {
          setBrand({
            ...brandData,
            id: brandID,
          })
          setLoading(false)
        } else {
          history.push('/')
        }
      }
    }

    fetchBrandData()
  }, [history, params.handle])

  if (loading) {
    return (
      <Loader />
    )
  }

  const password = localStorage.getItem('password')

  return (
    <>
      {brand.hasPassword && password !== process.env.REACT_APP_PASSWORD ? (
        <Password />
      ) : (
        <div className="brand">
          <div className="top">
            <img src={brand.logo} alt="logo" className="logo" />
          </div>
          <Switch>
            <Route path="/:handle/join">
              <SuperfanSignup brand={brand} />
            </Route>
            <Route path="/:handle/stories/:id">
              <ViewStory brand={brand} />
            </Route>
            <Route path="/:handle/video-messages/:id">
              <ViewVideoMessage brand={brand} />
            </Route>
            <Route>
              <BrandHome brand={brand} />
            </Route>
          </Switch>
        </div>
      )}
    </>
  )
}
