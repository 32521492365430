import axios from 'axios'

export default function setAdminOf(email, brandID) {
  const url = `${process.env.REACT_APP_FUNCTIONS_URL}/setAdminOf`
  const data = { email, brandID }
  const headers = { accept: 'application/json' }

  return axios
    .post(url, data, { headers })
    .then(() => true)
    .catch(error => error)
}
