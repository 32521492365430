import firebase from 'firebase'

export default async function getSuperAdmins() {
  const db = firebase.firestore()

  const superAdmins = []
  await db
    .collection('users')
    .where('superAdmin', '==', true)
    .get()
    .then(querySnapshot => {
      querySnapshot.forEach(doc => {
        superAdmins.push({
          ...doc.data(),
          id: doc.id,
        })
      })
    })

  return superAdmins
}
