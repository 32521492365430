const getReach = views => {
  const reach = []
  views.forEach(view => {
    reach.push(view.reach)
  })

  return reach.reduce((a, b) => a + b, 0)
}

export default getReach
