import React from 'react'
import { Link } from 'react-router-dom'
import { Button } from 'antd'
import Emoji from '../Emoji'

export default function SuperfanSignup1({ brand, user, setUser }) {

  return (
    <div className="box">
      <h1 className="m-0 mt-3">
        Let&apos;s get started
      </h1>
      <p>
        To check if we are a match, we need to know the following information:
      </p>

      <h2 className="m-0">
        <span className="emoji-bullet double"><Emoji symbol="📲" /></span>
        1. Social account
      </h2>
      <p className="custom-ul light double">
        Connect the social account(s) you want to use spreading our mission.
      </p>

      <h2 className="m-0">
        <span className="emoji-bullet double"><Emoji symbol="👩🏽 👦🏼" /></span>
        2. Personal details
      </h2>
      <p className="custom-ul light double">
        Your name, email adress and a profile picture.
      </p>

      <h2 className="m-0">
        <span className="emoji-bullet double"><Emoji symbol="✊🏼" /></span>
        3. Your motivation
      </h2>
      <p className="custom-ul light double">
        Choose your reason for joining us.
      </p>

      <div className="small-container">
        <Link to={`/${brand.handle}/join/2`}>
          <Button
            type="primary"
            onClick={() => setUser({ ...user, isStarted: true })}
            className="w-100 mt-5"
          >
            I&apos;m ready
          </Button>
        </Link>

        <div className="px-3">
          <ul className="checklist light mt-4">
            <li>
              You are crazy about our brand
            </li>
            <li>
              You want to share our mission
            </li>
            <li>
              Your social account is required, not a minimum of followers
            </li>
          </ul>
        </div>
      </div>
    </div>
  )
}
