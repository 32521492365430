import firebase from 'firebase'

export default async function saveBrand(brand) {
  try {
    const upload = {
      ...brand,
    }

    delete upload.imageFiles

    const db = firebase.firestore()
    const storage = firebase.storage()

    let doc
    if (brand.id) {
      doc = await db.collection('brands').doc(brand.id)
    } else {
      doc = await db.collection('brands').doc()
    }

    const imageFileIDs = Object.keys(brand.imageFiles)
    for (const id of imageFileIDs) {
      const ref = await storage.ref().child(`brands/${doc.id}/${id}`)
      await ref.put(brand.imageFiles[id])
      const imageUrl = await ref
        .getDownloadURL()
        .then(url => url)
        .catch(error => { throw new Error(error) })

      if (id.startsWith('socialPreview')) {
        const storyID = id.slice(-1)
        upload.screenshots[storyID].image = imageUrl
      } else if (id === 'header') {
        upload.headline.image = imageUrl
      } else if (id === 'representative') {
        upload.representative.image = imageUrl
      } else {
        upload[id] = imageUrl
      }
    }

    return doc
      .set(upload, { merge: true })
      .then(() => true)
  } catch (error) {
    return error
  }
}
