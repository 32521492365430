/* eslint-disable no-await-in-loop */
import React, { useEffect, useState } from 'react'
import { Link, useHistory } from 'react-router-dom'
import update from 'immutability-helper'
import {
  Button, Input, Col, Tag, Row, Select, Space, Drawer, Checkbox, message, notification,
} from 'antd'
import { TweenOneGroup } from 'rc-tween-one'
import { SearchOutlined } from '@ant-design/icons'
import FloatLabel from '../FloatLabel'

import capitilize from '../../utils/capitilize'
import validateEmail from '../../utils/validateEmail'
import saveStory from '../../api/saveStory'
import cleanUpSuperfanData from '../../utils/cleanUpSuperfanData'
import sendStoryMail from '../../api/sendStoryMail'

const { TextArea } = Input
const { Option } = Select

export default function StoryEditorInformation({
  acceptedSuperfans, story, setStory, data, preselectedSuperfans, setPreselectedSuperfans,
}) {
  const [newHashtag, setNewHashtag] = useState()
  const [drawer, setDrawer] = useState()
  const [specificSuperfans, setSpecificSuperfans] = useState((story.categories.length === 0 && story.superfans.length > 0) ? story.superfans.map(superfan => superfan.id) : preselectedSuperfans)
  const [drawerSuperfans, setDrawerSuperfans] = useState([])
  const [search, setSearch] = useState()
  const [loading, setLoading] = useState()
  const [loadingSave, setLoadingSave] = useState()

  const history = useHistory()

  const categoriesList = []

  acceptedSuperfans.forEach(superfan => {
    if (typeof superfan.socialAccounts === 'object') {
      const channels = Object.keys(superfan.socialAccounts)
      channels.forEach(channel => {
        if (!categoriesList.map(category => category.name).includes(channel)) {
          categoriesList.push({
            name: channel,
            count: 1,
          })
        } else {
          categoriesList[categoriesList.findIndex(category => category.name === channel)].count += 1
        }
      })
    }
  })

  useEffect(() => {
    setPreselectedSuperfans([])
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (story.categories.includes('all')) {
      setStory(update(story, { superfans: { $set: cleanUpSuperfanData(acceptedSuperfans) } }))
    } else {
      const superfans = []
      specificSuperfans.forEach(id => {
        superfans.push(acceptedSuperfans.find(superfan => superfan.id === id))
      })
      story.categories.forEach(category => {
        acceptedSuperfans.forEach(superfan => {
          if (!superfans.includes(superfan) && Object.keys(superfan.socialAccounts).includes(category)) {
            superfans.push(superfan)
          }
        })
      })
      setStory(update(story, { superfans: { $set: cleanUpSuperfanData(superfans) } }))
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [acceptedSuperfans, story.categories, specificSuperfans])

  const handleHashtagConfirm = () => {
    if (newHashtag && ((story.hashtags && story.hashtags.indexOf(newHashtag) === -1) || !story.hashtags)) {
      if (story.hashtags) {
        setStory(update(story, { hashtags: { $push: [newHashtag] } }))
      } else {
        setStory(update(story, { hashtags: { $set: [newHashtag] } }))
      }
      setNewHashtag()
    }
  }

  const handleCategoryClick = category => {
    const index = story.categories.indexOf(category)
    if (index >= 0) {
      setStory(update(story, { categories: { $splice: [[index, 1]] } }))
    } else if (story.categories.includes('all')) {
      setStory(update(story, { categories: { $set: [category] } }))
    } else {
      setStory(update(story, { categories: { $push: [category] } }))
    }
  }

  return (
    <>
      <div className="content-box">
        <Row gutter={[96, 24]}>
          <Col xs={24} lg={12}>
            <h1>
              Story information
            </h1>
            <h2>
              About the story
            </h2>
            <TextArea
              placeholder="What do you want to tell your superfans?"
              value={story.description}
              onChange={e => setStory(update(story, { description: { $set: e.target.value } }))}
            />

            <h2 className="mt-3 mb-0">
              Hashtags
            </h2>
            <TweenOneGroup
              className="hashtags"
              enter={{
                scale: 0.8,
                opacity: 0,
                type: 'from',
                duration: 100,
                onComplete: e => {
                  e.target.style = ''
                },
              }}
              leave={{
                opacity: 0, width: 0, scale: 0, duration: 200,
              }}
              appear={false}
            >
              {story.hashtags && story.hashtags.map(tag => (
                <Tag
                  key={tag}
                  closable
                  className="hashtag"
                  onClose={e => {
                    e.preventDefault()
                    setStory(update(story, { hashtags: { $set: story.hashtags.filter(ht => ht !== tag) } }))
                  }}
                >
                  #{tag}
                </Tag>
              ))}
              <FloatLabel
                label="Add hashtag"
                value={newHashtag}
                key="input"
              >
                <Input
                  type="text"
                  size="small"
                  style={{ width: 137 }}
                  value={newHashtag}
                  onChange={e => setNewHashtag(e.target.value)}
                  onBlur={handleHashtagConfirm}
                  onPressEnter={handleHashtagConfirm}
                />

              </FloatLabel>
            </TweenOneGroup>
          </Col>

          <Col xs={24} lg={12}>
            <Space direction="vertical" size="large">
              <FloatLabel
                label="URL"
                value={story.url}
              >
                <Input
                  value={story.url}
                  onChange={e => setStory(update(story, { url: { $set: e.target.value } }))}
                />
              </FloatLabel>
              <FloatLabel
                label="Reply to email"
                value={story.replyTo || ''}
              >
                <Input
                  value={story.replyTo || data.brand.defaultReplyTo || ''}
                  onChange={e => setStory(update(story, { replyTo: { $set: e.target.value } }))}
                />
              </FloatLabel>
              <FloatLabel
                label="Duration"
                labelStyle={{ top: '12px' }}
                value={story.duration}
              >
                <Select
                  value={story.duration && (story.duration === 'unlimited' ? 'Unlimited' : `${story.duration} hours`)}
                  onChange={value => setStory(update(story, { duration: { $set: value } }))}
                >
                  <Option value="unlimited">
                    Unlimited
                  </Option>
                  <Option value="12">
                    12 hours
                  </Option>
                  <Option value="24">
                    24 hours
                  </Option>
                  <Option value="48">
                    48 hours
                  </Option>
                  <Option value="72">
                    72 hours
                  </Option>
                </Select>
              </FloatLabel>
              <Row>
                <Col xs={24} lg={18}>
                  <h2>
                    Share story with:
                  </h2>
                  <div className="categories">
                    <Tag
                      key="all"
                      className={`category ${story.categories.includes('all') && 'selected'}`}
                      onClick={() => {
                        const index = story.categories.indexOf('all')
                        if (index >= 0) {
                          setStory(update(story, { categories: { $set: [] } }))
                        } else {
                          setStory(update(story, { categories: { $set: ['all'] } }))
                        }
                      }}
                    >
                      All superfans ({acceptedSuperfans.length})
                    </Tag>
                    <Tag
                      key="specific"
                      className={`category ${!story.categories.includes('all') && specificSuperfans.length > 0 && 'selected'}`}
                      onClick={() => {
                        setDrawerSuperfans(specificSuperfans)
                        setDrawer(true)
                      }}
                    >
                      Specific superfans ({specificSuperfans.length})
                    </Tag>
                    {categoriesList.map(category => (
                      <Tag
                        key={category.name}
                        className={`category ${story.categories.includes(category.name) && 'selected'}`}
                        onClick={() => handleCategoryClick(category.name)}
                      >
                        {capitilize(category.name)} ({category.count})
                      </Tag>
                    ))}
                  </div>
                </Col>
              </Row>
            </Space>
          </Col>
        </Row>

        <Drawer
          className="superfan-selector"
          placement="right"
          height="auto"
          closable={false}
          onClose={() => {
            setDrawer()
            setDrawerSuperfans([])
          }}
          visible={drawer}
          width={430}
        >
          <h1 className="mt-3">
            Choose specific superfans
          </h1>
          <FloatLabel
            value={search}
            label="Search specific superfan"
            labelStyle={{ paddingLeft: '26px' }}
            className="mt-6"
          >
            <Input
              value={search}
              onChange={e => setSearch(e.target.value.toLowerCase())}
              prefix={<SearchOutlined />}
            />
          </FloatLabel>

          <div className="bg-gray-super-light superfan-list padding py-0 mt-4">
            {acceptedSuperfans.filter(superfan => (search ? superfan.firstName.toLowerCase().includes(search) || superfan.lastName.toLowerCase().includes(search) : true)).map(superfan => (
              <div key={superfan.id}>
                <div
                  onClick={() => {
                    const index = drawerSuperfans.findIndex(id => superfan.id === id)
                    if (index >= 0) {
                      setDrawerSuperfans(update(drawerSuperfans, { $splice: [[index, 1]] }))
                    } else {
                      setDrawerSuperfans(update(drawerSuperfans, { $push: [superfan.id] }))
                    }
                  }}
                  className="d-flex align-center py-2 pointer"
                  key={superfan.id}
                >
                  <Checkbox checked={drawerSuperfans.find(id => superfan.id === id) !== undefined} />
                  <img src={superfan.imageUrl} alt="profile" className="profile-img round mr-3 ml-3" />
                  <p className="m-0">
                    {superfan.firstName} {superfan.lastName}
                  </p>
                </div>
                <div className="separator m-0" />
              </div>
            ))}
          </div>

          <div className="d-flex justify-between align-center mt-3 bottom-full-width">
            <Button
              type="secondary"
              onClick={() => {
                setDrawerSuperfans([])
                setDrawer()
              }}
            >
              Cancel
            </Button>
            <Button
              type="primary"
              onClick={() => {
                setSpecificSuperfans(drawerSuperfans)
                setDrawerSuperfans([])
                const index = story.categories.indexOf('all')
                if (index >= 0) {
                  setStory(update(story, { categories: { $splice: [[index, 1]] } }))
                }
                setDrawer()
              }}
            >
              Apply
            </Button>
          </div>
        </Drawer>
      </div>
      <div className="buttons-container">
        <Link to={`/dashboard/${data.brand.handle}/stories/${(story && story.id) || 'add'}/content`}>
          <Button type="secondary">
            Previous step
          </Button>
        </Link>
        <div className="buttons">
          <Button
            type="secondary"
            className="mr-3"
            loading={loadingSave}
            onClick={async () => {
              setLoadingSave(true)
              const response = await saveStory(story, data.brand.id, false)

              if (response.status === 'success') {
                message.success('Story saved 💾')
                history.push(`/dashboard/${data.brand.handle}/stories`)
                setLoadingSave()
              } else {
                setLoadingSave()
                message.error('Something went wrong saving your story')
                console.log(response)
              }
            }}
          >
            Save as draft
          </Button>
          <Button
            type="primary"
            loading={loading}
            disabled={(!story.images && story.imageFiles.length < 1) || !story.title || !story.description || !story.duration || !validateEmail(story.replyTo) || story.superfans.length < 1}
            onClick={async () => {
              setLoading(true)
              const response = await saveStory(story, data.brand.id, true)

              if (response.status === 'success' && response.savedStory) {
                const res = await sendStoryMail(response.savedStory)

                if (res === true) {
                  message.success('Story published 💌')
                  history.push(`/dashboard/${data.brand.handle}/stories`)
                } else {
                  history.push(`/dashboard/${data.brand.handle}/stories`)
                  notification.error({
                    title: 'Something went wrong',
                    message: 'Your story was published but something went wrong sending it to your superfans, please contact support.',
                    duration: 0,
                  })
                  console.log(response.error)
                }

                setLoading()
              } else {
                setLoading()
                notification.error({
                  title: 'Something went wrong',
                  message: 'Something went wrong publishing your story, please try again or contact support. We did not send an email to your superfans.',
                  duration: 0,
                })
                console.log(response)
              }
            }}
          >
            Publish
          </Button>
        </div>
      </div>
    </>
  )
}
