import queryString from 'query-string'

export default function getLinkedInAuthCode() {
  const params = {
    response_type: 'code',
    client_id: '77a7pi29q7zujn',
    redirect_uri: `${process.env.REACT_APP_DOMAIN}/linkedin-handler`,
    scope: 'r_basicprofile r_emailaddress',
  }

  const query = queryString.stringify(params)

  const url = 'https://www.linkedin.com/oauth/v2/authorization'

  window.location.href = `${url}?${query}`
}
